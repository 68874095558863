import Icon from 'components/Icon';
import * as S from './style';
import { Flex, Span } from 'styles/style';
import { openNewWindow } from 'constants/common';
const Footer = () => {
  return (
    <S.RelativeDiv>
      <S.Contaier>
        <S.LeftContainer>
          <S.CSContainer>
            <Icon icon='SupportAgent' width={16} height={16} />
            <Flex gap='5px' flexDirection='column' flex={1}>
              <Span fontSize='1.4rem' fontWeight='600'>
                리얼 피드 고객센터
              </Span>
              <Span fontSize='1.2rem' fontWeight='300'>
                카카오톡 플러스친구 @리얼피드
              </Span>
            </Flex>
            <S.WrapIcon
              onClick={() => openNewWindow('http://pf.kakao.com/_vvTun')}
            >
              <Icon icon='RightArrow' width={16} height={16} />
            </S.WrapIcon>
          </S.CSContainer>
          <Span fontSize='1.2rem' fontWeight='300' whiteSpace='nowrap'>
            운영시간 : 평일 10:00 - 17:00 (점심시간 13:00 - 14:00) 주말 및
            공휴일은 휴무입니다.
          </Span>
        </S.LeftContainer>
        <S.RightContainer>
          <Flex gap='8px' margin='0 0 16px 0'>
            <Span
              fontSize='1.2rem'
              cursor='pointer'
              onClick={() => {
                openNewWindow('/setting/terms/privacy');
              }}
            >
              개인정보 취급방침
            </Span>
            <Span
              fontSize='1.2rem'
              cursor='pointer'
              onClick={() => {
                openNewWindow('/setting/terms/provision');
              }}
            >
              이용약관
            </Span>
          </Flex>
          <Flex gap='12px' margin='0 0 8px 0'>
            <Span fontSize='1rem'>상호명 : (주) 스판링크</Span>
            <Span fontSize='1rem'>대표이사 : 정영권, 김창운</Span>
            <Span fontSize='1rem'>사업자등록번호 : 701-86-01391</Span>
            <Span fontSize='1rem'>
              통신판매업신고번호: 재 2019-서울강남-02722 호
            </Span>
          </Flex>
          <Flex gap='12px'>
            <Span fontSize='1rem'>
              주소 : 서울특별시 송파구 올림픽로 424, SPORTS 360 LAB 201호
            </Span>
            <Span fontSize='1rem'>전화번호 : 02-555-0634</Span>
            <Span fontSize='1rem'>이메일 : spanlink@naver.com</Span>
          </Flex>
        </S.RightContainer>
      </S.Contaier>
    </S.RelativeDiv>
  );
};
export default Footer;
