import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';

const GlobalStyle = createGlobalStyle`
  ${reset}
  *, *::before, *::after {
    box-sizing: border-box;
  }
  *{
    font-family: 'Pretendard' !important;
  }
  body {
    background-color: black;
    color: white;
    font-family: 'Pretendard' !important;
  }
  html,body{
    font-size: 62.5%;
    line-height: 1.285;
  }
  a{
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  :root{
    --semantic-red: #FF453A;
    --primary: #00FF99;
    --primary-inprogress:#0A764B;
    --c0: #FFFFFF;
    --c100: #E5E5E5;
    --c200: #CCCCCC;
    --c300: #B2B2B2;
    --c400: #999999;
    --c500: #808080;
    --c600:#666666;
    --c700:#4C4C4C;
    --c800:#333333;
    --c900:#191919;
    --c1000:#121212;
    --c1100: #000000;
    --background-color: #24252d;
  }
  input{
    border: none;
    cursor: text;
    :focus{
      outline: none;
      background-color: transparent;
    }
    :active{
      background-color: transparent;
    }
    :hover{

      background-color: transparent;
    }
  }
  
  input:active {
    background-color: transparent !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
    background-color: transparent;
    caret-color: white;
  }
  button{
    background: inherit ; 
    border:none; 
    box-shadow:none; 
    border-radius:0; 
    padding:0; 
    overflow:visible; 
    cursor:pointer
    
  }
  button:disabled{
    cursor: default;
  }
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;   
    flex-shrink: 0;
  }
  video{
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }

  ::-webkit-scrollbar-track {
    background: transparent; 
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0,0,0,0.4);
    border-radius : 20px;
  }

  ::-webkit-scrollbar-thumb:hover {    
    background: rgba(0,0,0,0.5);

  }
  input, textarea {
    font-size: 1.3rem;
  }
  div, button, span{
    font-size: 1.4rem;
  }

`;

export default GlobalStyle;
