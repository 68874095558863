import React, { useState, useRef } from 'react';
import Icon from 'components/Icon';
import {
  ProfileImageWrapper,
  ProfileContainer,
  Content,
  StateItem,
  StateContainer,
  TextArea,
  ButtonWrapper,
  GreenButton,
  GrayButton,
} from '../CommentContainer/style';
import { Flex, PreLineDiv, Span } from 'styles/style';
import { ToggleMenu } from 'components/ToggleMenu';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { timeAgo } from 'constants/common';
import { ReplyType } from 'constants/type';
import { postAddLike, postEditReply } from 'apis/community';

type CommentItemType = {
  reply: ReplyType;
  orderBy: string;
  handleSeeMore: (replyId: string) => void;
  menuOpenId: string;
  setMenuOpenId: React.Dispatch<React.SetStateAction<string>>;
};
export const CommentItem = ({
  reply: reply_prop,
  orderBy,
  handleSeeMore,
  menuOpenId,
  setMenuOpenId,
}: CommentItemType) => {
  const [reply, setReply] = useState<ReplyType>(reply_prop);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const { center } = useCenterStore();
  const { accessToken } = useAccessTokenStore();
  const [replyContent, setReplyContent] = useState<string>(reply.content);
  const textAreaRef_reply = useRef<HTMLTextAreaElement | null>(null);

  const adjustHeight = (
    isInit: boolean,
    textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>
  ) => {
    if (textAreaRef.current && isInit) {
      textAreaRef.current.style.height = `20px`;
    } else if (textAreaRef.current) {
      textAreaRef.current.style.height = 'inherit';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };
  const editComment = async (reply: ReplyType) => {
    try {
      const response = await postEditReply({
        params: {
          postId: reply.postId,
        },
        data: {
          centerId: center.centerId,
          content: replyContent,
          orderBy,
          replyId: reply.replyId,
        },
      });
      const result = response;
      if (!result) return;

      const { replyview } = result;
      setReply(replyview);
      setReplyContent('');
      setIsEdit(false);
      adjustHeight(true, textAreaRef_reply);
    } catch (e) {
      console.error('editComment error');
    }
  };
  const handleThumbsUp = async (reply: ReplyType) => {
    try {
      const response = await postAddLike({
        centerId: center.centerId,
        replyId: reply.replyId,
        postId: reply.postId,
        isLikeData: !reply.isLikeExist,
      });
      const result = response;
      if (!result) return;

      const updatedReply = {
        ...reply,
        isLikeExist: !reply.isLikeExist,
        likeCount: reply.isLikeExist
          ? reply.likeCount - 1
          : reply.likeCount + 1,
      };
      setReply(updatedReply);
    } catch (e) {
      console.error('handleThumbsUp error');
    }
  };
  return (
    <>
      <ProfileContainer>
        <ProfileImageWrapper>
          <img
            src={
              reply.authorProfile?.profileImage?.thumbnailImage ||
              '/defaultUserImage.png'
            }
            alt='프로필 이미지'
            onError={(e) => {
              const target = e.target as HTMLImageElement;
              target.onerror = null; // 재귀적인 호출을 방지
              target.src = '/defaultUserImage.png';
            }}
          />
        </ProfileImageWrapper>
        {reply.authorProfile?.nickname}
        {!reply.isDeleted && (
          <Flex
            position='relative'
            flex={1}
            justifyContents='right'
            alignItems='flex-start'
          >
            <Flex
              onClick={(e) => {
                e.stopPropagation();
                if (menuOpenId === '' || menuOpenId !== reply.replyId) {
                  setMenuOpenId(reply.replyId);
                } else {
                  setMenuOpenId('');
                }
              }}
              style={{ cursor: 'pointer' }}
            >
              <Icon icon='DotMenu' width={20} height={20} />
            </Flex>
            {reply.replyId === menuOpenId && (
              <ToggleMenu
                isMyPost={reply.isMine}
                postId={reply.postId}
                replyId={reply.replyId}
                setIsEdit={setIsEdit}
                setMenuOpenId={setMenuOpenId}
              />
            )}
          </Flex>
        )}
      </ProfileContainer>
      {reply.isDeleted ? (
        <Content isDeleted={reply.isDeleted}>삭제된 댓글입니다</Content>
      ) : (
        <Content isDeleted={reply.isDeleted}>
          {isEdit ? (
            <Flex
              flexDirection='column'
              border='1px solid var(--c700)'
              padding='10px'
              borderRadius='3px'
            >
              <TextArea
                rows={1}
                ref={textAreaRef_reply}
                onInput={() => adjustHeight(false, textAreaRef_reply)}
                onChange={(e) => setReplyContent(e.target.value)}
                value={replyContent}
                placeholder='댓글을 남겨주세요'
              />
              <Flex flexDirection='row' gap='7px' justifyContents='right'>
                <GrayButton
                  onClick={() => {
                    setIsEdit(false);
                    setReplyContent(reply.content);
                    adjustHeight(true, textAreaRef_reply);
                  }}
                >
                  취소
                </GrayButton>
                <GreenButton
                  isActive={replyContent !== ''}
                  onClick={() => editComment(reply)}
                >
                  저장
                </GreenButton>
              </Flex>
            </Flex>
          ) : (
            <PreLineDiv>{reply.content}</PreLineDiv>
          )}
        </Content>
      )}
      {!isEdit && (
        <StateContainer>
          <StateItem>{timeAgo(reply.createdAt)}</StateItem>
          {!reply.isDeleted &&
            (reply.isLikeExist ? (
              <StateItem>
                <Span color='var(--primary)'>좋아요</Span>
                <ButtonWrapper onClick={() => handleThumbsUp(reply)}>
                  <Icon icon='ThumbsUp_Active' width={13} height={13} />
                </ButtonWrapper>
                <Span color='var(--primary)'>{reply.likeCount}</Span>
              </StateItem>
            ) : (
              <StateItem>
                <Span>좋아요</Span>
                <ButtonWrapper onClick={() => handleThumbsUp(reply)}>
                  <Icon icon='ThumbsUp' width={13} height={13} />
                </ButtonWrapper>
                <Span>{reply.likeCount}</Span>
              </StateItem>
            ))}
          {reply.subReplyCount < 1 && (
            <StateItem>
              <Span>대댓글</Span>
              <ButtonWrapper onClick={() => handleSeeMore(reply.replyId)}>
                <Icon icon='Message' width={13} height={13} />
              </ButtonWrapper>
            </StateItem>
          )}
        </StateContainer>
      )}
    </>
  );
};
