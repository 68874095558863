import { useState, useEffect, useRef } from 'react';
import { Flex, Span } from 'styles/style';
import { HorizontalBox, VerticalBox } from '../Scene/style';
import {
  getQuality,
  getRatio,
  getMMSSFormat,
  getMS,
  moveCursorToEnd,
} from 'constants/video';
import { AddSceneText, TimeInput } from 'components/Scene/style';
import { AddImage } from 'styles/FormStyle';
import {
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
  VideoChangeButton,
} from '../MainVideoInput/style';
import SkipToggleButton from 'components/SkipToggleButton';
import VideoSnapshot from 'components/VideoSnapshot';
import { SceneType } from 'constants/type';
import { debounce } from 'constants/common';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import { VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';

type FeedbackVideoInputType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ratio: string;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  feedbackVideo: File | null;
  setFeedbackVideo: React.Dispatch<React.SetStateAction<File | null>>;
  duration: number;
};
const FeedbackVideoInput = ({
  setIsLoading,
  ratio,
  sceneList,
  setSceneList,
  feedbackVideo,
  setFeedbackVideo,
  duration: mainVideoDuration,
}: FeedbackVideoInputType) => {
  const [feedbackVideoSrc, setFeedbackVideoSrc] = useState('');
  // const [quality, setQuality] = useState('');
  const [isVideoCanPlay, setIsVideoCanPlay] = useState(false);

  //video hook
  const { videoRef, isVideoPlaying, play, pause, currentBarHandler } =
    useVideoControl();
  const { duration, quality, currentTime, setCurrentTime, metadata } =
    useVideoMetadata({
      videoRef,
      video: feedbackVideo,
      videoRatio: ratio,
      setVideoSrc: setFeedbackVideoSrc,
      setIsVideoCanPlay,
      setIsLoading,
    });
  const [time, setTime] = useState({
    minute: 0,
    second: 0,
  });
  const [thumbnail, setThumbnail] = useState<Blob>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [canSkipStep, setCanSkipStep] = useState<boolean>(false);
  const [additionalStreamingDuration, setAdditionalStreamingDuration] =
    useState<number>(0);
  useEffect(() => {
    // 초기 시간 메인 비디오의 끝으로 지정
    if (feedbackVideo) {
      const objectUrl = URL.createObjectURL(feedbackVideo);
      setFeedbackVideoSrc(objectUrl);
    } else {
      setFeedbackVideoSrc('');
    }
    setTime({
      minute: Math.floor(mainVideoDuration / 1000 / 60),
      second: Math.floor((mainVideoDuration / 1000) % 60),
    });
  }, [mainVideoDuration, feedbackVideo]);
  useEffect(() => {
    setAdditionalStreamingDuration(Math.floor(duration * 1000));
  }, [duration]);
  useEffect(() => {
    if (!thumbnail || !thumbnailUrl) {
      return;
    }
    const { minute, second } = time;
    const newScene: SceneType = {
      sceneType: 'coaching',
      sceneStartAt: (minute * 60 + second) * 1000,
      sceneEndAt: (minute * 60 + second) * 1000 + 300,
      title: '따라하기',
      thumbnailUrl,
      thumbnailFile: thumbnail,
      additionalMent: '지금 바로\n연습하고\n피드백을 받아보세요!',
      additionalStreamingDuration,
      canSkipStep,
      metadata,
    };
    setSceneList((prev) => {
      // sceneType이 'coaching'인 씬이 있는지 확인
      const hasCoaching = prev.some((scene) => scene.sceneType === 'coaching');
      if (hasCoaching) {
        return prev.map((scene) =>
          scene.sceneType === 'coaching' ? newScene : scene
        );
      } else {
        return [...prev, newScene];
      }
    });
  }, [time, thumbnail, canSkipStep, metadata]);
  useEffect(() => {
    const timer = setTimeout(() => {
      debouncedCheckTime();
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);
  const debouncedCheckTime = debounce(() => {
    checkTime();
  });
  const addFeedbackVideo = (file: File) => {
    if (!file) {
      return;
    }
    if (file.size > 1 * 1024 * 1024 * 1024) {
      alert(VIDEOUPLOAD_ALERT_TEXT);
      return;
    }
    const videoElement = document.createElement('video');
    const objectUrl = URL.createObjectURL(file);
    videoElement.src = objectUrl;
    videoElement.addEventListener('loadedmetadata', () => {
      const videoDuration = videoElement.duration;
      if (videoDuration > 30 * 60) {
        alert(VIDEOUPLOAD_ALERT_TEXT);
        URL.revokeObjectURL(objectUrl);
        return;
      } else {
        setFeedbackVideo(file);
        setFeedbackVideoSrc(objectUrl);
      }
    });
  };

  const setCanSkipStep_forFunc = (index: number, value: boolean) => {
    setCanSkipStep(value);
  };
  const setThumbnailFile = (index: number, blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setThumbnail(blob);
    setThumbnailUrl(url);
  };
  const checkTime = () => {
    const startAt = (time.minute * 60 + time.second) * 1000;
    const zeroTime = { minute: 0, second: 0 };
    if (mainVideoDuration < 1) {
      return;
    }
    if (startAt > mainVideoDuration) {
      alert('메인비디오의 영상 범위를 벗어납니다. 다시 입력해주세요.');
      setTime(zeroTime);
      return false;
    }
    if (
      time.second !== 0 &&
      sceneList
        .filter((v) => v.sceneType !== 'coaching')
        .find((scene) => {
          if (startAt >= scene.sceneStartAt && startAt < scene.sceneEndAt) {
            return true;
          }
        })
    ) {
      alert('다른 씬과 시간이 겹칩니다. 다시 입력해주세요.');
      setTime(zeroTime);
      return false;
    }
    return true;
  };
  return (
    <>
      <input
        id='feedbackVideo'
        type='file'
        accept='video/*'
        style={{ display: 'none' }}
        onChange={(e) => e.target.files && addFeedbackVideo(e.target.files[0])}
      />
      <video
        src={feedbackVideoSrc}
        ref={videoRef}
        style={{ display: 'none' }}
      />
      <Flex margin='20px 0 10px 0'>
        <label htmlFor='feedbackVideo'>
          <AddSceneText>피드백</AddSceneText>
        </label>
      </Flex>
      {feedbackVideoSrc !== '' ? (
        <Flex flexDirection='row' gap='15px'>
          {ratio === 'landscape' ? (
            <HorizontalBox>
              <VideoSnapshot
                videoSrc={feedbackVideoSrc}
                time={1}
                index={0}
                setThumbnailFile={setThumbnailFile}
              />
            </HorizontalBox>
          ) : (
            <VerticalBox>
              {' '}
              <VideoSnapshot
                videoSrc={feedbackVideoSrc}
                time={1}
                index={0}
                setThumbnailFile={setThumbnailFile}
              />
            </VerticalBox>
          )}
          <Flex alignItems='center' gap='20px' padding='10px 0' flex={1}>
            <MetaDataContainer>
              <MetaDataColumn>
                <MetaDataTitle>시작 시간</MetaDataTitle>
                <MetaDataValue>
                  <TimeInput
                    value={time.minute.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        setTime((prev) => ({ ...prev, minute: Number(val) }));
                      }
                    }}
                    placeholder='00'
                  />
                  분{' '}
                  <TimeInput
                    value={time.second.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        setTime((prev) => ({ ...prev, second: Number(val) }));
                      }
                    }}
                    placeholder='00'
                  />
                  초
                </MetaDataValue>
              </MetaDataColumn>
              <MetaDataColumn>
                <SkipToggleButton
                  id='feedback'
                  index={0}
                  canSkipStep={canSkipStep}
                  setCanSkipStep={setCanSkipStep_forFunc}
                />
              </MetaDataColumn>
            </MetaDataContainer>
            <MetaDataContainer>
              <MetaDataColumn>
                <MetaDataTitle>영상길이</MetaDataTitle>
                <MetaDataValue>
                  {isVideoCanPlay
                    ? getMMSSFormat(additionalStreamingDuration)
                    : '-'}
                </MetaDataValue>
              </MetaDataColumn>
              <MetaDataColumn>
                <MetaDataTitle>비율</MetaDataTitle>
                <MetaDataValue>
                  {isVideoCanPlay
                    ? `${
                        ratio === 'landscape'
                          ? '가로형 (16:9)'
                          : '세로형 (9:16)'
                      }`
                    : '-'}
                </MetaDataValue>
              </MetaDataColumn>
              <MetaDataColumn>
                <MetaDataTitle>화질</MetaDataTitle>
                <MetaDataValue>{isVideoCanPlay ? quality : '-'}</MetaDataValue>
              </MetaDataColumn>
            </MetaDataContainer>
            <Flex flex={1} justifyContents='right'>
              <label htmlFor='feedbackVideo'>
                <VideoChangeButton>영상 변경하기</VideoChangeButton>
              </label>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <label htmlFor='feedbackVideo'>
          <AddImage>
            <Span>+</Span>
            <Span>영상 추가</Span>
          </AddImage>
        </label>
      )}
    </>
  );
};
export default FeedbackVideoInput;
