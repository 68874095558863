import Header from 'components/Header';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import { Flex, Span } from 'styles/style';
import { Container, ImageWrapper } from './style';
import { useEffect } from 'react';
import { postCenterView } from 'apis/center';
function PendingView() {
  const { accessToken } = useAccessTokenStore();
  const { center, setCenter } = useCenterStore();
  const { setProfile } = useProfileStore();
  useEffect(() => {
    getCenterData(center.centerId, accessToken);
  }, []);
  const getCenterData = async (centerId: string, accessToken: string) => {
    const response = await postCenterView({
      params: {
        centerId,
      },
    });
    const result = response;
    if (!result) return;

    const { center, watchingProfile } = result;
    setCenter(center);
    setProfile(watchingProfile);
  };
  return (
    <>
      <Header />
      <Flex
        flexDirection='row'
        height='100vh'
        justifyContents='center'
        alignItems='center'
        padding='64px 0 0 0'
      >
        <Container>
          <ImageWrapper>
            <img src='/noCenter.png' />
          </ImageWrapper>
          <Span color='white' fontWeight='700' fontSize='2rem'>
            센터에서 승인 절차가 진행중입니다
          </Span>
        </Container>
      </Flex>
    </>
  );
}
export default PendingView;
