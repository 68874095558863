import styled from 'styled-components';
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
export const TableContainer = styled.div`
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;
export const Table = styled.table`
    width: 100%;
    border: none;
    margin-top: 15px;
    padding: 10px 0;
`;
export const THead = styled.thead`
    border-bottom: 1px solid var(--c700);
`;
export const TBody = styled.tbody``;
export const Th = styled.th`
    white-space: nowrap;
    padding: 15px 10px;
    vertical-align: middle;
    text-align: left;
    font-weight: 500;
`;
export const Tr = styled.tr`
    :not(:last-child) {
        border-bottom: 1px solid var(--c700);
    }
`;
export const Td = styled.td`
    white-space: nowrap;
    padding: 15px 10px;
    vertical-align: middle;
`;
export const PaginationContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 15px;
`;
type PaginationItemType = {
    isActive: boolean;
};
export const PaginationItem = styled.div<PaginationItemType>`
    color: ${({ isActive }) => (isActive ? 'white' : 'var(--c300)')};
    font-size: 1.4rem;
    cursor: pointer;
`;
type RoleBoxType = {
    role: string;
};
export const RoleBox = styled.span<RoleBoxType>`
    padding: 6px 13px;
    justify-content: center;
    align-items: center;
    border: 1px solid
        ${(props) => {
            switch (props.role) {
                case 'owner':
                    return 'white';
                case 'staff':
                    return '#ffe000';
                case 'member':
                    return 'var(--primary)';
                default:
                    return 'var(--c300)';
            }
        }};
    color: ${(props) => {
        switch (props.role) {
            case 'owner':
                return 'white';
            case 'staff':
                return '#ffe000';
            case 'member':
                return 'var(--primary)';
            default:
                return 'var(--c300)';
        }
    }};
    font-size: 1.3rem;
    border-radius: 20px;
`;
export const DeleteButton = styled.button`
    height: 30px;
    text-align: center;
    padding: 0px 15px;
    font-size: 13px;
    border-radius: 4px;
    color: white;
    background-color: var(--semantic-red);
    border: none;
    cursor: pointer;
`;
