import { useEffect, useState, useRef } from 'react';
import { Flex, Margin, Span } from 'styles/style';
import { InputContainer, AddImage } from 'styles/FormStyle';
import {
  MainVideoContainer,
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
  ControlsContainer,
  ProgressTime,
  ProgressBar,
  CurrentBar,
  PlayButtonWrapper,
  HorizontalBox,
  VerticalBox,
} from './style';
import { Title2 } from '../AddSessionForm/style';
import Icon from 'components/Icon';
import {
  getQuality,
  getRatio,
  getMMSSFormat,
  getMMSSFormat_colon,
} from 'constants/video';
import Loading from 'components/Loading';
import { SceneType, SessionFormType, VideoType } from 'constants/type';
import {
  useForm,
  FieldErrors,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';
import { Error } from 'styles/FormStyle';
import VideoPlayer from 'components/VideoPlayer';
import FeedbackVideoInputDetail from 'components/FeedbackVideoInputDetail';
import { AddSceneText } from 'components/Scene/style';
import { AddScene } from 'components/AddScene';
import { SortButton } from 'components/MainVideoInput/style';
import Scene from 'components/Scene';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';

export type MainVideoInputType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  mainVideo: VideoType;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  isEdit: boolean;
  errors: FieldErrors<any>;
  register: UseFormRegister<SessionFormType>;
};
const MainVideoInputDetail = ({
  setIsLoading,
  mainVideo,
  sceneList,
  setSceneList,
  isEdit,
  errors,
  register,
}: MainVideoInputType) => {
  const [feedbackScene, setFeedbackScene] = useState<SceneType>();
  const [isVideoCanPlay, setIsVideoCanPlay] = useState(false);
  const [quality, setQuality] = useState<string>('');
  useEffect(() => {
    setFeedbackScene(
      sceneList.filter((scene) => scene.sceneType === 'coaching')[0]
    );
    setQuality(
      getQuality(
        mainVideo.metadata.originalWidth,
        mainVideo.metadata.originalHeight
      )
    );
  }, []);
  //video hook
  const { videoRef, isVideoPlaying, play, pause, currentBarHandler } =
    useVideoControl();
  const { currentTime } = useVideoMetadata({
    videoRef,
    video: mainVideo,
    setIsVideoCanPlay,
  });
  const {
    formState: { isDirty },
  } = useForm();

  const sortScene = () => {
    const newSceneList = sceneList.sort(
      (a, b) => a.sceneStartAt - b.sceneStartAt
    );
    setSceneList([...newSceneList]);
  };

  return (
    <>
      <InputContainer>
        {mainVideo ? (
          <>
            <MainVideoContainer>
              {mainVideo.ratio === 'landscape' ? (
                <HorizontalBox>
                  <VideoPlayer
                    src={mainVideo.streamingUrl}
                    videoRef={videoRef}
                  />
                </HorizontalBox>
              ) : (
                <VerticalBox>
                  <VideoPlayer
                    src={mainVideo.streamingUrl}
                    videoRef={videoRef}
                  />
                </VerticalBox>
              )}
              <Flex
                flex={1}
                flexDirection='column'
                gap='41px'
                justifyContents='center'
              >
                <Flex flexDirection='row' justifyContents='space-between'>
                  <MetaDataContainer>
                    <MetaDataColumn>
                      <MetaDataTitle>영상길이</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay
                          ? getMMSSFormat(mainVideo.duration)
                          : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                    <MetaDataColumn>
                      <MetaDataTitle>비율</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay
                          ? `${
                              mainVideo.ratio === 'landscape'
                                ? '가로형 (16:9)'
                                : '세로형 (9:16)'
                            }`
                          : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                    <MetaDataColumn>
                      <MetaDataTitle>화질</MetaDataTitle>
                      <MetaDataValue>
                        {isVideoCanPlay ? quality : '-'}
                      </MetaDataValue>
                    </MetaDataColumn>
                  </MetaDataContainer>
                </Flex>
                <ControlsContainer>
                  {isVideoPlaying ? (
                    <PlayButtonWrapper onClick={pause}>
                      <Icon icon='PauseButton' width={20} height={20} />
                    </PlayButtonWrapper>
                  ) : (
                    <PlayButtonWrapper onClick={play}>
                      <Icon icon='PlayButton' width={20} height={20} />
                    </PlayButtonWrapper>
                  )}
                  <Flex flexDirection='column' gap='7px'>
                    <ProgressTime>
                      {getMMSSFormat_colon(currentTime)} /{' '}
                      {isVideoCanPlay
                        ? getMMSSFormat_colon(mainVideo.duration)
                        : '00:00'}
                    </ProgressTime>
                    <ProgressBar
                      onClick={(e) => {
                        currentBarHandler(e);
                      }}
                    >
                      <CurrentBar
                        width={
                          (currentTime /
                            1000 /
                            ((mainVideo.duration < 1000
                              ? 1000
                              : mainVideo.duration) /
                              1000)) *
                          100
                        }
                      />
                    </ProgressBar>
                  </Flex>
                </ControlsContainer>
              </Flex>
            </MainVideoContainer>
            <Margin margin='30px 0 0 0' />
            {isEdit && (
              <>
                <Flex margin='0px 0 10px 0'>
                  <AddSceneText>씬 추가하기</AddSceneText>
                </Flex>
                <AddScene
                  ratio={mainVideo.ratio}
                  duration={mainVideo.duration}
                  sceneList={sceneList}
                  setSceneList={setSceneList}
                  video={mainVideo.streamingUrl}
                />
              </>
            )}
            {sceneList.filter((scene) => scene.sceneType === 'custom_scene')
              .length > 0 && (
              <Flex margin='0px 0 10px 0' justifyContents='space-between'>
                <AddSceneText>추가한 씬</AddSceneText>
                <SortButton onClick={() => sortScene()}>정렬하기</SortButton>
              </Flex>
            )}
            {sceneList
              .filter((scene) => scene.sceneType === 'custom_scene')
              .map((scene, i) => {
                return (
                  <Scene
                    key={scene.sceneStartAt + '_' + i}
                    index={i}
                    scene={scene}
                    video={mainVideo.streamingUrl}
                    setIsLoading={setIsLoading}
                    ratio={mainVideo.ratio}
                    duration={mainVideo.duration / 1000}
                    sceneList={sceneList}
                    setSceneList={setSceneList}
                    errors={errors}
                    register={register}
                    isEdit={isEdit}
                  />
                );
              })}

            {feedbackScene && (
              <FeedbackVideoInputDetail
                setIsLoading={setIsLoading}
                ratio={mainVideo.ratio}
                sceneList={sceneList}
                setSceneList={setSceneList}
                feedbackScene={feedbackScene}
                duration={mainVideo.duration / 1000}
                isEdit={isEdit}
                // errors={errors}
                // register={register}
              />
            )}
          </>
        ) : (
          <>
            <Title2>세션 영상 추가</Title2>
            <label htmlFor='mainVideo'>
              <AddImage>
                <Span>+</Span>
                <Span>영상 추가</Span>
              </AddImage>
            </label>
          </>
        )}
        {errors.mainVideo && mainVideo.streamingUrl === '' && (
          <Error role='alert'>{errors.mainVideo.message?.toString()}</Error>
        )}
      </InputContainer>
    </>
  );
};
export default MainVideoInputDetail;
