import { useEffect, useState } from 'react';
import { Flex, Margin, Span, Container, Head } from 'styles/style';
import { useCenterStore } from 'store/auth';
import { ManageTable } from 'components/ManageTable';
import { ListInfoType } from 'constants/type';
import { postProposalMemberList } from 'apis/user';
const ManageUserPage = () => {
  const { center, setCenter } = useCenterStore();
  const [userList, setUserList] = useState([]);
  const [userListInfo, setUserListInfo] = useState<ListInfoType>({
    count: 0,
    isLastOffset: false,
    limit: 0,
    offset: 0,
    totalCount: 0,
  });
  const [offset, setOffset] = useState<number>(0);

  useEffect(() => {
    setData();
  }, []);
  useEffect(() => {
    setData();
  }, [offset]);

  const setData = async () => {
    const response = await postProposalMemberList({
      centerId: center.centerId,
    });
    const { memberlist, listInfo } = response;
    if (memberlist) {
      setUserList(memberlist);
    }
    if (listInfo) {
      setUserListInfo(listInfo);
    }
  };
  return (
    <>
      <Container>
        <Head>회원 리스트</Head>
        <Margin margin='30px 0 0' />
        <Flex justifyContents='space-between'>
          <Span color='var(--c100)'>총 {userListInfo.totalCount}명</Span>
          {/* <Span color='var(--c100)'>1/5페이지</Span> */}
        </Flex>
        <ManageTable
          type='member'
          dataList={userList}
          dataInfo={userListInfo}
          setOffset={setOffset}
        />
      </Container>
    </>
  );
};
export default ManageUserPage;
