import { Container, AddCommentContainer, MoreButton } from './style';
import Icon from 'components/Icon';
import {
  ProfileImageWrapper,
  ProfileContainer,
  SeeMoreBox,
  TextArea,
  AddCommentButton,
} from '../CommentContainer/style';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import React, { useEffect, useRef, useState } from 'react';
import { SubReplyItem } from 'components/SubReplyItem';
import { ReplyType } from 'constants/type';
import MyProfileImage from 'components/MyProfileImage';
import { postCreateReply, postReplyList } from 'apis/community';
type Type = {
  postId: string;
  parentReplyId: string;
  isOpen: boolean;
  openIds: string[];
  orderBy: string;
  setOpenIds: React.Dispatch<React.SetStateAction<string[]>>;
  menuOpenId: string;
  setMenuOpenId: React.Dispatch<React.SetStateAction<string>>;
};
export const SubReplyContainer = ({
  postId,
  parentReplyId,
  isOpen,
  openIds,
  setOpenIds,
  orderBy,
  menuOpenId,
  setMenuOpenId,
}: Type) => {
  //auth
  const { center } = useCenterStore();
  const { profile } = useProfileStore();

  const [subReplyList, setSubReplyList] = useState<ReplyType[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [content, setContent] = useState<string>('');
  const [offset, setOffset] = useState(0); // 추가
  const [totalCount, setTotalCount] = useState(0);
  const adjustHeight = (isInit: boolean) => {
    if (textAreaRef.current && isInit) {
      textAreaRef.current.style.height = `20px`;
    } else if (textAreaRef.current) {
      textAreaRef.current.style.height = 'inherit';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };
  useEffect(() => {
    adjustHeight(false);
    getSubReplyList();
  }, [isOpen]);
  useEffect(() => {
    getSubReplyList();
  }, [parentReplyId, offset]);
  const addComment = async () => {
    const response = await postCreateReply({
      params: { postId },
      data: {
        centerId: center.centerId,
        parentReplyId,
        content,
        orderBy: 'asc',
      },
    });
    const result = response;
    if (!result) return;

    const { replylist, listInfo } = result;
    if (listInfo) {
      setTotalCount(listInfo.totalCount);
    }

    if (replylist) {
      setSubReplyList(replylist);
    } else {
      setSubReplyList([]);
    }
    setContent('');
    adjustHeight(true);
  };

  const getSubReplyList = async () => {
    const response = await postReplyList({
      params: {
        postId,
      },
      data: {
        centerId: center.centerId,
        parentReplyId,
        limit: 10,
        offset,
        orderBy: 'desc',
      },
    });
    const result = response;
    if (!result) return;

    const { listInfo, replylist } = result;
    listInfo && setTotalCount(listInfo.totalCount);
    if (replylist?.length > 0 && offset === 0) {
      setSubReplyList(replylist);
    } else if (replylist) {
      setSubReplyList((prevList) => [...prevList, ...replylist]);
    }
  };

  const handleSeeMore = (parentReplyId: string) => {
    if (openIds.includes(parentReplyId)) {
      const newArray = openIds.filter((id) => id !== parentReplyId);
      setOpenIds(newArray);
    } else {
      setOpenIds((prev) => [...prev, parentReplyId]);
    }
  };
  return (
    <>
      {subReplyList && subReplyList.length > 0 && (
        <>
          <SeeMoreBox onClick={() => handleSeeMore(parentReplyId)}>
            ...{totalCount}개의 대댓글 더보기
          </SeeMoreBox>
        </>
      )}
      {(isOpen || openIds.includes(parentReplyId)) && (
        <Container>
          <AddCommentContainer>
            <ProfileContainer>
              <ProfileImageWrapper>
                <MyProfileImage />
              </ProfileImageWrapper>
              {profile.nickname}
            </ProfileContainer>
            <TextArea
              rows={1}
              ref={textAreaRef}
              onInput={() => adjustHeight(false)}
              onChange={(e) => setContent(e.target.value)}
              value={content}
              placeholder='댓글을 남겨주세요'
            />
            {content !== '' ? (
              <AddCommentButton isActive={true} onClick={() => addComment()}>
                등록
              </AddCommentButton>
            ) : (
              <AddCommentButton isActive={false}>등록</AddCommentButton>
            )}
          </AddCommentContainer>
          {subReplyList &&
            subReplyList.length > 0 &&
            subReplyList.map((reply: ReplyType, index: number) => {
              return (
                <SubReplyItem
                  key={'subReplyItem' + reply.replyId}
                  reply={reply}
                  menuOpenId={menuOpenId}
                  setMenuOpenId={setMenuOpenId}
                />
              );
            })}
          {totalCount > subReplyList.length && (
            <MoreButton onClick={() => setOffset((prev) => prev + 10)}>
              <Icon icon='ArrowDown' width={11} height={11} />
              더보기
            </MoreButton>
          )}
        </Container>
      )}
    </>
  );
};
