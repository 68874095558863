import { AxiosResponse } from 'axios';
type type = (...args: any[]) => Promise<AxiosResponse<any>>;
export const withErrorHandling = (apiCall: type) => {
  return function (...args: any[]): Promise<any> {
    return apiCall(...args)
      .then((response) => {
        if (!response?.data) {
          return new Error('undefined response');
        }
        return response.data;
      })
      .catch((error) => {
        console.error('API call error:', error);
      });
  };
};
