const Logo = (props: any) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    data-name='layer 2'
    width='auto'
    height='auto'
    viewBox='0 0 703.4 100'
  >
    <defs>
      <linearGradient
        id='a'
        x1='196.67'
        x2='196.67'
        y1='1.58'
        y2='97.66'
        gradientTransform='matrix(1 0 .0926 1 -180.54 0)'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0' stop-color='#191919' />
        <stop offset='1' stop-color='#16f596' />
      </linearGradient>
      <linearGradient
        id='b'
        x1='578.19'
        x2='578.19'
        y1='71.88'
        y2='4.77'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='.11' stop-color='#16f596' />
        <stop offset='.44' stop-color='#f6f6f6' />
      </linearGradient>
    </defs>
    <g data-name='logo set'>
      <path fill='url(#a)' d='M21.81 97.66H0L20.23 1.58l21.41 2.08-19.83 94z' />
      <path
        fill='#16f596'
        d='M20.23 1.58s40.45-4.74 64.93 2.19c7.11 2.01 9.8 9.8 5.31 15.15-4.25 5.06-12.51 5.45-17.42.89-8.04-7.47-32.17-16.33-52.82-18.23ZM54.56 51.91c10.78-2.79 21.63-7.88 30.21-16.89 3.14-3.3-.56-8.42-5.06-7.06-11.39 3.44-34.68 7.07-65.5 2.2L9.33 53.33s9.04 1.79 21.46 1.61c23.77 12.35 30.54 42.71 30.54 42.71h27.81c-3.55-27.54-18.59-40.22-34.58-45.74ZM459.15 89.57h203.71c-1.51 6.12-7 10.43-13.31 10.43H445.84c1.51-6.12 7-10.43 13.31-10.43ZM462.88 74.25h217.79c-1.51 6.12-7 10.43-13.31 10.43H449.57c1.51-6.12 7-10.43 13.31-10.43Z'
      />
      <path
        fill='url(#b)'
        d='M697.68 16.38C683.45-3.31 654.26 1.65 631.24 1.51l-10.96 52.35h20.24l7.53-36.09c5.9.08 20.3-.43 24.19 1.21 19.84 7.38 12.27 39.94-3.24 39.95H466.3c-6.31 0-11.8 4.3-13.31 10.43h216.55c42.31-.32 36.08-42 28.16-52.96Z'
      />
      <path
        fill='#f6f6f6'
        d='M386.25 2.07 364.43 97.7h20.49l9.19-40.23h44.78l3.26-15.17h-44.57l5.72-25.06h46.99l3.26-15.17h-67.3zM549.94 2.07l-11.59 50.8h65.21l2.27-10.57h-44.57l5.73-25.06h46.98l3.26-15.17h-67.29zM468.59 2.07 457 52.87h65.21l2.27-10.57h-44.57l5.73-25.06h46.98l3.26-15.17h-67.29z'
      />
      <g fill='#f6f6f6'>
        <path d='M120.4 2.04 98.56 97.7h67.25l3.26-15.17h-46.54l5.72-25.07h44.8l3.26-15.18h-44.59l5.73-25.07h47l3.26-15.17H120.4zM242.22 77.58l3.48 20.12h20.55L249.7 2.04h-21.99L177.42 97.7h19.98l10.58-20.12h34.25Zm-8.48-49.02 5.85 33.84h-23.65l17.79-33.84ZM299.86 82.53l18.39-80.49h-20.49L275.89 97.7h71.94l3.26-15.17h-51.23z' />
      </g>
    </g>
  </svg>
);

export default Logo;
