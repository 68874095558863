import EditCenterForm from 'components/EditCenterForm';
import styled from 'styled-components';

const EditCenterPage = () => {
  return (
    <EditCenterFormWrapper>
      <EditCenterForm />
    </EditCenterFormWrapper>
  );
};

export default EditCenterPage;

const EditCenterFormWrapper = styled.div`
  margin: 100px auto;
`;
