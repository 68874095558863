import styled from 'styled-components';
export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 27px;
`;
export const FormWrapper = styled.div`
  position: relative;
  padding: 26px 32px;
  background-color: var(--background-color);
  width: 733px;
  border-radius: 3px;
`;
export const Title = styled.div`
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
`;
const Button = styled.button`
  width: 83px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.5px;
`;
export const SubmitButton = styled(Button)`
  background-color: var(--primary);
  color: black;
`;

export const DeleteButton = styled(Button)`
  background-color: var(--semantic-red);
  color: black;
`;
export const Title2 = styled(Title)`
  margin: 20px 0 20px;
  font-size: 1.8rem;
`;
export const InputContainer_noW100 = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
  margin-bottom: 30px;
`;
export const PrepareCircle = styled.div`
  width: 86px;
  min-width: 86px;
  height: 86px;
  background-color: #2c2d34;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100%;
  color: var(--c500);
  flex-direction: column;
  font-size: 1.4rem;
  cursor: pointer;
`;

export const OverlayX = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;
  :hover {
    opacity: 100%;
    transition: all 0.3s;
  }
`;
export const W300 = styled.div`
  width: 440px;
  overflow: auto;
  display: flex;
  gap: 10px;
  justify-content: left;
  overflow-y: hidden;
  align-items: flex-start;
`;
export const CheckBoxContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  cursor: pointer;
  > * {
    cursor: pointer;
  }
`;
