import AddSessionForm from 'components/AddSessionForm';
import styled from 'styled-components';

const AddSessionPage = () => {
  return (
    <>
      <Container>
        <AddSessionForm />
      </Container>
    </>
  );
};
export default AddSessionPage;

export const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: 3px;
`;
