import {
  Title,
  Container,
  Contents,
  TermBody,
  TermTitle,
  TermBox,
  Dl,
  Dt,
  Dd,
  Table,
  Th,
  Td,
} from 'styles/TermStyle';
import { Margin } from 'styles/style';

export const Term1 = () => {
  return (
    <Container>
      <Title>Real Feed 이용약관</Title>
      <Margin margin='0 0 20px 0' />
      <Dd>
        안녕하세요. 저희는 Real-Feed 서비스를 운영하고 있는 주식회사
        스판링크라고 합니다. 저희가 설명해드릴 내용은 회사와 아카데미, 회원 간의
        웹사이트 및 응용프로그램(앱, 어플리케이션 포함, 이하 총칭하여
        ‘플랫폼’이라 합니다)을 통해 제공하는 Real-Feed 서비스(이하
        ‘서비스’라고도 합니다)를 이용하는데 필요한 권리, 의무 및 책임사항,
        서비스 이용조건 및 절차 등 기본적인 사항들을 정리한 이용약관 내용입니다.
        다만, 회사는 다양한 서비스를제공하는 과정에서 서비스 이용약관 외에 개별
        약관 또는 정책 등을 적용할 수 있음을 알려드립니다.
      </Dd>
      <Margin margin='0 0 20px 0' />
      <TermBox>
        <Dl>
          <Dt>1. 용어의 뜻</Dt>
          <Dd>
            1.1 '회사'란 Real-Feed 서비스를 운영하고 있는 주식회사 스판링크를
            말합니다.
            <br /> 1.2. '아카데미'이란 회사와 계약하여 승인을 얻은 자로서 회사가
            플랫폼에서 제공하는 모든 서비스를 계속적으로 활용할 수 있는 지위를
            가진 자를 말합니다. <br />
            1.3. '회원'이란 플랫폼에 회원가입을 한 자로서 회사나 아카데미가
            플랫폼에서 제공하는 모든 서비스를 계속적으로 이용할 수 있는 지위를
            가진 자를 말합니다. <br />
            1.4. '아카데미 구독서비스'란 플랫폼에서 제공하는 모든 프로그램과
            수강 콘텐츠를 활용할 수 있는 공간서비스로 유료서비스를 의미합니다.{' '}
            <br />
            1.5. '계정정보'란 회원(또는 회원이 되려고 하는 자)이 플랫폼을 통하여
            회사에 제공하는 전자우편 등 회원과 관련한 정보를 포괄하여
            의미합니다.
          </Dd>
          <Dt>2. 약관의 효력 및 개정</Dt>
          <Dd>
            이 약관은 서비스를 이용하고자 하는 모든 아카데미, 회원에게 효력이
            발생합니다. <br />
            2.1. 회사는 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지
            주소, 전화번호, 전자우 편 주소, 사업자등록번호, 통신판매업신고번호,
            개인정보관리책임자 등을 아카데미, 회원이 인지할 수 있는 위치에
            게시합니다. 다만, 약관의 내용은 아카데미, 회원이 연결화면을 통하 여
            볼 수 있도록 합니다. <br />
            2.2. 회사는 아카데미승인, 회원이 회원가입을 진행하는 경우, 별도의
            연결화면 또는 팝업화 면 등을 통하여 회원이 약관의 전체 내용을 확인할
            수 있도록 조치합니다. <br />
            2.3. 회사는 서비스를 구성하는 개별 서비스에 관하여 개별 약관 또는
            정책을 운영할 수 있 습니다. 이 경우 개별 약관 또는 정책은 이 약관과
            동일하게 아카데미, 회원에게 효력을 가 지며 이 약관과 상호 간에
            모순이 있는 경우 개별 약관 또는 정책이 우선 적으로 효력을 가 집니다.
            <br />
            2.4. 이 약관에 동의하는 것은 정기적으로 이용약관의 변경사항을
            확인하는 것에 동의함을 의미합니다. 이에 대하여 변경된 약관을 알지
            못해 발생하는 피해는 회사에서 책임지지 않 습니다.
          </Dd>
          <Dt>3. 약관 외 준칙</Dt>
          <Dd>
            이 약관에 명시되지 않은 사항은 「약관의 규제에 관한 법률」,
            「전자상거래 등에서의 소 비자 보호에 관한 법률」, 「정보통신망
            이용촉진 및 정보보호 등에 관한 법률」, 「소비자 기본법」 등 관련
            법령과 개인정보 처리방침 등에 의합니다.
          </Dd>
          <Dt>4. 서비스 이용</Dt>
          <Dd>
            4.1. 아카데미, 회원은 컴퓨터, 휴대전화 등 정보통신기기를 사용하여
            서비스를 이용할 수 있으며, 개별 서비스의 구체적인 내용과 이용조건은
            개별 약관 및 정책 등을 서비스 내에 아카데미, 회원이 인지 가능한
            위치에서 확인할 수 있습니다. <br />
            4.2. 회사는 필요하다고 판단될 경우 아카데미, 회원에 대한 사전 통지
            없이 언제라도 서비 스의 전부 또는 일부 내용을 변경할 수 있으며 또한
            제공을 중지할 수 있습니다. <br />
            4.3. 회사는 아카데미, 회원 모두에게 기본적으로 동일한 내용의
            서비스를 제공합니다. 다 만, 당사가 개별 서비스 제공 관련한 특별한
            사정에 따라 연령 또는 일정한 기준으로 이용 자를 구분하여 서비스를
            제공하거나 서비스의 내용, 이용 시간, 이용 횟수 등을 다르게 하 는 등
            서비스 이용을 제한할 수 있습니다. <br />
            4.4. 회사는 아카데미, 회원들에게 최고의 서비스를 제공하기 위하여
            최선의 노력을 다합니 다. 다만, 장비의 유지 및 보수를 위한 정기 또는
            임시 점검, 다른 이유로 서비스의 제공이 일시 중단될 수 있습니다.{' '}
            <br />
            4.5. 회사는 원칙적으로 회원에게 연중무휴 1일 24시간 서비스를
            제공하고, 회사가 제공하 는 유료 서비스의 종류와 내용은 회사의 정책
            등에 따라 변경될 수 있습니다. <br />
            4.6. 다음 각 호의 어느 하나에 해당하는 경우에는 일정한 시간 동안
            서비스가 제공되지 아 니하거나 서비스 일부 또는 전부가 폐지될 수
            있으며, 이 경우 회사는 서비스를 제공할 의 무가 없습니다. <br />
            1. 컴퓨터 등 정보통신설비의 보수점검, 교체, 정기점검 또는 서비스의
            수정을 위하 여 필요한 경우
            <br />
            2. 해킹 등의 전자적 침해사고, 통신사고, 회원들의 비정상적인 서비스
            이용행태, 예 상할 수 없는 서비스의 불안정성에 대응하기 위하여 필요한
            경우 <br />
            3. 관련 법령에서 특정 시간 또는 방법으로 서비스 제공을 금지하는 경우{' '}
            <br />
            4. 천재지변, 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스
            이용의 폭주 등으 로 정상적인 서비스 제공이 불가능할 경우 5. 분할,
            합병, 영업양도, 영업의 폐지, 당해 서비스의 수익 악화 등 회사의
            경영상 중대한 필요에 의한 경우 <br />
            4.7. 회사는 위 4.6.의 1,3,5 호의 경우, 그 사실을 아카데미, 회원에게
            플랫폼을 통해 사전 공지합니다. 다만 회사가 사전에 고지하였음에도
            불구하고 회사의 귀책사유 없이 위 기간 동안 회원이 공지 내용을
            인지하지 못한데 대하여 회사는 책임을 부담하지 아니합니다. <br />
            4.8. 회사는 사전에 아카데미, 회원에게 공지 내지 통지할 수 없는
            부득이한 사정(긴급한 시스템 점검 · 증설 · 교체, 시스템 관리자의
            고의, 과실 없는 디스크 장애 · 시스템 다운, 제3자인 PC통신 회사,
            기간통신사업자 등의 사정으로 인한 서비스 제공의 불가 등 회사가
            통제할 수 없는 사정)이 있는 경우에는 그 사정이 종료된 후에 공지 내지
            통지할 수 있습니 다.
          </Dd>
          <Dt>5. 아카데미 승인과 아카데미 탈퇴</Dt>
          <Dd>
            5.1. 아카데미는 회사가 정한 아카데미 승인 절차에 따라 아카데미
            정보를 입력한 후 이 약 관에 동의한다는 의사표시를 함으로써 아카데미
            승인(본 약관에서 ‘계정생성’이라고도 합니 다)을 신청합니다. <br />
            5.2. 아카데미승인 절차에서 본 약관의 동의를 표시함으로써 본 약관의
            내용을 인지하고 이 해하였으며 적용을 받음에 동의하는 것으로 간주
            됩니다.
            <br />
            5.3. 회사는 기본적으로 아카데미 승인 절차에 따라 신청한 아카데미를
            승인합니다. 다만, 아래와 같은 경우 회사는 아카데미 승인을 연기 또는
            거절할 수 있습니다. 이러한 경우 아 카데미는 일체의 권리를 주장할 수
            없습니다. <br />
            1. 아카데미에 필수적으로 요구되는 정보의 제공을 거절한 경우 <br />
            2. 타인의 정보 또는 허위 정보를 입력하여 아카데미승인을 신청한 경우{' '}
            <br />
            3. 사회질서를 파괴할 목적으로 아카데미 승인을 신청한 경우
            <br /> 4. 이용약관, 관련 법령 등의 위반을 하여 계정이 정지된 경우{' '}
            <br />
            5. 회사의 영업을 방해하거나 회사에 대하여 손해를 입힐, 그러한
            목적으로 아카데미 승인을 신청하였다고 의심할 만한 사유가 있는 경우
            <br />
            6. 기타 물리적, 기술적 사유로 아카데미를 승인할 수 없는 경우 <br />
            7. 위 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우{' '}
            <br />
            5.4. 아카데미는 언제든지 이 약관에 따라 아카데미탈퇴(본 약관에서
            ‘계정삭제’라고도 함) 를 신청할 수 있으며 회사는 기본적으로
            아카데미가 신청한 아카데미 탈퇴를 승낙합니다. 다만, 아래의 어느
            하나에 해당하는 경우 회사는 아카데미 탈퇴의 승낙을 거절하거나 보류
            할 수 있습니다.
            <br /> 1. 아카데미가 회사에 대하여 이행하여야 하는 법률상 의무가
            존재하는 경우
            <br /> 2. 아카데미와 회사 사이에 분쟁이 발생하거나 발생할 가능성이
            상당한 경우
            <br />
            5.5. 아카데미가 서비스에서 가지는 모든 이용 권한은 아카데미탈퇴를 한
            시점에 소멸됩니 다. <br />
            5.6. 아카데미가 탈퇴(계정삭제)한 경우, 이용 중인 서비스를 더 이상
            이용할 수 없으며, 이 에 대한 후속 조치에 관하여는 본 약관에 따라
            처리됩니다
          </Dd>
          <Dt>6. 회원가입과 회원탈퇴</Dt>
          <Dd>
            6.1. 회원은 회사가 정한 회원가입 절차에 따라 회원정보를 입력한 후 이
            약관에 동의한다 는 의사표시를 함으로써 회원가입(본 약관에서
            ‘계정생성’이라고도 합니다)을 신청합니다. <br />
            6.2. 아카데미나 회원은 가입 절차에서 본 약관의 동의를 표시함으로써
            본 약관의 내용을 인지하고 이해하였으며 적용을 받음에 동의하는 것으로
            간주 됩니다. <br />
            6.3. 회사는 기본적으로 회원가입 절차에 따라 신청한 회원가입을
            승낙합니다. 다만, 아래와 같은 경우 회사는 회원가입의 승낙을 연기
            또는 거절할 수 있습니다. 이러한 경우 회원은 일체의 권리를 주장할 수
            없습니다. <br />
            1. 회원가입에 필수적으로 요구되는 정보의 제공을 거절한 경우 <br />
            2. 타인의 정보 또는 허위 정보를 입력하여 회원가입을 신청한 경우{' '}
            <br />
            3. 사회질서를 파괴할 목적으로 회원가입을 신청한 경우
            <br /> 4. 이용약관, 관련 법령 등의 위반을 하여 계정이 정지된 경우{' '}
            <br />
            5. 회사의 영업을 방해하거나 회사에 대하여 손해를 입힐, 그러한
            목적으로 회원가입 을 신청하였다고 의심할 만한 사유가 있는 경우{' '}
            <br />
            6. 영리목적으로 회원가입을 신청한 경우
            <br /> 7. 기타 물리적, 기술적 사유로 회원가입을 승낙할 수 없는 경우
            <br /> 8. 위 각 호에 준하는 사유로서 승낙이 부적절하다고 판단되는
            경우 <br />
            6.4. 회원은 언제든지 이 약관에 따라 회원탈퇴(본 약관에서
            ‘계정삭제’라고도 함)를 신청할 수 있으며 회사는 기본적으로 회원이
            신청한 회원탈퇴를 승낙합니다. 다만, 아래의 어느 하 나에 해당하는
            경우 회사는 회원 탈퇴의 승낙을 거절하거나 보류할 수 있습니다.
            <br /> 1. 회원이 회사에 대하여 이행하여야 하는 법률상 의무가
            존재하는 경우
            <br /> 2. 회원과 회사 사이에 분쟁이 발생하거나 발생할 가능성이
            상당한 경우 <br />
            6.5. 회원이 서비스에서 가지는 모든 이용권한은 회원탈퇴를 한 시점에
            소멸됩니다. 회원의 실수로 회원탈퇴를 한 경우에도 회원의 계정을
            복구할 수 없습니다. 또한, 탈퇴한 회원은 탈퇴일로부터 동일한 아이디로
            회원가입을 할 수 없습니다. <br />
            6.6. 회원이 탈퇴(계정삭제)한 경우, 이용 중인 서비스를 더 이상 이용할
            수 없으며, 이에 대한 후속 조치에 관하여는 본 약관에 따라 처리됩니다.{' '}
            <br />
            6.7. 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은
            14세 미만 미성년자 회원에 대하여는 가입을 취소 또는 불허합니다.{' '}
            <br />
            6.8. 회원은 탈퇴 시까지 플랫폼 내에서 작성한 리뷰를 포함한 콘텐츠에
            대한 삭제, 수정이 가능하나, 탈퇴 이후는 삭제 또는 수정이 불가합니다.
            회원이 탈퇴하는 경우, 탈퇴 전에 삭 제하지 않은 콘텐츠는 탈퇴로
            인하여 삭제되지 않습니다.
          </Dd>
          <Dt>7. 계정정보</Dt>
          <Dd>
            7.1. 계정정보(본 약관에서 ‘계정’이라고도 함)라 함은 회원(또는 회원이
            되려고 하는 자)이 플랫폼을 통하여 회사에 제공하는 이메일, 닉네임,
            비밀번호 등 회원과 관련한 정보를 포괄 하여 의미합니다. <br />
            7.2. 계정을 생성하는 경우, 생성과 동시에 본 약관 및 개인정보
            처리방침에 동의한 것으로 간주됩니다. <br />
            7.3. 회원의 계정정보에 대한 관리 책임은 회원에게 있습니다. 따라서
            회원은 자신의 계정 정보를 제3자가 이용하도록 허용해서는 안 됩니다.
            만일 회원이 본인의 계정정보 관리 내지 보호를 소홀히 함으로써
            발생하는 손해에 대해서는 회원에게 그 책임이 있습니다. <br />
            7.4. 회원은 자신의 계정정보가 도용되거나 제3자가 사용하고 있음을
            인지한 경우에는 이를 즉시 회사에 통지하고, 회사의 안내에 따라야
            합니다. 만일 해당 회원이 회사에 그 사실을 즉시 통지하지 않거나,
            통지한 경우에도 회사의 안내에 따르지 않아 불이익 내지 손해가 발 생한
            경우, 회사는 이에 대한 책임을 지지 않습니다. <br />
            7.5. 회사는 이용약관, 관련 법령 등의 위반을 이유로 정지, 삭제되었던
            회원에 대하여 회원 가입 신청을 거절하거나 이미 가입한 경우 계정을
            삭제할 수 있습니다. <br />
            7.6. 회사는 회원이 이 약관을 위반하거나 또는 위반할 우려가 있다고
            인정된 경우 회원에 대해 사전 통지 없이 계정을 정지 또는 삭제할 수
            있습니다. <br />
            7.7. 회사는 계정의 이용이 정지된 회원이 게시한 정보의 전부 또는
            일부를 임의로 삭제할 수 있습니다
          </Dd>
          <Dt>8. 아카데미 구독서비스의 이용, 취소(해지)</Dt>
          <Dd>
            8.1. 아카데미는 구독서비스 이용금액을 결제한 후에 회사 정책에 따라
            서비스를 이용할 수 있습니다. <br />
            8.2. 아카데미는 이용금액 결제 후, 구독서비스의 이용(계약)을 취소
            내지 해지할 수 있습니 다. 회사는 관계 법령 등에서 정하는 절차에 따라
            이를 신속하게 처리합니다. <br />
            8.3. 아카데미가 구독서비스를 취소, 해지하는 경우, 원칙적으로 결제한
            서비스 이용기간 동 안에는 서비스 이용이 유지되고, 결제한 이용기간이
            종료되는 경우 해지의 효력이 발생하게 됩니다. <br />
            8.4. 아카데미는 이미 결제가 이루어진 서비스의 남은 이용기간 동안
            서비스를 정상 이용할 수 있으며, 별도의 환불은 이루어지지 않습니다.{' '}
            <br />
            8.5. 회사 측의 사정으로 인하여 서비스 이용계약이 취소, 해지된 경우,
            회사는 잔여기간에 대한 해당하는 서비스 이용대금을 아카데미에게
            환불하며, 1개월단위 사용일 계산방식에 따 라 환불금액을 산정합니다.{' '}
            <br />
            8.6. 회사는 환불절차를 서비스 종료일로부터 3 영업일 이내에
            조치합니다. 다만, 부득이한 사정이 있는 경우, 지연될 수 있으며,
            회사는 이에 대하여 아카데미에게 사전 고지합니다. <br />
            8.7. 본 약관에 명시되어 있지 않은 구독 서비스 관련 내용은 회사의
            정책이나 가이드 내에 표시한 내용에 따릅니다.
          </Dd>
          <Dt>9. 결제수단</Dt>
          <Dd>
            <br />
            9.1. 아카데미는 Real-Feed 플랫폼 서비스를 위하여 회사가 제공하는
            결제방식에 따라 결 제하여야 합니다. 아카데미는 언제든지 취소를
            요청할 수 있으며, 사용 중인 서비스 기간이 종료되면 아카데미의
            서비스에 대한 추가 결제가 없는 한 서비스가 종료됩니다. <br />
            9.2. 회원이 자동결제의 방식을 선택하는 경우, 아카데미는 신용카드 등
            결제 관련 정보를 등록해야 합니다
          </Dd>
          <Dt>10. 환불</Dt>
          <Dd>
            10.1. 아카데미는 Real-Feed 플랫폼 서비스 이용 중 회사에 환불을
            요청하고자 하는 경 우, 고객센터로 연락하여 별도의 절차를 따라야
            합니다. <br />
            10.2. 회사는 대금을 환급함에 있어서 아카데미는 신용카드 또는
            전자화폐 등의 결제수단을 제공한 사업자로 하여금 결제를 정지 또는
            취소하도록 요청합니다. 만일 회원이 구매한 결 제수단으로의 환불조치가
            어려운 경우, 회사는 회원에게 계좌를 통한 환불 등의 방식을 선 택하게
            할 수 있습니다
          </Dd>
          <Dt>11. 아카데미, 회원 의무사항</Dt>
          <Dd>
            11.1. 아카데미, 회원은 본 약관의 규정, 서비스 이용안내 및 이와
            관련하여 이루어진 공지 사항, 회사가 통지하는 사항 등을 확인하고
            준수할 의무가 있습니다. <br />
            11.2. 아카데미, 회원은 다음 행위(또는 이에 준하는 행위를
            포함합니다)를 하여서는 안 됩 니다. <br />
            1. 서비스 이용 목적에 위배되는 행위(영리, 비영리목적 불문) <br />
            2. 비정상적인 시스템 접근 · 악성코드, 바이러스 등의 프로그램을 고의
            또는 과실로 설치, 유포하는 행위 <br />
            3. 비정상적인 서비스 이용 · 허위 또는 타 아카데미, 회원의 정보로
            가입을 하는 행위 <br />
            4. 허위 또는 과장된 정보 입력 행위 <br />
            5. 회사가 운영하는 플랫폼 내에 상호(성명), 로고 등 상표, 주소,
            전화번호, 이메일 주소 등 신원 식별을 용이하게 하는 정보를 회사의
            사전 동의 내지 허락을 얻지 아니하고 입력 내지 사용하는 행위 <br />
            6. 공공질서 위반, 성별 · 정치 · 종교 · 장애 · 연령 · 사회적 신분 ·
            인종 · 지역 · 직업 등을 차별하거나 이에 대한 편견을 조장하는 행위{' '}
            <br />
            7. 회사 또는 다른 아카데미, 회원, 제3자를 차별 또는 비방하거나 욕설,
            폭언, 협박, 명예를 훼손하는 행위 <br />
            8. 과도한 신체 노출이나 음란한 행위를 묘사하거나, 성매매 관련 정보를
            공유하거 나, 타인에게 성적 수치심이나 불쾌감을 유발할 수 있는 내용을
            게시하는 등 미풍 양속에 반하는 정보를 등록하는 행위 <br />
            9. 혐오스러운 사진 또는 내용을 게시하거나, 욕설, 비속어, 은어를
            사용하는 등 사회 통념에 반하는 비정상적인 활동을 하는 행위 <br />
            10.회사 및 다른 아카데미, 회원, 제3자의 특허권, 상표권, 저작권 등
            지적재산권을 포함한 타인의 권리 침해하는 행위 또는 침해할 우려가
            있는 행위 <br />
            11.계정 및 계정 내 정보를 타 아카데미, 회원에게 판매, 양도,
            대여하거나, 타인에게 그 이용을 허락 또는 이를 시도하는 행위 및 이를
            알선하는 행위 <br />
            12.타 아카데미, 회원의 권리 보호를 위하여 회사가 정한 정책에 반하는
            행위(ex. 화 면 캡쳐행위 등) <br />
            13.명의 사칭, 도용 등으로 다른 아카데미, 회원, 제3자 또는 회사의
            직원을 사칭하 는 행위 <br />
            14.회사의 이익(영업)을 저해하는 행위 15.기타 위 각 호에 준하는 행위
          </Dd>
          <Dt>12. 지식재산권 보호</Dt>
          <Dd>
            12.1. 본 약관에 따라 아카데미, 회원이 직접 제작하여 플랫폼에 게시
            내지 업로드한 콘텐 츠(부호, 문자, 도형, 색채, 음성, 음향, 음원,
            이미지, 작성후기(리뷰) 및 영상 등(이들의 복 합체를 포함합니다), 이하
            동일)에 대한 저작권은 해당 아카데미, 회원이 소유하며, 회사는 플랫폼
            내에서 서비스의 원활한 제공을 위한 범위 내에서 해당 콘텐츠를
            게시(변형, 가공, 요약, 발췌, 취합 행위를 포함)할 수 있는 권리를
            갖습니다. <br />
            12.2. 회사는 아카데미, 회원이 플랫폼에 게시하는 콘텐츠가 다음 각
            호에 해당하는 경우 플랫폼 게시를 금지합니다. 이 경우 회사는 임의로
            회원이 작성한 콘텐츠를 삭제할 수 있습 니다. <br />
            1. 서비스 이용 목적에 부합하지 않는 경우 <br />
            2. 제3자의 개인정보를 직접 기재하는 등으로 제3자의 권리를 침해하는
            경우 <br />
            3. 회사의 플랫폼을 통하지 않고 직접 거래를 유도하거나 권유, 제안하는
            경우 <br />
            4. 객관적인 사실과 부합하지 않는 경우 <br />
            5. 본 약관에 위배되거나 불법, 음란, 저속하다고 판단되는 내용인 경우{' '}
            <br />
            6. 다른 회원 또는 제3자를 비방하거나 명예를 훼손시키는 내용인 경우{' '}
            <br />
            7. 공공질서 및 공서양속에 위반되는 내용인 경우 <br />
            8. 범죄적 행위에 결부된다고 인정되는 내용일 경우 <br />
            9. 제3자의 초상권, 저작권 등 기타 권리를 침해하는 내용인 경우 <br />
            10.수신자의 의사에 반하는 광고성 정보를 담은 내용인 경우 <br />
            11.상품과 무관하거나 객관적 증거 없이 비방할 목적으로 작성한 내용인
            경우 <br />
            12.기타 관계 법령에 위배되는 경우 <br />
            12.3. 회사는 아카데미, 회원이 다른 아카데미, 회원 또는 제3자의
            저작권을 침해하는 경우, 저작권법 등 관계법령에 정해진 절차에 의하여
            저작권자 또는 아카데미, 회원의 요청에 의 해 해당 콘텐츠를 삭제하거나
            다시 게시할 수 있으며, 이 경우 회사는 면책됩니다. 아카데 미, 회원이
            다른 아카데미, 회원 또는 제3자의 저작권 등의 권리를 침해함으로써
            발생하는 민, 형사상의 책임은 침해한 아카데미, 회원이 전적으로
            부담합니다. <br />
            12.4. 회사가 제작한 콘텐츠에 대한 저작권 및 기타 지식재산권은 회사의
            소유입니다. 회사 는 서비스와 관련하여 회사가 정한 이용조건에 따라
            서비스를 이용할 수 있는 이용권한만을 회원에게 부여하며, 회원은
            서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 플랫폼 에
            게재된 콘텐츠를 상업적으로 사용할 수 없으며, 이를 유상양도, 판매,
            담보제공 등의 처 분행위를 할 수 없습니다. <br />
            12.5. 아카데미, 회원은 회사가 제공하는 서비스를 이용함으로써 얻은
            정보 중 회사 또는 제3자에게 지적재산권 등이 귀속된 정보를 회사 또는
            제3자의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송 기타 방법에
            의하여 이용하거나 제3자에게 이용하게 하여서는 안 됩니다. 이 경우,
            영리, 비영리 이용을 모두 포함합니다. <br />
            12.6. 회사가 제공하는 서비스, 그에 필요한 소프트웨어, 이미지, 마크,
            로고, 디자인, 서비 스 명칭, 정보 및 상표 등과 관련된 지식재산권 및
            기타 권리는 회사에게 있습니다. 아카데 미, 회원은 회사가 명시적으로
            승인한 경우를 제외하고는 상기에 명시한 소정의 각 재산에 대한 전부
            또는 일부의 수정, 대여, 대출, 판매, 배포, 제작, 양도, 재라이센스,
            담보권설정 행위 등 일체의 상업적 이용행위를 할 수 없으며, 제3자로
            하여금 이와 같은 행위를 하도록 허락할 수 없습니다.
          </Dd>
          <Dt>13. 회원정보의 보호</Dt>
          <Dd>
            회사는 회원의 개인정보를 보호하고 존중합니다. 회사는 개인정보
            처리방침에 따라 회원의 개인정보 수집 시 서비스 제공을 위하여 필요한
            범위에서 최소한의 개인정보를 수집하고 이용할 수 있습니다. 다만,
            회원은 언제든지 개인 정보 제공을 거부하거나 개인정보의 삭제를 요청할
            수 있습니다. 회사는 회원의 소중한 개인정보를 보호하기 위하여
            「개인정보 보호법」에 따라 개인정보 처리방침을 수립하고 이를
            준수합니다. 회원은 회사가 회원에 대해 관련 개인정보를 안전하 게
            처리하는 기타 상세한 사항은 개인정보 처리방침에서 확인하실 수
            있습니다.
          </Dd>
          <Dt>14. 마케팅 정보 제공</Dt>
          <Dd>
            14.1. 회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에
            따라 회원에게 문자 메시지(SMS), 전자우편, 알림 메시지(Push
            Notification), 기타 전자적 전송매체를 이용하여 영리목적의 다양한
            마케팅 정보를 제공할 수 있습니다. <br />
            14.2. 회사는 회원에게 광고 수신을 거절할 수 있는 방법을 함께
            제공하며 회원은 광고 수 신을 언제든지 거절할 수 있습니다. 이 경우
            회사는 즉시 마케팅 정보 등을 제공하는 행위 를 중단합니다.
          </Dd>
          <Dt>15. 약관의 통지와 공고</Dt>
          <Dd>
            회사는 필요하다고 판단할 경우, 회원에 대한 사전 통지 없이 언제라도
            이 약관을 변경할 수 있으며 적용일자 및 개정 이유를 공지하여 현재
            약관과 함께 플랫폼의 초기 화면 또는 연결화면 등을 통해 그 적용일자
            7일 이전부터 적용일자 전일까지 공지합니다. 회원에게 불 리하게 약관
            내용을 변경하는 경우에는 최소한 적용일 30일 전부터 공지합니다.
            회원이 개 정된 약관의 적용에 동의하지 않는 경우, 회사는 해당 회원에
            대해 개정된 약관의 내용을 적용할 수 없으며, 서비스 이용계약을 해지할
            수 있습니다. 회원 역시 회원탈퇴의 방법으로 서비스 이용계약을 해지할
            수 있습니다. 만일 회원이 약관의 개정에 동의하지 않음에도 불 구하고
            회사의 공지 또는 개별 고지일자로부터 개정된 약관의 시행일까지
            회원탈퇴를 하지 않고 서비스를 계속 이용하는 경우에는 해당 회원이
            개정된 약관의 내용을 확인하고 동의한 것으로 간주합니다.
          </Dd>
          <Dt>16. 회사의 책임과 면책</Dt>
          <Dd>
            회사의 귀책사유로 인하여 회원이 손해를 입는 경우 회사는 회원에게
            손해를 배상합니다. 다만, 손해배상은 회원이 서비스 이용과 관련하여
            실제로 발생한 손해를 한도로 하며, 회사 의 귀책사유와 무관하게 발생한
            손해에 대하여는 책임을 지지 아니합니다. 즉, 회사는 천재 지변, 전쟁,
            기간통신사업자의 서비스 중지, 해결이 곤란한 기술적 결함 기타
            불가항력으로 인하여 서비스를 제공할 수 없는 경우, 비밀번호 관리의
            소홀, 개인정보의 부정확한 기재, 컴퓨터 오류, 보안 문제로 인하여
            발생하는 제반 문제, 현재의 보안기술 수준으로 방어가 곤란한 네트워크
            해킹 등으로 인하여 발생한 손해에 대하여는 일체의 책임을 지지
            아니합니 다. 회사는 본 서비스에 사실상 또는 법률상의 하자(안전성,
            신뢰성, 완전성, 유효성, 특정 목적 과의 부합 여부, 보안 등에 관련된
            결함, 오류와 버그, 권리 침해 등을 포함함)가 없음을 명 시적으로도
            암묵적으로도 보증하지 않습니다. 회사는 회원에 대해 이러한 하자를
            제거하고 본 서비스를 제공할 의무를 부담하지 않습니다.
          </Dd>
          <Dt>17. 준거법 및 재판관할</Dt>
          <Dd>
            회사와 회원 간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국
            법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는
            대한민국의 법원에 제기합니다. 이 약관은 2023년 9월 18일부터
            적용됩니다.
          </Dd>
        </Dl>
      </TermBox>
    </Container>
  );
};
