import {
  Flex,
  CircleImage,
  SelectWrapper,
  NullDataBox,
  Select,
  Option,
  Button,
} from 'styles/style';
import * as T from 'styles/TableStyle';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { getKoreanDate, maskEmail } from 'constants/common';
import { ListInfoType } from 'constants/type';
import { useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import { postConfirmMember, postConfirmStaff } from 'apis/user';

type Type = {
  type: 'staff' | 'member';
  dataList: any[];
  dataInfo: ListInfoType;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
};
export const ManageTable = ({ dataList, dataInfo, type, setOffset }: Type) => {
  const { accessToken } = useAccessTokenStore();
  const { center } = useCenterStore();
  const [currentPagination, setCurrentPagination] = useState<number>(1);

  useEffect(() => {
    setOffset((currentPagination - 1) * 10);
  }, [currentPagination]);

  const handleButtonClick = (user: any, value: string) => {
    const valueText = value === 'activated' ? '수락' : '거절';
    if (window.confirm(`${user.nickname}님을 ${valueText}하시겠습니까?`)) {
      if (type === 'member') confirmMember(value, user);
      else if (type === 'staff') confirmStaff(value, user);
    }
  };
  const confirmStaff = async (value: string, user: any) => {
    const response = await postConfirmStaff({
      centerId: center.centerId,
      accountId: user.accountId,
      connRegStatus: value,
      nickname: user.nickname,
    });
    const result = response;
    if (result.status && result.status !== 200) return;
    else {
      window.location.reload();
    }
  };
  const confirmMember = async (value: string, user: any) => {
    const response = await postConfirmMember({
      params: {
        accountId: user.accountId,
      },
      data: {
        centerId: center.centerId,
        connRegStatus: value,
      },
    });
    const result = response;
    if (result.status && result.status !== 200) return;
    else {
      window.location.reload();
    }
  };
  return (
    <T.Container>
      <T.TableContainer>
        <T.Table>
          <T.THead>
            <T.Tr>
              <T.Th>이름</T.Th>
              <T.Th>이메일</T.Th>
              <T.Th>연락처</T.Th>
              <T.Th>신청일</T.Th>
              <T.Th>상태 변경</T.Th>
            </T.Tr>
          </T.THead>
          <T.TBody>
            {dataList.length > 0 ? (
              dataList.map((user, index) => {
                return (
                  <T.Tr key={index}>
                    <T.Td>
                      <Flex
                        gap='13px'
                        alignItems='center'
                        justifyContents='left'
                      >
                        <CircleImage size='31px'>
                          <img
                            src={
                              user.profileImage?.thumbnailImage ||
                              '/defaultUserImage.png'
                            }
                            alt='프로필 이미지'
                            onError={(e) => {
                              const target = e.target as HTMLImageElement;
                              target.onerror = null; // 재귀적인 호출을 방지
                              target.src = '/defaultUserImage.png';
                            }}
                          />
                        </CircleImage>
                        {user.nickname}
                      </Flex>
                    </T.Td>
                    <T.Td>{user.email}</T.Td>
                    <T.Td>{user.phoneNumber}</T.Td>
                    <T.Td>
                      {user.createdAt && getKoreanDate(user.createdAt)}
                    </T.Td>

                    {/* <T.Td>{v.createdAt.split(' ')[0]}</T.Td> */}
                    <T.Td>
                      <Flex gap='5px'>
                        <Button
                          border='1px solid var(--semantic-red)'
                          color='var(--semantic-red)'
                          onClick={(e: any) =>
                            handleButtonClick(user, 'refusal')
                          }
                        >
                          거절
                        </Button>
                        <Button
                          border='1px solid var(--primary)'
                          color='var(--primary)'
                          onClick={(e: any) =>
                            handleButtonClick(user, 'activated')
                          }
                        >
                          승인
                        </Button>
                      </Flex>
                    </T.Td>
                  </T.Tr>
                );
              })
            ) : (
              <T.Tr>
                <T.Td colSpan={5}>
                  <NullDataBox>요청이 없습니다</NullDataBox>
                </T.Td>
              </T.Tr>
            )}
          </T.TBody>
        </T.Table>
      </T.TableContainer>
      <Pagination
        listInfo={dataInfo}
        currentPagination={currentPagination}
        setCurrentPagination={setCurrentPagination}
      />
    </T.Container>
  );
};
