import styled from 'styled-components';
import Lottie from 'lottie-react';
import { Margin, Span } from 'styles/style';
import { useEffect } from 'react';

const animationData = {
  v: '5.6.2',
  fr: 60,
  ip: 0,
  op: 200,
  w: 500,
  h: 500,
  nm: 'Render',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'football Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 16.363,
              s: [100],
            },
            { t: 36.81640625, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 0,
              s: [0],
            },
            { t: 49.08984375, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [250, 250.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-35.307, 18.034],
                    [-26.765, -27.715],
                    [12.149, -37.585],
                    [39.483, 3.986],
                    [12.338, 37.585],
                  ],
                  o: [
                    [36.447, 18.983],
                    [-12.339, 37.585],
                    [-39.483, 4.177],
                    [-12.149, -37.585],
                    [28.853, -27.905],
                  ],
                  v: [
                    [0.095, -93.11],
                    [95.766, -23.633],
                    [59.131, 88.933],
                    [-59.13, 88.933],
                    [-95.766, -23.633],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.4, 0.4, 0.4, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.905, 222.761], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-29.613, 36.636],
                    [0, 0],
                    [-9.112, -26.956],
                    [12.528, -28.284],
                  ],
                  o: [
                    [5.125, -49.355],
                    [0, 0],
                    [-10.63, 32.461],
                    [-28.284, 15.186],
                    [0, 0],
                  ],
                  v: [
                    [-248.577, -15.186],
                    [-194.286, -146.545],
                    [-171.508, -150.532],
                    [-174.165, -61.313],
                    [-234.529, 4.556],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [25.247, 33.788],
                    [0, 0],
                    [-30.562, 1.897],
                    [-26.007, -20.691],
                  ],
                  o: [
                    [-39.104, -17.654],
                    [0, 0],
                    [30.752, 20.122],
                    [7.973, 33.409],
                    [0, 0],
                  ],
                  v: [
                    [-102.6, 238.99],
                    [-200.551, 160.213],
                    [-196.754, 112.187],
                    [-104.879, 139.712],
                    [-53.246, 220.387],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [38.913, -17.654],
                    [0, 0],
                    [-7.972, 33.409],
                    [-30.752, 20.122],
                  ],
                  o: [
                    [-25.247, 33.788],
                    [0, 0],
                    [25.816, -20.691],
                    [30.562, 1.897],
                    [0, 0],
                  ],
                  v: [
                    [200.551, 160.213],
                    [102.792, 238.99],
                    [53.436, 220.387],
                    [104.878, 139.712],
                    [196.754, 112.187],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.125, -49.355],
                    [0, 0],
                    [28.095, 15.186],
                    [10.82, 32.461],
                  ],
                  o: [
                    [29.613, 36.636],
                    [0, 0],
                    [-12.528, -28.284],
                    [9.111, -26.956],
                    [0, 0],
                  ],
                  v: [
                    [194.286, -146.545],
                    [248.577, -15.186],
                    [234.529, 4.556],
                    [174.354, -61.313],
                    [171.508, -150.532],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.917, 0],
                    [-21.64, -6.264],
                    [0, 0],
                    [19.932, -17.274],
                    [28.664, 0.949],
                    [0, 0],
                  ],
                  o: [
                    [23.729, 0],
                    [0, 0],
                    [-31.7, 2.278],
                    [-22.968, -18.983],
                    [0, 0],
                    [21.64, -6.264],
                  ],
                  v: [
                    [0.095, -238.99],
                    [68.432, -229.499],
                    [77.733, -215.072],
                    [0.095, -186.029],
                    [-77.734, -215.072],
                    [-68.432, -229.499],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.4, 0.4, 0.4, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.905, 238.991], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [14.617, -14.617],
                    [1.518, 1.519],
                    [-1.519, 1.518],
                    [-17.274, 11.77],
                    [-19.362, 8.732],
                    [-0.949, -1.898],
                    [2.088, -0.949],
                    [16.894, -11.58],
                  ],
                  o: [
                    [-1.519, 1.519],
                    [-1.519, -1.708],
                    [14.996, -14.806],
                    [17.085, -11.769],
                    [2.088, -0.949],
                    [0.76, 2.088],
                    [-18.982, 8.353],
                    [-16.705, 11.579],
                  ],
                  v: [
                    [-172.551, -147.874],
                    [-178.056, -147.874],
                    [-178.056, -153.568],
                    [-129.841, -193.432],
                    [-75.171, -224.184],
                    [-69.856, -222.285],
                    [-71.944, -216.97],
                    [-125.474, -186.977],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.278, 0],
                    [0, -2.277],
                    [0, 0],
                    [2.089, 0],
                    [0, 2.088],
                  ],
                  o: [
                    [0, -2.277],
                    [2.089, 0],
                    [0, 0],
                    [0, 2.088],
                    [-2.278, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.986, -202.164],
                    [0, -206.15],
                    [3.797, -202.164],
                    [3.797, -98.898],
                    [0, -94.913],
                    [-3.986, -98.898],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.996, -14.806],
                    [1.519, -1.708],
                    [1.519, 1.519],
                    [16.894, 11.579],
                    [18.982, 8.353],
                    [-0.949, 2.088],
                    [-1.897, -0.949],
                    [-17.274, -11.769],
                  ],
                  o: [
                    [1.519, 1.518],
                    [-1.518, 1.519],
                    [-14.616, -14.617],
                    [-16.704, -11.58],
                    [-1.899, -0.949],
                    [0.759, -1.898],
                    [19.362, 8.732],
                    [17.084, 11.77],
                  ],
                  v: [
                    [176.727, -153.568],
                    [176.727, -147.874],
                    [171.033, -147.874],
                    [123.956, -186.977],
                    [70.426, -216.97],
                    [68.527, -222.285],
                    [73.652, -224.184],
                    [128.512, -193.432],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.57, 2.088],
                    [-2.089, 0.57],
                    [0, 0],
                    [-0.569, -2.088],
                    [2.278, -0.569],
                  ],
                  o: [
                    [-2.086, 0.57],
                    [-0.569, -2.088],
                    [0, 0],
                    [2.088, -0.57],
                    [0.57, 2.088],
                    [0, 0],
                  ],
                  v: [
                    [87.888, -36.447],
                    [83.143, -39.293],
                    [85.802, -44.039],
                    [181.663, -70.234],
                    [186.408, -67.577],
                    [183.561, -62.642],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.986, 21.26],
                    [-2.088, -0.379],
                    [0.38, -2.089],
                    [7.404, -20.881],
                    [10.06, -20.121],
                    [1.899, 0.949],
                    [-0.949, 1.898],
                    [-7.214, 20.311],
                  ],
                  o: [
                    [0.38, -2.088],
                    [2.088, 0.38],
                    [-4.176, 21.64],
                    [-7.214, 20.691],
                    [-0.95, 1.898],
                    [-1.897, -0.948],
                    [9.871, -19.743],
                    [7.403, -20.501],
                  ],
                  v: [
                    [232.156, -7.023],
                    [236.713, -10.06],
                    [239.939, -5.504],
                    [222.475, 58.086],
                    [196.09, 119.211],
                    [190.774, 120.918],
                    [189.066, 115.604],
                    [215.072, 55.43],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.898, 1.329],
                    [-1.329, -1.899],
                    [0, 0],
                    [1.709, -1.139],
                    [1.139, 1.708],
                  ],
                  o: [
                    [-1.139, -1.709],
                    [1.709, -1.139],
                    [0, 0],
                    [1.329, 1.708],
                    [-1.898, 1.328],
                    [0, 0],
                  ],
                  v: [
                    [49.734, 63.212],
                    [50.873, 57.706],
                    [56.379, 58.846],
                    [114.085, 143.508],
                    [113.136, 148.823],
                    [107.631, 147.874],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.502, 3.417],
                    [-0.38, 2.087],
                    [-2.088, -0.38],
                    [-20.121, 0],
                    [-20.311, 3.417],
                    [-0.379, -2.278],
                    [2.089, -0.379],
                    [20.501, 0],
                  ],
                  o: [
                    [-2.277, -0.379],
                    [0.189, -2.278],
                    [20.312, 3.417],
                    [19.931, 0],
                    [2.089, -0.38],
                    [0.381, 2.087],
                    [-20.69, 3.417],
                    [-20.691, 0],
                  ],
                  v: [
                    [-61.694, 220.008],
                    [-64.92, 215.452],
                    [-60.554, 212.225],
                    [0, 217.35],
                    [60.364, 212.225],
                    [64.92, 215.452],
                    [61.693, 220.008],
                    [0, 225.133],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.709, -1.139],
                    [1.139, -1.709],
                    [0, 0],
                    [1.899, 1.328],
                    [-1.139, 1.708],
                  ],
                  o: [
                    [1.329, -1.899],
                    [1.898, 1.329],
                    [0, 0],
                    [-1.139, 1.708],
                    [-1.708, -1.139],
                    [0, 0],
                  ],
                  v: [
                    [-56.568, 58.846],
                    [-51.063, 57.706],
                    [-49.924, 63.212],
                    [-107.821, 147.874],
                    [-113.326, 148.823],
                    [-114.275, 143.508],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 8,
              ty: 'sh',
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.569, -2.088],
                    [2.088, 0.57],
                    [0, 0],
                    [-0.57, 2.088],
                    [-2.088, -0.57],
                  ],
                  o: [
                    [2.088, 0.57],
                    [-0.57, 2.088],
                    [0, 0],
                    [-2.088, -0.569],
                    [0.569, -2.088],
                    [0, 0],
                  ],
                  v: [
                    [-86.181, -44.039],
                    [-83.333, -39.293],
                    [-88.269, -36.447],
                    [-183.941, -62.642],
                    [-186.788, -67.577],
                    [-181.853, -70.234],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 9',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 9,
              ty: 'sh',
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.986, 21.64],
                    [-2.088, 0.38],
                    [-0.38, -2.088],
                    [-7.213, -20.501],
                    [-10.061, -19.743],
                    [1.899, -0.948],
                    [0.95, 1.898],
                    [7.403, 20.691],
                  ],
                  o: [
                    [-0.38, -2.089],
                    [2.278, -0.379],
                    [3.796, 21.26],
                    [7.403, 20.311],
                    [0.949, 1.898],
                    [-2.088, 0.949],
                    [-10.25, -20.121],
                    [-7.403, -20.881],
                  ],
                  v: [
                    [-239.939, -5.504],
                    [-236.902, -10.06],
                    [-232.156, -7.023],
                    [-215.262, 55.43],
                    [-189.066, 115.604],
                    [-190.775, 120.918],
                    [-196.09, 119.211],
                    [-222.665, 58.086],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 10',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.760999971278, 0.764999988032, 0.764999988032, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 241.838], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 12,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-138.193, 0],
                    [0, -138.193],
                    [138.004, 0],
                    [0, 138.003],
                  ],
                  o: [
                    [138.004, 0],
                    [0, 138.003],
                    [-138.193, 0],
                    [0, -138.193],
                  ],
                  v: [
                    [0, -250],
                    [250, 0],
                    [0, 250],
                    [-250, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.917999985639, 0.922000002394, 0.922000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 250.001], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 200,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'basketball Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 81.818,
              s: [100],
            },
            { t: 103.09375, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 16.363,
              s: [-360],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 36.816,
              s: [0],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 65.455,
              s: [0],
            },
            { t: 114.544921875, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [250, 250, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-3.607, 1.898],
                    [-54.475, -94.145],
                    [-7.782, -67.194],
                    [3.227, -2.657],
                    [39.86, 68.901],
                    [78.39, 37.202],
                  ],
                  o: [
                    [3.606, -2.088],
                    [78.201, 38.721],
                    [39.1, 67.952],
                    [-3.037, 2.656],
                    [-6.643, -67.762],
                    [-54.475, -94.714],
                    [0, 0],
                  ],
                  v: [
                    [-144.444, -202.905],
                    [-133.625, -208.789],
                    [74.215, -5.315],
                    [144.444, 201.008],
                    [135.143, 208.789],
                    [65.484, -0.19],
                    [-143.115, -202.336],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.301999978458, 0.298000021542, 0.258999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [273.484, 234.299], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-40.05, 60.169],
                    [-1.898, -3.226],
                    [74.215, -42.897],
                    [83.705, 2.278],
                    [1.328, 3.417],
                    [-89.21, 51.437],
                  ],
                  o: [
                    [1.898, 3.037],
                    [-40.808, 59.979],
                    [-89.21, 51.438],
                    [-1.518, -3.226],
                    [82.945, 3.417],
                    [74.404, -42.897],
                  ],
                  v: [
                    [217.52, -121.572],
                    [223.214, -112.271],
                    [48.591, 46.408],
                    [-218.849, 119.295],
                    [-223.214, 109.236],
                    [43.656, 37.678],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.301999978458, 0.298000021542, 0.258999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [241.786, 234.963], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.879, -34.355],
                    [3.986, 0],
                    [0.569, 39.859],
                    [71.558, 47.832],
                    [55.235, 12.907],
                    [-1.708, 3.036],
                    [-40.999, -27.522],
                    [-1.139, -77.632],
                  ],
                  o: [
                    [-3.986, 0.38],
                    [22.018, -34.355],
                    [-1.139, -74.215],
                    [-40.808, -27.332],
                    [1.708, -3.037],
                    [55.235, 13.286],
                    [74.405, 49.73],
                    [0.569, 39.669],
                  ],
                  v: [
                    [4.651, 241.341],
                    [-7.307, 241.91],
                    [26.289, 129.734],
                    [-91.203, -59.125],
                    [-236.596, -120.433],
                    [-231.661, -129.543],
                    [-85.698, -67.286],
                    [36.159, 129.545],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.569, 9.68],
                    [-3.416, -1.519],
                    [-1.137, -7.593],
                    [-66.623, -32.647],
                    [-9.491, -2.848],
                    [0, -3.417],
                    [10.629, 5.314],
                    [13.286, 88.261],
                  ],
                  o: [
                    [3.416, 1.329],
                    [0.569, 7.782],
                    [12.718, 85.224],
                    [9.299, 4.555],
                    [0.189, 3.416],
                    [-11.199, -3.227],
                    [-69.66, -34.166],
                    [-1.329, -9.491],
                  ],
                  v: [
                    [74.69, -241.91],
                    [84.939, -237.734],
                    [87.596, -214.767],
                    [208.126, -27.996],
                    [236.407, -16.797],
                    [236.596, -6.358],
                    [203.759, -19.075],
                    [77.727, -213.249],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.301999978458, 0.298000021542, 0.258999992819, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [263.328, 257.93], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-25.624, 1.139],
                    [-32.079, -55.424],
                    [-1.329, -28.092],
                    [50.868, -29.23],
                    [45.744, 79.149],
                    [-79.15, 45.744],
                  ],
                  o: [
                    [59.6, 2.658],
                    [14.995, 26.194],
                    [-2.469, 54.474],
                    [-79.15, 45.744],
                    [-45.554, -79.151],
                    [23.726, -13.666],
                  ],
                  v: [
                    [4.271, -177.186],
                    [153.081, -87.027],
                    [177.186, -4.46],
                    [94.43, 131.442],
                    [-131.632, 70.894],
                    [-70.893, -155.168],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.941000007181, 0.490000017952, 0.019999999626, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [305.087, 238.759], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-119.579, 69.09],
                    [-69.09, -119.579],
                    [119.579, -69.09],
                    [69.09, 119.579],
                  ],
                  o: [
                    [119.58, -68.901],
                    [68.901, 119.579],
                    [-119.58, 69.089],
                    [-69.091, -119.58],
                  ],
                  v: [
                    [-124.947, -216.518],
                    [216.518, -125.031],
                    [125.031, 216.435],
                    [-216.434, 124.947],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.847000002394, 0.435000011968, 0.008000000785, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 250], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 5',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 5,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 200,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'volleyball Outlines',
      sr: 1,
      ks: {
        o: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 147.273,
              s: [100],
            },
            { t: 167.7265625, s: [0] },
          ],
          ix: 11,
        },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 81.818,
              s: [-360],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 103.094,
              s: [0],
            },
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 130.91,
              s: [0],
            },
            { t: 180, s: [1800] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [250, 250, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-9.302, 22.779],
                    [-2.846, -3.037],
                    [46.318, -29.234],
                    [57.138, -15.376],
                    [-14.047, -37.395],
                    [-40.812, 178.626],
                    [-2.088, -6.835],
                    [46.318, -8.352],
                    [-18.793, -19.551],
                    [-67.198, 147.685],
                    [6.644, -15.756],
                    [41.571, -4.936],
                    [-9.681, -6.834],
                    [0, 0],
                    [4.555, 0.19],
                    [15.376, 36.636],
                    [-7.972, 51.631],
                    [50.494, 20.691],
                    [-58.277, -98.709],
                    [8.542, 11.199],
                    [-7.593, 26.766],
                    [12.149, -0.569],
                    [-1.518, 3.796],
                    [-11.2, -18.792],
                    [-44.799, 26.766],
                    [100.987, -3.606],
                    [-5.125, 3.607],
                    [-21.26, -26.956],
                    [-18.032, 20.691],
                    [95.862, 15.945],
                    [-11.769, 0],
                    [-14.807, -26.196],
                  ],
                  o: [
                    [3.228, 2.847],
                    [-24.297, 55.998],
                    [-48.026, 30.562],
                    [-6.833, 45.368],
                    [39.864, -6.834],
                    [2.657, 6.644],
                    [-51.823, 167.995],
                    [10.441, 23.539],
                    [22.399, -1.898],
                    [-3.416, 16.895],
                    [-74.601, 102.126],
                    [8.542, 7.593],
                    [0, 0],
                    [-4.366, 0],
                    [-33.979, -26.956],
                    [-17.843, -42.141],
                    [-5.884, -10.441],
                    [-8.352, 30.561],
                    [-10.251, -9.492],
                    [-36.447, -95.482],
                    [-10.82, -3.226],
                    [1.328, -4.176],
                    [76.88, -0.57],
                    [52.581, -14.046],
                    [-22.779, -28.853],
                    [4.556, -3.986],
                    [101.936, 6.645],
                    [22.779, -14.617],
                    [-12.528, -22.779],
                    [11.58, -1.709],
                    [76.499, 28.283],
                    [13.856, -17.654],
                  ],
                  v: [
                    [166.287, -182.707],
                    [175.398, -173.785],
                    [66.439, -49.07],
                    [-95.293, 16.23],
                    [-84.283, 141.135],
                    [228.36, -91.591],
                    [235.384, -71.469],
                    [-79.727, 152.524],
                    [-35.877, 217.254],
                    [241.078, 48.31],
                    [226.082, 97.475],
                    [-24.677, 228.075],
                    [2.657, 249.905],
                    [-4.366, 249.905],
                    [-17.653, 249.715],
                    [-91.876, 154.043],
                    [-106.872, 12.624],
                    [-195.52, -72.039],
                    [-174.829, 182.707],
                    [-202.923, 151.765],
                    [-206.72, -76.026],
                    [-241.078, -80.202],
                    [-236.713, -92.16],
                    [-97.76, 4.46],
                    [51.443, -53.816],
                    [-167.046, -189.731],
                    [-152.43, -201.311],
                    [61.693, -60.08],
                    [123.196, -112.662],
                    [-42.711, -247.058],
                    [-7.593, -249.905],
                    [131.17, -122.343],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.760999971278, 0.764999988032, 0.764999988032, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [254.366, 250.095], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-103.834, 0],
                    [0, -103.835],
                    [103.835, 0],
                    [0, 104.025],
                  ],
                  o: [
                    [103.835, 0],
                    [0, 104.025],
                    [-103.834, 0],
                    [0, -103.835],
                  ],
                  v: [
                    [0, -188.117],
                    [188.117, 0],
                    [0, 188.117],
                    [-188.117, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.995999983245, 0.995999983245, 0.995999983245, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [282.27, 216.591], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-138.003, 0],
                    [0, -138.003],
                    [138.193, 0],
                    [0, 138.193],
                  ],
                  o: [
                    [138.193, 0],
                    [0, 138.193],
                    [-138.003, 0],
                    [0, -138.003],
                  ],
                  v: [
                    [0, -250],
                    [250, 0],
                    [0, 250],
                    [-250, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.917999985639, 0.922000002394, 0.922000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 250], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 200,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'football Outlines',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.2], y: [1] },
              o: { x: [0.8], y: [0] },
              t: 147.273,
              s: [0],
            },
            { t: 167.728515625, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [250, 250, 0], ix: 2 },
        a: { a: 0, k: [250, 250.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-35.307, 18.034],
                    [-26.765, -27.715],
                    [12.149, -37.585],
                    [39.483, 3.986],
                    [12.338, 37.585],
                  ],
                  o: [
                    [36.447, 18.983],
                    [-12.339, 37.585],
                    [-39.483, 4.177],
                    [-12.149, -37.585],
                    [28.853, -27.905],
                  ],
                  v: [
                    [0.095, -93.11],
                    [95.766, -23.633],
                    [59.131, 88.933],
                    [-59.13, 88.933],
                    [-95.766, -23.633],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.4, 0.4, 0.4, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.905, 222.761], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-29.613, 36.636],
                    [0, 0],
                    [-9.112, -26.956],
                    [12.528, -28.284],
                  ],
                  o: [
                    [5.125, -49.355],
                    [0, 0],
                    [-10.63, 32.461],
                    [-28.284, 15.186],
                    [0, 0],
                  ],
                  v: [
                    [-248.577, -15.186],
                    [-194.286, -146.545],
                    [-171.508, -150.532],
                    [-174.165, -61.313],
                    [-234.529, 4.556],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [25.247, 33.788],
                    [0, 0],
                    [-30.562, 1.897],
                    [-26.007, -20.691],
                  ],
                  o: [
                    [-39.104, -17.654],
                    [0, 0],
                    [30.752, 20.122],
                    [7.973, 33.409],
                    [0, 0],
                  ],
                  v: [
                    [-102.6, 238.99],
                    [-200.551, 160.213],
                    [-196.754, 112.187],
                    [-104.879, 139.712],
                    [-53.246, 220.387],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [38.913, -17.654],
                    [0, 0],
                    [-7.972, 33.409],
                    [-30.752, 20.122],
                  ],
                  o: [
                    [-25.247, 33.788],
                    [0, 0],
                    [25.816, -20.691],
                    [30.562, 1.897],
                    [0, 0],
                  ],
                  v: [
                    [200.551, 160.213],
                    [102.792, 238.99],
                    [53.436, 220.387],
                    [104.878, 139.712],
                    [196.754, 112.187],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-5.125, -49.355],
                    [0, 0],
                    [28.095, 15.186],
                    [10.82, 32.461],
                  ],
                  o: [
                    [29.613, 36.636],
                    [0, 0],
                    [-12.528, -28.284],
                    [9.111, -26.956],
                    [0, 0],
                  ],
                  v: [
                    [194.286, -146.545],
                    [248.577, -15.186],
                    [234.529, 4.556],
                    [174.354, -61.313],
                    [171.508, -150.532],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-23.917, 0],
                    [-21.64, -6.264],
                    [0, 0],
                    [19.932, -17.274],
                    [28.664, 0.949],
                    [0, 0],
                  ],
                  o: [
                    [23.729, 0],
                    [0, 0],
                    [-31.7, 2.278],
                    [-22.968, -18.983],
                    [0, 0],
                    [21.64, -6.264],
                  ],
                  v: [
                    [0.095, -238.99],
                    [68.432, -229.499],
                    [77.733, -215.072],
                    [0.095, -186.029],
                    [-77.734, -215.072],
                    [-68.432, -229.499],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0.4, 0.4, 0.4, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [249.905, 238.991], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 2',
          np: 7,
          cix: 2,
          bm: 0,
          ix: 2,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [14.617, -14.617],
                    [1.518, 1.519],
                    [-1.519, 1.518],
                    [-17.274, 11.77],
                    [-19.362, 8.732],
                    [-0.949, -1.898],
                    [2.088, -0.949],
                    [16.894, -11.58],
                  ],
                  o: [
                    [-1.519, 1.519],
                    [-1.519, -1.708],
                    [14.996, -14.806],
                    [17.085, -11.769],
                    [2.088, -0.949],
                    [0.76, 2.088],
                    [-18.982, 8.353],
                    [-16.705, 11.579],
                  ],
                  v: [
                    [-172.551, -147.874],
                    [-178.056, -147.874],
                    [-178.056, -153.568],
                    [-129.841, -193.432],
                    [-75.171, -224.184],
                    [-69.856, -222.285],
                    [-71.944, -216.97],
                    [-125.474, -186.977],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 1,
              ty: 'sh',
              ix: 2,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-2.278, 0],
                    [0, -2.277],
                    [0, 0],
                    [2.089, 0],
                    [0, 2.088],
                  ],
                  o: [
                    [0, -2.277],
                    [2.089, 0],
                    [0, 0],
                    [0, 2.088],
                    [-2.278, 0],
                    [0, 0],
                  ],
                  v: [
                    [-3.986, -202.164],
                    [0, -206.15],
                    [3.797, -202.164],
                    [3.797, -98.898],
                    [0, -94.913],
                    [-3.986, -98.898],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 2',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 2,
              ty: 'sh',
              ix: 3,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-14.996, -14.806],
                    [1.519, -1.708],
                    [1.519, 1.519],
                    [16.894, 11.579],
                    [18.982, 8.353],
                    [-0.949, 2.088],
                    [-1.897, -0.949],
                    [-17.274, -11.769],
                  ],
                  o: [
                    [1.519, 1.518],
                    [-1.518, 1.519],
                    [-14.616, -14.617],
                    [-16.704, -11.58],
                    [-1.899, -0.949],
                    [0.759, -1.898],
                    [19.362, 8.732],
                    [17.084, 11.77],
                  ],
                  v: [
                    [176.727, -153.568],
                    [176.727, -147.874],
                    [171.033, -147.874],
                    [123.956, -186.977],
                    [70.426, -216.97],
                    [68.527, -222.285],
                    [73.652, -224.184],
                    [128.512, -193.432],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 3',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 3,
              ty: 'sh',
              ix: 4,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.57, 2.088],
                    [-2.089, 0.57],
                    [0, 0],
                    [-0.569, -2.088],
                    [2.278, -0.569],
                  ],
                  o: [
                    [-2.086, 0.57],
                    [-0.569, -2.088],
                    [0, 0],
                    [2.088, -0.57],
                    [0.57, 2.088],
                    [0, 0],
                  ],
                  v: [
                    [87.888, -36.447],
                    [83.143, -39.293],
                    [85.802, -44.039],
                    [181.663, -70.234],
                    [186.408, -67.577],
                    [183.561, -62.642],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 4',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 4,
              ty: 'sh',
              ix: 5,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-3.986, 21.26],
                    [-2.088, -0.379],
                    [0.38, -2.089],
                    [7.404, -20.881],
                    [10.06, -20.121],
                    [1.899, 0.949],
                    [-0.949, 1.898],
                    [-7.214, 20.311],
                  ],
                  o: [
                    [0.38, -2.088],
                    [2.088, 0.38],
                    [-4.176, 21.64],
                    [-7.214, 20.691],
                    [-0.95, 1.898],
                    [-1.897, -0.948],
                    [9.871, -19.743],
                    [7.403, -20.501],
                  ],
                  v: [
                    [232.156, -7.023],
                    [236.713, -10.06],
                    [239.939, -5.504],
                    [222.475, 58.086],
                    [196.09, 119.211],
                    [190.774, 120.918],
                    [189.066, 115.604],
                    [215.072, 55.43],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 5',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 5,
              ty: 'sh',
              ix: 6,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.898, 1.329],
                    [-1.329, -1.899],
                    [0, 0],
                    [1.709, -1.139],
                    [1.139, 1.708],
                  ],
                  o: [
                    [-1.139, -1.709],
                    [1.709, -1.139],
                    [0, 0],
                    [1.329, 1.708],
                    [-1.898, 1.328],
                    [0, 0],
                  ],
                  v: [
                    [49.734, 63.212],
                    [50.873, 57.706],
                    [56.379, 58.846],
                    [114.085, 143.508],
                    [113.136, 148.823],
                    [107.631, 147.874],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 6',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 6,
              ty: 'sh',
              ix: 7,
              ks: {
                a: 0,
                k: {
                  i: [
                    [20.502, 3.417],
                    [-0.38, 2.087],
                    [-2.088, -0.38],
                    [-20.121, 0],
                    [-20.311, 3.417],
                    [-0.379, -2.278],
                    [2.089, -0.379],
                    [20.501, 0],
                  ],
                  o: [
                    [-2.277, -0.379],
                    [0.189, -2.278],
                    [20.312, 3.417],
                    [19.931, 0],
                    [2.089, -0.38],
                    [0.381, 2.087],
                    [-20.69, 3.417],
                    [-20.691, 0],
                  ],
                  v: [
                    [-61.694, 220.008],
                    [-64.92, 215.452],
                    [-60.554, 212.225],
                    [0, 217.35],
                    [60.364, 212.225],
                    [64.92, 215.452],
                    [61.693, 220.008],
                    [0, 225.133],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 7',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 7,
              ty: 'sh',
              ix: 8,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-1.709, -1.139],
                    [1.139, -1.709],
                    [0, 0],
                    [1.899, 1.328],
                    [-1.139, 1.708],
                  ],
                  o: [
                    [1.329, -1.899],
                    [1.898, 1.329],
                    [0, 0],
                    [-1.139, 1.708],
                    [-1.708, -1.139],
                    [0, 0],
                  ],
                  v: [
                    [-56.568, 58.846],
                    [-51.063, 57.706],
                    [-49.924, 63.212],
                    [-107.821, 147.874],
                    [-113.326, 148.823],
                    [-114.275, 143.508],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 8',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 8,
              ty: 'sh',
              ix: 9,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0.569, -2.088],
                    [2.088, 0.57],
                    [0, 0],
                    [-0.57, 2.088],
                    [-2.088, -0.57],
                  ],
                  o: [
                    [2.088, 0.57],
                    [-0.57, 2.088],
                    [0, 0],
                    [-2.088, -0.569],
                    [0.569, -2.088],
                    [0, 0],
                  ],
                  v: [
                    [-86.181, -44.039],
                    [-83.333, -39.293],
                    [-88.269, -36.447],
                    [-183.941, -62.642],
                    [-186.788, -67.577],
                    [-181.853, -70.234],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 9',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ind: 9,
              ty: 'sh',
              ix: 10,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.986, 21.64],
                    [-2.088, 0.38],
                    [-0.38, -2.088],
                    [-7.213, -20.501],
                    [-10.061, -19.743],
                    [1.899, -0.948],
                    [0.95, 1.898],
                    [7.403, 20.691],
                  ],
                  o: [
                    [-0.38, -2.089],
                    [2.278, -0.379],
                    [3.796, 21.26],
                    [7.403, 20.311],
                    [0.949, 1.898],
                    [-2.088, 0.949],
                    [-10.25, -20.121],
                    [-7.403, -20.881],
                  ],
                  v: [
                    [-239.939, -5.504],
                    [-236.902, -10.06],
                    [-232.156, -7.023],
                    [-215.262, 55.43],
                    [-189.066, 115.604],
                    [-190.775, 120.918],
                    [-196.09, 119.211],
                    [-222.665, 58.086],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 10',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'mm',
              mm: 1,
              nm: 'Merge Paths 1',
              mn: 'ADBE Vector Filter - Merge',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.760999971278, 0.764999988032, 0.764999988032, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 241.838], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 3',
          np: 12,
          cix: 2,
          bm: 0,
          ix: 3,
          mn: 'ADBE Vector Group',
          hd: false,
        },
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-138.193, 0],
                    [0, -138.193],
                    [138.004, 0],
                    [0, 138.003],
                  ],
                  o: [
                    [138.004, 0],
                    [0, 138.003],
                    [-138.193, 0],
                    [0, -138.193],
                  ],
                  v: [
                    [0, -250],
                    [250, 0],
                    [0, 250],
                    [-250, 0],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.917999985639, 0.922000002394, 0.922000002394, 1],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [250, 250.001], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Group 4',
          np: 4,
          cix: 2,
          bm: 0,
          ix: 4,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 137,
      op: 200,
      st: 249,
      bm: 0,
    },
  ],
  markers: [],
};
// num = 0 -> 로딩 아이콘만
// num = 1 -> 세션 등록 로딩
type LoadingType = {
  num: 0 | 1 | 2;
};
const Loading = ({ num }: LoadingType) => {
  useEffect(() => {
    // 컴포넌트가 마운트될 때 body의 스크롤을 비활성화
    document.body.style.overflow = 'hidden';

    // 컴포넌트가 언마운트될 때 스크롤을 다시 활성화
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);
  return (
    <Background>
      <Container>
        <LottieContainer>
          <Lottie animationData={animationData} />
        </LottieContainer>
        <Margin margin='21px' />
        <Span fontSize='20px' fontWeight='700' margin='0 0 -10px 0'>
          {num === 0 && <>센터를 등록하고 있습니다.</>}
          {num === 1 && <>세션을 등록하고 있습니다.</>}
          {num === 2 && <></>}
        </Span>
        {(num === 0 || num === 1) && (
          <>
            <Margin margin='16px' />

            <Span fontSize='20px' fontWeight='500' lineHeight='28px'>
              뒤로 이동하거나 브라우저를 끄면
            </Span>
            <Span fontSize='20px' fontWeight='500' lineHeight='28px'>
              등록 작업이 취소되며,
            </Span>
            <Span fontSize='20px' fontWeight='500' lineHeight='28px'>
              작성하신 내용을 저장하지 못할 수 있습니다.
            </Span>
          </>
        )}
      </Container>
    </Background>
  );
};
export default Loading;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: var(--c200);
  font-weight: 600;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;
const Container = styled.div`
  width: 500px;
  height: 360px;
  background-color: #2c2d34;
  color: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  padding: 63px 73px;
`;
const LottieContainer = styled.div`
  width: 61px;
  height: 61px;
  flex-shrink: 0;
`;
