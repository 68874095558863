import DetailSessionForm from 'components/DetailSessionForm';
import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { SessionType } from 'constants/type';
import { GET_ALERT_TEXT } from 'constants/alertText';
import { postSession } from 'apis/session';

const DetailSessionPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const sessionId = searchParams.get('sessionId');
  const { center } = useCenterStore();
  const [session, setSession] = useState<SessionType>();
  useEffect(() => {
    getSessionData();
  }, []);
  const getSessionData = async () => {
    if (!sessionId) {
      return;
    }
    const response = await postSession({
      params: {
        sessionId,
      },
      data: {
        centerId: center.centerId,
      },
    });
    const result = response;
    if (!result) return;

    const { sessionView } = result;
    if (sessionView) {
      setSession(sessionView);
    } else {
      alert(GET_ALERT_TEXT);
      navigate(-1);
    }
  };
  return (
    <>
      <Container>
        {session && <DetailSessionForm session={session} />}
      </Container>
    </>
  );
};
export default DetailSessionPage;

export const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: 3px;
`;
