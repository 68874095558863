import { useState, useEffect, useRef } from 'react';
import { Flex, Span } from 'styles/style';
import { HorizontalBox, VerticalBox } from '../Scene/style';
import { getMMSSFormat, getQuality } from 'constants/video';
import { AddSceneText, TimeInput } from 'components/Scene/style';
import { AddImage } from 'styles/FormStyle';
import {
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
} from '../MainVideoInput/style';
import SkipToggleButton from 'components/SkipToggleButton';
import VideoSnapshot from 'components/VideoSnapshot';
import { SceneType } from 'constants/type';
import { debounce } from 'constants/common';
import useVideoControl from 'hooks/useVideoControl';
import useVideoMetadata from 'hooks/useVideoMetadata';
import Img from 'components/Img';

type FeedbackVideoInputType = {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  ratio: string;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  feedbackScene: SceneType;
  duration: number;
  isEdit: boolean;
};
const FeedbackVideoInputDetail = ({
  setIsLoading,
  ratio,
  sceneList,
  setSceneList,
  feedbackScene,
  duration: mainVideoDuration,
  isEdit,
}: FeedbackVideoInputType) => {
  //씬 데이터
  const [time, setTime] = useState({
    minute: 0,
    second: 0,
  });
  const [thumbnail, setThumbnail] = useState<Blob>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [canSkipStep, setCanSkipStep] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const [isVideoCanPlay, setIsVideoCanPlay] = useState(false);
  const [quality, setQuality] = useState<string>('');

  //video hook
  const { videoRef, isVideoPlaying, play, pause, currentBarHandler } =
    useVideoControl();
  const { currentTime } = useVideoMetadata({
    videoRef,
    video: feedbackScene.additionalVideo?.streamingUrl,
    setIsVideoCanPlay,
  });
  useEffect(() => {
    setTime({
      minute: Math.floor(feedbackScene.sceneStartAt / 1000 / 60),
      second: (feedbackScene.sceneStartAt / 1000) % 60,
    });
    setThumbnailUrl(feedbackScene.thumbnailUrl);
    const metadata = feedbackScene.additionalVideo?.metadata;
    if (metadata) {
      setQuality(getQuality(metadata.originalWidth, metadata.originalHeight));
    }
  }, []);
  useEffect(() => {
    if (!thumbnailUrl) {
      return;
    }
    const { minute, second } = time;
    const newScene: SceneType = {
      sceneId: feedbackScene.sceneId,
      sceneType: 'coaching',
      sceneStartAt: (minute * 60 + second) * 1000,
      sceneEndAt: (minute * 60 + second) * 1000 + 300,
      title: '따라하기',
      thumbnailUrl,
      thumbnailFile: thumbnail,
      additionalMent: '지금 바로\n연습하고\n피드백을 받아보세요!',
      additionalStreamingDuration: feedbackScene.additionalVideo?.duration || 0,
      canSkipStep,
      thumbnailUrlChange: false,
      metadata: feedbackScene.additionalVideo?.metadata || {},
    };
    setSceneList((prev) => {
      // sceneType이 'coaching'인 씬이 있는지 확인
      const hasCoaching = prev.some((scene) => scene.sceneType === 'coaching');

      if (hasCoaching) {
        // 있으면 교체
        return prev.map((scene) =>
          scene.sceneType === 'coaching' ? newScene : scene
        );
      } else {
        // 없으면 추가
        return [...prev, newScene];
      }
    });
  }, [time, thumbnail, canSkipStep]);
  useEffect(() => {
    const timer = setTimeout(() => {
      debouncedCheckTime();
    }, 700);

    return () => {
      clearTimeout(timer);
    };
  }, [time]);
  const debouncedCheckTime = debounce(() => {
    isEdit && checkTime();
  });
  const setCanSkipStep_forFunc = (index: number, value: boolean) => {
    setCanSkipStep(value);
  };
  const checkTime = () => {
    const startAt = time.minute * 60 + time.second;
    const zeroTime = { minute: 0, second: 0 };
    if (startAt > mainVideoDuration) {
      alert('메인비디오의 영상 범위를 벗어납니다. 다시 입력해주세요.');
      setStatus(false);
      setTime(zeroTime);
      return false;
    }
    if (
      time.second !== 0 &&
      sceneList.find((scene) => {
        const startAtMS = startAt * 1000;
        if (
          startAtMS >= scene.sceneStartAt &&
          startAtMS < scene.sceneEndAt &&
          scene.sceneType !== 'coaching'
        ) {
          return true;
        }
      })
    ) {
      alert('다른 씬과 시간이 겹칩니다. 다시 입력해주세요.');
      setStatus(false);
      setTime(zeroTime);
      return false;
    }
    setStatus(true); //유효성 검사 완료
    return true;
  };
  return (
    <>
      {feedbackScene && (
        <>
          <video
            src={feedbackScene.additionalVideo?.streamingUrl}
            ref={videoRef}
            style={{ display: 'none' }}
          />
          <Flex margin='20px 0 10px 0'>
            <label htmlFor='feedbackVideo'>
              <AddSceneText>피드백</AddSceneText>
            </label>
          </Flex>
          {feedbackScene.additionalVideo ? (
            <Flex flexDirection='row' gap='15px'>
              {ratio === 'landscape' ? (
                <HorizontalBox>
                  <Img src={feedbackScene.thumbnailUrl} alt='썸네일 이미지' />
                </HorizontalBox>
              ) : (
                <VerticalBox>
                  <Img src={feedbackScene.thumbnailUrl} alt='썸네일 이미지' />
                </VerticalBox>
              )}
              <Flex alignItems='center' gap='20px' padding='10px 0' flex={1}>
                <MetaDataContainer>
                  <MetaDataColumn>
                    <MetaDataTitle>시작 시간</MetaDataTitle>
                    <MetaDataValue>
                      <TimeInput
                        value={time.minute.toString().padStart(2, '0')}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (/^\d+$/.test(val) || val === '') {
                            setTime((prev) => ({
                              ...prev,
                              minute: Number(val),
                            }));
                          }
                        }}
                        placeholder='00'
                        readOnly={!isEdit}
                      />
                      분{' '}
                      <TimeInput
                        value={time.second.toString().padStart(2, '0')}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (/^\d+$/.test(val) || val === '') {
                            setTime((prev) => ({
                              ...prev,
                              second: Number(val),
                            }));
                          }
                        }}
                        placeholder='00'
                        readOnly={!isEdit}
                      />
                      초
                    </MetaDataValue>
                  </MetaDataColumn>
                  <MetaDataColumn>
                    <SkipToggleButton
                      id='feedback'
                      index={0}
                      canSkipStep={
                        isEdit ? canSkipStep : feedbackScene.canSkipStep
                      }
                      setCanSkipStep={setCanSkipStep_forFunc}
                    />
                  </MetaDataColumn>
                </MetaDataContainer>
                <MetaDataContainer>
                  <MetaDataColumn>
                    <MetaDataTitle>영상길이</MetaDataTitle>
                    <MetaDataValue>
                      {getMMSSFormat(feedbackScene.additionalVideo.duration)}
                    </MetaDataValue>
                  </MetaDataColumn>
                  <MetaDataColumn>
                    <MetaDataTitle>비율</MetaDataTitle>
                    <MetaDataValue>
                      {ratio === 'landscape'
                        ? '가로형 (16:9)'
                        : '세로형 (9:16)'}
                      `
                    </MetaDataValue>
                  </MetaDataColumn>
                  <MetaDataColumn>
                    <MetaDataTitle>화질</MetaDataTitle>
                    <MetaDataValue>{quality}</MetaDataValue>
                  </MetaDataColumn>
                </MetaDataContainer>
                {/* {isEdit && (
                  <Flex flex={1} justifyContents='right'>
                    <label htmlFor='feedbackVideo'>
                      <VideoChangeButton>영상 변경하기</VideoChangeButton>
                    </label>
                  </Flex>
                )} */}
              </Flex>
            </Flex>
          ) : (
            <label htmlFor='feedbackVideo'>
              <AddImage>
                <Span>+</Span>
                <Span>영상 추가</Span>
              </AddImage>
            </label>
          )}
        </>
      )}
    </>
  );
};
export default FeedbackVideoInputDetail;
