import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, RelativeDiv, Span } from '../../styles/style';
import {
  Form,
  Title,
  InputContainer,
  SubmitButton,
  Input,
  InputName,
  Red,
  GrayButton,
  Hr,
  Error,
  CheckBoxInput,
} from '../../styles/LoginStyles';
import { Container } from './style';
import Icon from '../Icon';
import { TimerSpan } from 'styles/FormStyle';
import { TermsWrapper } from 'components/EditCenterFormAfter/style';
import Terms from 'components/Terms';
type TermType = {
  name: string;
  required: boolean;
  termId: number;
  url: string;
};
function SignUpForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    watch,
    trigger,
    control,
    formState: { isDirty, dirtyFields, errors },
  } = useForm();
  const expireTime = 180;
  const [emailRegToken, setEmailRegToken] = useState('');
  const [emailChecked, setEmailChecked] = useState('');
  const [isReSendActive, setIsResendActive] = useState(false);
  const [isAuthNumCorrect, setIsAuthNumCorrect] = useState<boolean>(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(expireTime);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [resultTerms, setResultTerms] = useState<{
    [key: string]: boolean;
  }>({});

  const email = watch('email');
  const password = watch('password');
  const phoneNumber = watch('phoneNumber');
  const authNumber = watch('authNumber');
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
  const phoneNumberRegex = /^(01([016789]))(\d{4})(\d{4})$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,40}$/;

  useEffect(() => {
    dirtyFields['email'] && trigger('email');
    dirtyFields['password'] && trigger('password');
    dirtyFields['authNumber'] && trigger('authNumber');
  }, [email, password, emailChecked, isAuthNumCorrect]);

  const isValidEmail = (email: string) => {
    return emailRegex.test(email);
  };
  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneNumberRegex.test(phoneNumber);
  };
  const emailDupCheck = async () => {
    trigger('email');
    if (!email || !isValidEmail(email)) {
      return;
    }
    const response = await fetch(`${API_URL}/api/mem-join/duplication-pwd`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      const result = await response.json();
      setEmailChecked(email);
      alert('사용 가능한 이메일입니다.');
      trigger('email');
    } else {
      alert('중복되는 이메일입니다');
    }
  };
  const sendAuthNumber = async () => {
    setIsSending(true);
    trigger('phoneNumber');
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      setIsSending(false);
      return;
    }

    const response = await fetch(`${API_URL}/api/mem-join/authnum-send`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phoneNumber }),
    });

    if (response.ok) {
      const { emailRegToken } = await response.json();
      setEmailRegToken(emailRegToken);
      setIsResendActive(true);
      resetTimer();
      startTimer();
    } else {
      alert('이미 존재하는 전화번호입니다.');
      //error
    }
    setIsSending(false);
  };
  const checkAuthNumber = async () => {
    if (authNumber === '') {
      return;
    }
    const response = await fetch(`${API_URL}/api/mem-join/authnum-check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        emailRegToken,
        authNumber,
      }),
    });

    if (response.ok) {
      alert('전화번호 인증이 완료되었습니다'); //알바몬
      setIsAuthNumCorrect(true);
      resetTimer();
    } else {
      setIsAuthNumCorrect(false);
      alert('인증번호가 일치하지 않습니다.');
      trigger('authNumber');
    }
  };

  //타이머
  const startTimer = () => {
    const id = setInterval(decreaseCounter, 1000);
    setIntervalId(id);
    setTimer(expireTime);
  };

  const decreaseCounter = () => {
    setTimer((prev) => {
      const newTimerValue = prev - 1;

      if (newTimerValue <= 0) {
        alert(
          '입력 시간이 초과되었습니다. 다시 재전송 버튼을 클릭하여 발급된 인증번호를 입력해주세요'
        );
        resetTimer();
        setIsResendActive(true);
      }

      return newTimerValue;
    });
  };

  const resetTimer = () => {
    clearInterval(intervalId!);
    setIntervalId(null);
  };
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);
  //

  const signUp = async () => {
    const response = await fetch(`${API_URL}/api/mem-join/join-complete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        phoneNumber,
        emailRegToken,
        term_permissions: resultTerms,
      }),
    });

    if (response.ok) {
      const { accessToken, refreshToken } = await response.json();
      alert('회원가입에 성공하였습니다');

      navigate('/login');
    } else {
      //error
    }
  };
  return (
    <Container>
      <Form
        onSubmit={handleSubmit((data: any) => {
          signUp();
        })}
      >
        <Flex gap='13px'>
          <Flex margin='0 0 40px 0'>
            <Icon icon='LogoText' width={130} />
          </Flex>
          <Title>회원가입</Title>
        </Flex>
        <InputContainer>
          <InputName>
            이메일 <Red>*</Red>
          </InputName>
          <Flex flexDirection='row' gap='7px'>
            <Input
              id='email'
              placeholder='이메일을 입력하세요'
              aria-invalid={isDirty ? (errors.email ? true : false) : undefined}
              {...register('email', {
                required: '이메일을 입력하세요.',
                pattern: {
                  value: emailRegex,
                  message: '이메일 형식을 확인하세요.',
                },
                validate: {
                  isVerified: () =>
                    emailChecked !== email
                      ? '이메일 중복 검사를 완료해주세요.'
                      : true,
                },
              })}
              autoComplete='new-password'
            />
            <GrayButton type='button' onClick={() => emailDupCheck()}>
              중복확인
            </GrayButton>
          </Flex>
          {errors.email && (
            <Error role='alert'>{errors.email.message?.toString()}</Error>
          )}
        </InputContainer>
        <InputContainer>
          <InputName>
            비밀번호 <Red>*</Red>
          </InputName>
          <Flex flexDirection='row'>
            <Span fontSize='13px' color='var(--c400)' margin='-2px 0 0 0'>
              영어, 숫자, 특수문자 포함 10글자 이상 40자 이내
            </Span>
          </Flex>
          <Input
            id='password'
            type='password'
            placeholder='비밀번호를 입력하세요'
            aria-invalid={
              isDirty ? (errors.password ? true : false) : undefined
            }
            {...register('password', {
              required: '비밀번호를 입력하세요',
              pattern: {
                value: passwordRegex,
                message:
                  '비밀번호는 영어, 숫자, 특수문자를 포함하여 10글자 이상 40자 이내여야 합니다.',
              },
            })}
            autoComplete='new-password'
          />
          {errors.password && (
            <Error role='alert'>{errors.password.message?.toString()}</Error>
          )}
        </InputContainer>
        <InputContainer>
          <InputName>
            전화번호 <Red>*</Red>
          </InputName>
          <Flex flexDirection='row'>
            <Span fontSize='13px' color='var(--c400)' margin='-2px 0 0 0'>
              문자를 제외한 숫자만 입력하세요
            </Span>
          </Flex>
          <Flex flexDirection='row' gap='7px' margin='0 0 3px 0'>
            <RelativeDiv>
              <Input
                id='phoneNumber'
                placeholder='전화번호를 입력하세요'
                disabled={isAuthNumCorrect === true}
                aria-invalid={
                  isDirty ? (errors.phoneNumber ? true : false) : undefined
                }
                {...register('phoneNumber', {
                  required: '전화번호를 입력해주세요',
                  pattern: {
                    value: phoneNumberRegex,
                    message: '유효한 전화번호를 입력해주세요.',
                  },
                })}
              />
              {!isAuthNumCorrect && (
                <TimerSpan>
                  {Math.floor(timer / 60)}:
                  {(timer % 60).toString().padStart(2, '0')}
                </TimerSpan>
              )}
            </RelativeDiv>
            <GrayButton
              type='button'
              onClick={() => {
                sendAuthNumber();
              }}
              disabled={isAuthNumCorrect === true || isSending}
            >
              {isReSendActive ? '재전송' : '인증번호 발송'}
            </GrayButton>
          </Flex>
          <Flex flexDirection='row' gap='7px' margin='-4px 0 0 0'>
            <Input
              id='authNumber'
              placeholder='인증번호를 입력하세요'
              disabled={isAuthNumCorrect === true}
              aria-invalid={
                isDirty ? (errors.authNumber ? true : false) : undefined
              }
              {...register('authNumber', {
                required: '인증번호를 입력하세요',
                validate: {
                  isVerified: () =>
                    isAuthNumCorrect !== true
                      ? '인증번호를 정확하게 입력해주세요.'
                      : true,
                },
              })}
            />
            <GrayButton
              type='button'
              onClick={() => checkAuthNumber()}
              disabled={isAuthNumCorrect === true}
            >
              확인
            </GrayButton>
          </Flex>
          {errors.phoneNumber ? (
            <Error>{errors.phoneNumber.message?.toString()}</Error>
          ) : (
            errors.authNumber && (
              <Error>{errors.authNumber.message?.toString()}</Error>
            )
          )}
        </InputContainer>
        <InputContainer>
          <InputName>약관동의</InputName>

          <>
            <TermsWrapper>
              <Terms
                control={control}
                errors={errors}
                resultTerms={resultTerms}
                setResultTerms={setResultTerms}
              />
            </TermsWrapper>
          </>
        </InputContainer>
        <SubmitButton type='submit'>회원가입</SubmitButton>
      </Form>
    </Container>
  );
}
export default SignUpForm;
