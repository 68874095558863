import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span } from '../../styles/style';
import {
  Form,
  Title,
  LoginButton,
  Container,
  Input,
  Line,
  Error,
} from '../../styles/LoginStyles';
import {
  useAccessTokenStore,
  useCenterStore,
  useRefreshTokenStore,
  useAccountStore,
  useProfileStore,
} from 'store/auth';
import { getCenterData } from 'apis/center';
import styled from 'styled-components';
import Icon from 'components/Icon';

function LoginForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isDirty, errors },
  } = useForm();
  const [isLoginOK, setIsLoginOK] = useState(true);

  //auth
  const { setAccessToken } = useAccessTokenStore();
  const { setRefreshToken } = useRefreshTokenStore();
  const { center, setCenter } = useCenterStore();
  const { setAccount } = useAccountStore();
  const { profile, setProfile } = useProfileStore();

  const login = async (data: any) => {
    const { email, password } = data;
    try {
      const response = await fetch(`${API_URL}/api/center/auth-submit`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userCenterId: email.trim(),
          userCenterPwd: password,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        const { centerId, account, accessToken, refreshToken } = result;

        const accessToken2 =
          'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIyMDMxRjlCQjk4MDkzQzJENTFFQ0EyM0Q4MTE4REM4NDQ1NDU4QzJDMEJGQTExNDk0REVFMkZFQzE2OTU2OENCIiwiYXV0aCI6Im93bmVyIiwiZXhwIjoxNjk5OTI0MjQ0fQ.w2kGOgP4PHKkwqCpB9w-pMMhqdJlcTVR4xY_wzTvN7U';
        const refreshToken2 =
          'eyJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MDUwNzIyNDR9.9xoTBLmi1qrQoF7nZcHSePK9M_WIFC2IruqZ325nL3o';

        setAccessToken(accessToken);
        setRefreshToken(refreshToken);
        setAccount(account);
        getCenterData({ centerId, setCenter, setProfile });

        navigate('/');
      } else {
        setIsLoginOK(false);
        alert(
          '등록되지 않은 아이디거나, 아이디 또는 비밀번호가 일치하지 않습니다.'
        );
      }
    } catch (error) {
      console.error(
        'There has been a problem with your fetch operation: ',
        error
      );
    }
  };
  return (
    <Container>
      <Flex
        width='100%'
        maxWidth='360px'
        justifyContents='space-between'
        margin='0 0 50px 0'
      >
        <LogoWrapper>
          <Icon icon='LogoText' width={130} />
        </LogoWrapper>
      </Flex>
      <Form onSubmit={handleSubmit((data: any) => login(data))}>
        <Title>관리자 로그인</Title>

        <Input
          id='email'
          placeholder='이메일'
          aria-invalid={isDirty ? (errors.email ? 'true' : 'false') : undefined}
          {...register('email', {
            required: '이메일을 입력하세요.',
            pattern: {
              value: /^\s*[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}\s*$/i,
              message: '이메일 형식을 확인하세요.',
            },
          })}
        />
        <Flex flexDirection='row' margin='0 0 5px 0 ' />
        <Input
          id='password'
          type='password'
          placeholder='비밀번호'
          aria-invalid={
            isDirty ? (errors.password ? 'true' : 'false') : undefined
          }
          {...register('password', {
            required: '비밀번호를 입력하세요',
          })}
        />

        <Flex
          flexDirection='row'
          width='100%'
          margin='10px 0 '
          justifyContents='left'
        >
          {!isLoginOK ? (
            <Error>
              로그인에 실패하였습니다. 등록되지 않은 아이디거나, 아이디 또는
              비밀번호가 일치하지 않습니다.
            </Error>
          ) : errors.email ? (
            <Error role='alert'>{errors.email.message?.toString()}</Error>
          ) : (
            errors.password && (
              <Error role='alert'>{errors.password.message?.toString()}</Error>
            )
          )}
        </Flex>
        <LoginButton type='submit' disabled={isSubmitting}>
          로그인
        </LoginButton>
        <Flex
          width='100%'
          flexDirection='row'
          margin='15px 0 0 0'
          justifyContents='right'
        >
          <Span
            fontSize='12px'
            cursor='pointer'
            onClick={() => navigate('/pwdReset')}
          >
            비밀번호 재설정
          </Span>
        </Flex>
      </Form>
      <Line />
      <Flex flexDirection='row' gap='10px'>
        <Span fontSize='14px' fontWeight='600'>
          계정이 없으신가요?
        </Span>
        <Span
          // color="rgb(0 181 110)"
          color='var(--primary)'
          fontSize='14px'
          fontWeight='bold'
          cursor='pointer'
          onClick={() => navigate('/signup')}
        >
          RealFeed 가입하기
        </Span>
      </Flex>
    </Container>
  );
}
export default LoginForm;

const LogoWrapper = styled.div`
  width: 15vh;
`;
