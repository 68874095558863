import Analytics from 'components/Analytics';
import SessionList from 'components/SessionList';
import { Container, Flex } from 'styles/style';
import styled from 'styled-components';

const SessionPage = () => {
  return (
    <>
      <SessionContainer>
        <Flex flexDirection='column' width='100%' gap='50px'>
          {/* <Analytics /> */}
          <SessionList />
        </Flex>
      </SessionContainer>
    </>
  );
};
export default SessionPage;
const SessionContainer = styled(Container)`
  width: 100%;
  max-width: 1200px;
  min-width: 900px;
`;
