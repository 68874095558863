import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span } from '../../styles/style';
import {
  useAccessTokenStore,
  useAccountStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import DaumPostcodeEmbed from 'react-daum-postcode';
import {
  Form,
  InputName,
  Input,
  AddImage,
  UploadImageContainer,
  InputContainer,
  ImageWrapper,
  DeleteButton,
  SubmitButton,
  TextArea,
  IconWrapper,
  Error,
  ImageContainer,
  WhiteBorderButton,
} from 'styles/FormStyle';
import {
  Container,
  Title,
  AddressFindButton,
  PostcodeWrapper,
  TagImageContainer,
  TagContainer,
  TagName,
  TagImage,
} from '../ApplyCenterForm/style';
import Header from 'components/Header';
import Icon from '../Icon';
import { ImageType, TermType, SportsTagType } from 'constants/type';
import { allowedImageFormats, uploadS3 } from 'constants/common';
import { CaptionModal } from 'components/Modals/CaptionModal';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import { TermsWrapper } from 'components/EditCenterFormAfter/style';
import Terms from 'components/Terms';
import Image from 'components/Img';
import Img from 'components/Img';
import { getCenterData, postCancelCenter, postUpdateCenter } from 'apis/center';
import { postSportsTag } from 'apis/resource';

function EditCenterForm() {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    control,
    formState: { isSubmitting, isDirty, errors, dirtyFields },
  } = useForm();
  const [sportsTags, setSportsTags] = useState<SportsTagType[]>([]);
  const [selectedTag_init, setSelectedTag_init] = useState<string[]>([]);
  const [selectedTag, setSelectedTag] = useState<string[]>([]);
  const [profileFiles, setProfileFiles] = useState<File[]>([]);
  const [profileFiles_init, setProfileFiles_init] = useState<ImageType[]>([]);
  const [deleteBannerImages, setDeleteBannerImages] = useState<string[]>([]);
  const [businessRegImage_init, setBusinessRegImage_init] =
    useState<string>('');
  const [businessRegFile, setBusinessRegFile] = useState<File[]>([]);
  const [latitude, setLatitude] = useState<string>('');
  const [longitude, setLongitude] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [termPermissions, setTermPermissions] = useState<{
    [key: string]: boolean;
  }>({});
  const [captions, setCaptions] = useState<any[]>([]);

  //상태변경 useState
  const [profileFilestatus, setProfileFilestatus] = useState<string>('');
  const [tagStatus, setTagStatus] = useState<string>('');
  const [businessImageStatus, setBusinessImageStatus] = useState<string>('');
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isOpenPost, setIsOpenPost] = useState(false);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [captionModalShow, setCaptionModalShow] = useState(false);
  const [resultTerms, setResultTerms] = useState<{
    [key: string]: boolean;
  }>({});

  //auth
  const { accessToken } = useAccessTokenStore();
  const { center, setCenter } = useCenterStore();
  const { profile, setProfile } = useProfileStore();
  const { account } = useAccountStore();

  const script = document.createElement('script');
  script.src =
    '//dapi.kakao.com/v2/maps/sdk.js?appkey=84e1c459f31b318cab45a0a83e1d85ae&autoload=false&libraries=services';
  script.async = true;
  useEffect(() => {
    getCenterData({ centerId: center.centerId, setCenter, setProfile });
    setCenterData();
    getSportsTag();
    document.body.appendChild(script);
    script.addEventListener('load', () => {
      window.kakao.maps.load(function () {
        setIsApiLoading(true);
      });
    });
  }, []);
  useEffect(() => {
    getLocation();
  }, [address]);
  const setCenterData = async () => {
    setSelectedTag_init(center.sportsTagIds.map((v: SportsTagType) => v.tagId));
    setSelectedTag(center.sportsTagIds.map((v: SportsTagType) => v.tagId));
    setProfileFiles_init(center.bannerImages);
    setTermPermissions(
      center.terms.reduce((acc: { [key: string]: boolean }, term: any) => {
        acc[term.termId] = term.isViewCkOk;
        return acc;
      }, {} as { [key: string]: boolean })
    );
    setLatitude(center.location.latitude);
    setLongitude(center.location.longitude);
    setAddress(center.location.fullAddress);
    setBusinessRegImage_init(center?.businessRegImage?.mediumImage);
    //form setValue
    setValue('ownerName', center.ownerName);
    setValue('ownerPhone', center.ownerPhone);
    setValue('centerPhone', center.centerPhone);
    const termsObject: { [key: number]: boolean } = center.terms.reduce(
      (acc, term: TermType) => {
        acc[term.termId] = term.isViewCkOk; // termId를 key로, termValue를 value로 설정합니다.
        return acc;
      },
      {} as { [key: number]: boolean }
    ); // 초기 객체에 인덱스 시그니처를 추가합니다.
    setResultTerms(termsObject);
  };

  const onSubmit = async (data: any) => {
    if (!checkValidate()) {
      return;
    }
    let bannerImages: { [key: string]: any } = {};
    if (profileFiles) {
      for (let i = 0; i < profileFiles.length; i++) {
        bannerImages[i + 1] = profileFiles[i].name;
      }
    }
    const deleteSportsTags = selectedTag_init.filter(
      (v) => !selectedTag.includes(v)
    );
    const deleteBannerImagesObject = deleteBannerImages.reduce(
      (acc: any, curr, index) => {
        acc[index + 1] = curr; // 1-based index
        return acc;
      },
      {}
    );
    const response = await postUpdateCenter({
      params: { centerId: center.centerId },
      data: {
        bannerImages,
        deleteBannerImages: deleteBannerImagesObject,
        businessRegImage:
          businessRegFile.length > 0 ? businessRegFile[0].name : null,
        displayName: data.displayName,
        fullAddress: address + '|' + data.subAddress,
        introduce: data.introduce,
        latitude: latitude.toString(),
        longitude: longitude.toString(),
        centerPhone: data.centerPhone,
        term_permissions: termPermissions,
        ownerName: data.ownerName,
        ownerPhone: data.ownerPhone,
        regNumber: data.regNumber,
        sportsTags: selectedTag,
        deleteSportsTags,
      },
    });

    const result = response;
    if (!result) return;
    const { center: updatedCenter } = result;
    setCenter(updatedCenter);

    const { bannerImagesPresignedUrl, businessRegImgPresignedUrl } = result;

    const uploadTasks: any[] = [];
    //씬 썸네일 보내기 (ST00 + ST01)
    profileFiles.map((item: any, index: number) => {
      bannerImagesPresignedUrl[index] &&
        uploadTasks.push(uploadS3(bannerImagesPresignedUrl[index], item));
    });
    //메인 영상 보내기
    if (businessRegFile) {
      businessRegImgPresignedUrl &&
        uploadTasks.push(
          uploadS3(businessRegImgPresignedUrl, businessRegFile[0])
        );
    }
    uploadTasks.length > 0 &&
      Promise.all(uploadTasks)
        .then(() => {})
        .catch((error) => {
          console.error('An error occurred during the upload:', error);

          alert(UPLOAD_ALERT_TEXT);
        });
    setIsEdit(false);
    window.scrollTo(0, 0);
    return true;
  };
  const getSportsTag = async () => {
    try {
      const response = await postSportsTag();
      const { sportsTag } = response;
      setSportsTags((prev) => sportsTag);
    } catch (e) {
      console.error(e);
    }
  };
  const addImage = (file: File, target: string) => {
    if (target === 'profile') {
      setProfileFiles((prev) => [...prev, file]); // `file`을 그대로 추가합니다.
    } else if (target === 'company') {
      setBusinessRegFile((prev) => [...prev, file]); // `file`을 그대로 추가합니다.
    }
  };
  const deleteImage = (file: File | string, target: string) => {
    if (target === 'profile') {
      if (typeof file === 'string') {
        setDeleteBannerImages((prev) => [...prev, file]);
      } else {
        setProfileFiles((prev) => {
          return profileFiles.filter((v) => v !== file);
        });
      }
    } else if (target === 'company') {
      setBusinessRegFile((prev) => {
        return businessRegFile.filter((v) => v !== file);
      });
    }
  };
  const tagClickHandler = (tagId: string) => {
    if (selectedTag.find((v) => v === tagId)) {
      let arr = selectedTag.filter((v) => v !== tagId);
      setSelectedTag(arr);
    } else {
      setSelectedTag((prev) => [...prev, tagId]);
    }
  };
  // Kakao API
  const handleComplete = (data: any) => {
    setIsOpenPost(false);
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress +=
          extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    setAddress(fullAddress);
    getLocation();
  };

  const getLocation = async () => {
    if (!isApiLoading) {
      return;
    }
    const geocoder = new window.kakao.maps.services.Geocoder();

    var callback = function (result: any, status: any) {
      if (status === window.kakao.maps.services.Status.OK) {
        const { x: long, y: lat } = result[0].road_address;
        setLongitude(long);
        setLatitude(lat);
      }
    };

    trigger('location');
    geocoder.addressSearch(address, callback);
  };
  const checkValidate = () => {
    if (
      profileFiles.length < 1 &&
      profileFiles_init.length === deleteBannerImages.length
    ) {
      setProfileFilestatus('false');
    }
    if (selectedTag.length < 1) {
      setTagStatus('false');
    }
    if (businessRegFile.length < 1 && businessRegImage_init == '') {
      setBusinessImageStatus('false');
    }
    if (
      (profileFiles_init.length !== deleteBannerImages.length ||
        profileFiles.length > 0) &&
      selectedTag.length > 0 &&
      (businessRegFile.length > 0 || businessRegImage_init !== '')
    ) {
      return true;
    }
    return false;
  };
  const cancel = async () => {
    if (!window.confirm('센터 신청을 취소하시겠습니까?')) {
      return;
    }
    const response = await postCancelCenter({
      params: { centerId: center.centerId },
    });
    getCenterData({ centerId: center.centerId, setCenter, setProfile });
    navigate('/noCenter');
  };

  return (
    <>
      <Header />
      <Container>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Title>
            현재 승인 절차가 진행중입니다
            <Flex width='343px' margin='16px 0 0 0'>
              <Span
                color='var(--c500)'
                fontSize='16px'
                lineHeight='23px'
                fontWeight='400'
              >
                최대 48시간 이내에 승인 결과를 알려드립니다.
              </Span>
            </Flex>
          </Title>
          <InputContainer>
            <InputName>센터명</InputName>
            <Input
              id='displayName'
              placeholder='센터명을 입력하세요'
              aria-invalid={
                isDirty ? (errors.displayName ? 'true' : 'false') : undefined
              }
              {...register('displayName', {
                required: '센터명을 입력하세요.',
              })}
              defaultValue={center.displayName}
              readOnly={!isEdit}
            />
            {errors.displayName && (
              <Error role='alert'>
                {errors.displayName.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터주소</InputName>
            <Flex flexDirection='row' gap='12px'>
              <Input
                id='location'
                type='text'
                placeholder='주소를 입력하세요'
                aria-invalid={
                  isDirty ? (errors.location ? 'true' : 'false') : undefined
                }
                {...register('location', {
                  validate: {
                    isVerified: () =>
                      address === '' ? '주소를 입력해주세요' : true,
                  },
                })}
                onClick={() => {
                  isEdit && setIsOpenPost(true);
                }}
                value={address.split('|')[0]}
                readOnly={true}
              />
              <AddressFindButton
                type='button'
                onClick={() => isEdit && setIsOpenPost(true)}
              >
                주소검색
              </AddressFindButton>
            </Flex>
            <Input
              id='subAddress'
              placeholder='상세주소'
              type='text'
              readOnly={!isEdit}
              defaultValue={center.location.fullAddress.split('|')[1]}
              aria-invalid={
                isDirty ? (errors.subAddress ? 'true' : 'false') : undefined
              }
              {...register('subAddress')}
            />

            {errors.address && (
              <Error role='alert'>{errors.address.message?.toString()}</Error>
            )}
            {errors.subAddress && (
              <Error role='alert'>
                {errors.subAddress.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터소개</InputName>
            <TextArea
              id='introduce'
              placeholder='센터소개를 입력하세요'
              aria-invalid={
                isDirty ? (errors.introduce ? 'true' : 'false') : undefined
              }
              {...register('introduce', {
                required: '센터소개를 입력하세요.',
              })}
              readOnly={!isEdit}
              defaultValue={center.introduce.text}
            />
            {errors.introduce && (
              <Error role='alert'>{errors.introduce.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>센터 연락처</InputName>
            <Input
              id='centerPhone'
              placeholder='센터 연락처를 입력하세요'
              aria-invalid={
                isDirty ? (errors.centerPhone ? 'true' : 'false') : undefined
              }
              {...register('centerPhone', {
                required: '센터 연락처를 입력하세요.',
              })}
              readOnly={!isEdit}
              defaultValue={center.centerPhone}
            />
            {errors.centerPhone && (
              <Error role='alert'>
                {errors.centerPhone.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>대표이미지 등록</InputName>
            <ImageContainer>
              {profileFiles_init.map((v: ImageType, i) => {
                if (v.id && !deleteBannerImages.includes(v.id)) {
                  return (
                    <UploadImageContainer
                      key={v.id /* or another unique identifier */}
                    >
                      <ImageWrapper>
                        <Img src={v.mediumImage} alt='thumbIage' />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton
                          onClick={() => {
                            const id = v.id;
                            if (id)
                              setDeleteBannerImages((prev) => [...prev, id]);
                          }}
                        >
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  );
                }
                return null;
              })}

              {profileFiles.length > 0 &&
                profileFiles.map((file, index) => {
                  let imageUrl = '';
                  if (file instanceof File) {
                    imageUrl = URL.createObjectURL(file);
                  } else {
                    imageUrl = file;
                  }
                  return (
                    <UploadImageContainer key={index}>
                      <ImageWrapper>
                        <img src={imageUrl} alt='image' />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton
                          onClick={() => deleteImage(file, 'profile')}
                        >
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  );
                })}
              <input
                id='profileImage'
                type='file'
                accept={allowedImageFormats}
                style={{ display: 'none' }}
                onChange={(e) =>
                  e.target.files && addImage(e.target.files[0], 'profile')
                }
              />
              {isEdit && (
                <label htmlFor='profileImage'>
                  <AddImage>
                    <Span>+</Span>
                    <Span>이미지 추가</Span>
                  </AddImage>
                </label>
              )}
            </ImageContainer>
            {profileFilestatus === 'false' && profileFiles.length < 1 && (
              <Error role='alert'>대표이미지를 등록하세요.</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>스포츠 태그</InputName>
            <TagContainer>
              {sportsTags.length > 0 &&
                sportsTags.map((sportsTag: SportsTagType, index) => {
                  return (
                    <TagImageContainer
                      key={index}
                      active={selectedTag.includes(sportsTag.tagId)}
                      onClick={() => isEdit && tagClickHandler(sportsTag.tagId)}
                    >
                      <TagImage src={sportsTag?.tagImage?.mediumImage || ''} />
                      <TagName>{sportsTag.tagName}</TagName>
                    </TagImageContainer>
                  );
                })}
            </TagContainer>

            {tagStatus === 'false' && selectedTag.length < 1 && (
              <Error role='alert'>태그를 선택하세요.</Error>
            )}
          </InputContainer>

          <InputContainer>
            <InputName>대표자 성명</InputName>
            <Input
              id='ownerName'
              placeholder='대표자 성명을 입력하세요'
              aria-invalid={
                isDirty ? (errors.ownerName ? 'true' : 'false') : undefined
              }
              {...register('ownerName', {
                required: '대표자 성명을 입력하세요.',
              })}
              readOnly={!isEdit}
            />
            {errors.ownerName && (
              <Error role='alert'>{errors.ownerName.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>대표자 연락처</InputName>
            <Input
              id='ownerPhone'
              placeholder='대표자 연락처를 입력하세요'
              aria-invalid={
                isDirty ? (errors.ownerPhone ? 'true' : 'false') : undefined
              }
              {...register('ownerPhone', {
                required: '대표자 연락처를 입력하세요.',
              })}
              readOnly={!isEdit}
            />
            {errors.ownerPhone && (
              <Error role='alert'>
                {errors.ownerPhone.message?.toString()}
              </Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>사업자등록번호</InputName>
            <Input
              id='regNumber'
              placeholder='사업자등록번호를 입력하세요'
              aria-invalid={
                isDirty ? (errors.regNumber ? 'true' : 'false') : undefined
              }
              {...register('regNumber', {
                required: '사업자등록번호를 입력하세요.',
              })}
              defaultValue={center.regNumber}
              readOnly={!isEdit}
            />
            {errors.regNumber && (
              <Error role='alert'>{errors.regNumber.message?.toString()}</Error>
            )}
          </InputContainer>
          <InputContainer>
            <InputName>사업자등록증</InputName>
            <Flex flexDirection='row' gap='10px' margin='4px 0 0 0'>
              {businessRegFile.length > 0 ? (
                businessRegFile.map((file, index) => {
                  let imageUrl: string | undefined;
                  if (file instanceof File) {
                    imageUrl = URL.createObjectURL(file);
                  } else {
                    imageUrl = file;
                  }
                  return (
                    <UploadImageContainer key={index}>
                      <ImageWrapper>
                        <img src={imageUrl} alt='image' />
                      </ImageWrapper>
                      {isEdit && (
                        <DeleteButton
                          onClick={() => deleteImage(file, 'company')}
                        >
                          <Icon icon='X' width={9.8} height={9.8} />
                        </DeleteButton>
                      )}
                    </UploadImageContainer>
                  );
                })
              ) : businessRegImage_init !== '' ? (
                <UploadImageContainer key='businessRegImage_init'>
                  <ImageWrapper>
                    <Img src={businessRegImage_init} alt='img' />
                  </ImageWrapper>
                  {isEdit && (
                    <DeleteButton onClick={() => setBusinessRegImage_init('')}>
                      <Icon icon='X' width={9.8} height={9.8} />
                    </DeleteButton>
                  )}
                </UploadImageContainer>
              ) : (
                <>
                  <input
                    id='businessRegFile'
                    type='file'
                    accept={allowedImageFormats}
                    style={{ display: 'none' }}
                    onChange={(e) =>
                      e.target.files && addImage(e.target.files[0], 'company')
                    }
                  />
                  <label htmlFor='businessRegFile'>
                    <AddImage>
                      <Span>+</Span>
                      <Span>이미지 추가</Span>
                    </AddImage>
                  </label>
                </>
              )}
            </Flex>
            {businessImageStatus === 'false' &&
              businessRegFile.length < 1 &&
              businessRegImage_init === '' && (
                <Error role='alert'>사업자등록증 이미지를 등록하세요.</Error>
              )}
          </InputContainer>
          <InputContainer>
            <>
              <TermsWrapper>
                <Terms
                  readOnly={!isEdit}
                  control={control}
                  errors={errors}
                  resultTerms={resultTerms}
                  setResultTerms={setResultTerms}
                />
              </TermsWrapper>
            </>
          </InputContainer>
          {center.ownerAccountId === account.accountId &&
            (isEdit ? (
              <SubmitButton type='submit'>저장하기</SubmitButton>
            ) : (
              <Flex gap='7px' width='100%'>
                <WhiteBorderButton width='180px' type='button' onClick={cancel}>
                  신청 취소하기
                </WhiteBorderButton>
                <SubmitButton
                  type='button'
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    window.scrollTo(0, 0);
                    setIsEdit(true);
                  }}
                >
                  수정하기
                </SubmitButton>
              </Flex>
            ))}
        </Form>
      </Container>
      {isOpenPost && (
        <PostcodeWrapper>
          <Flex
            flexDirection='row'
            height='38px'
            padding='0 15px'
            justifyContents='right'
          >
            <IconWrapper>
              <Icon
                icon='X'
                width={10}
                height={10}
                onClick={() => setIsOpenPost(false)}
              />
            </IconWrapper>
          </Flex>
          <DaumPostcodeEmbed
            onComplete={handleComplete}
            // jsOptions={{ animation: true }}
            // onError={function (error: unknown): void {
            //   throw new Error("Function not implemented.");
            // }}
          />
        </PostcodeWrapper>
      )}
      <CaptionModal
        setCaptions={setCaptions}
        modalShow={captionModalShow}
        setModalShow={setCaptionModalShow}
      />
    </>
  );
}
export default EditCenterForm;
