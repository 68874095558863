import styled from 'styled-components';
export const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  margin-bottom: 20px;
  padding-top: 3px;
  z-index: 2;
  > * {
    z-index: 2;
  }
`;
export const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;
export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  overflow: hidden;
`;
export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 9px;
  justify-content: center;
`;
export const ThumbImageWrapper = styled.div`
  width: 100%;
  max-width: 270px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
`;
export const ContentWrapper = styled.div`
  font-size: 1.4rem;
  line-height: 16px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  color: var(--c100);
  cursor: pointer;
  :hover {
    color: white;
  }
`;
export const AddFeedbackButton = styled.div`
  width: 124px;
  height: 36px;
  flex-shrink: 0;
  border-radius: 18px;
  background: rgb(80 81 86 / 70%);
  color: white;
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  margin-top: 5px;
  cursor: pointer;
`;
export const Line = styled.div`
  position: absolute;
  width: 1px;
  top: 80px;
  left: 48px;
  height: 100%;
  background-color: var(--c800);
  z-index: 1;
`;
export const StateContainer = styled.div`
  display: flex;
  gap: 25px;
  align-items: center;
`;
export const StateItem = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  font-size: 1.3rem;
`;
export const NotiButton = styled.div`
  display: flex;
  padding: 4px 5px 5px 5px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6.5px;
  background: var(--c100);
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.5px;
`;
