import { TermType } from 'constants/type';
import { ReactSVG, useEffect, useState } from 'react';
import { useAccessTokenStore } from 'store/auth';
import * as S from './style';
import Icon from 'components/Icon';
import {
  Control,
  FieldErrors,
  FieldValues,
  useController,
} from 'react-hook-form';
import Input from 'components/Input';
import { Error, IconWrapper } from 'styles/FormStyle';
import { Hr } from 'styles/LoginStyles';
import { openNewWindow } from 'constants/common';
import { postTerm } from 'apis/resource';
type TermsType = {
  readOnly?: boolean;
  control: Control<any>;
  errors: FieldErrors<FieldValues>;
  resultTerms: { [key: string]: boolean };
  setResultTerms: React.Dispatch<
    React.SetStateAction<{ [key: string]: boolean }>
  >;
};
const Terms = ({
  readOnly,
  control,
  errors,
  resultTerms,
  setResultTerms,
}: TermsType) => {
  const [terms, setTerms] = useState<TermType[]>([]);
  const [termAllCheck, setTermAllCheck] = useState<boolean>(false);

  useEffect(() => {
    getTermsList();
  }, []);
  useEffect(() => {
    if (Object.keys(resultTerms).length < 1) {
      const temp: { [key: number]: boolean } = {};
      terms.forEach((term: TermType) => {
        temp[term.termId] = false;
      });

      setResultTerms(temp);
    }
  }, [terms]);
  useEffect(() => {
    handleTermsChange();
  }, [resultTerms]);

  const handleTermsChange = () => {
    setTermAllCheck(
      Object.values(resultTerms).every((value) => value === true)
    );
  };
  const getTermsList = async () => {
    const response = await postTerm();
    const { terms } = response;
    setTerms(terms);
  };
  const handleCheckboxChange = (termId: number, isChecked: boolean) => {
    if (readOnly) {
      return;
    }
    setResultTerms((prevState: any) => ({
      ...prevState,
      [termId]: isChecked,
    }));
  };
  const handleAllCheckChange = () => {
    if (readOnly) {
      return;
    }
    setTermAllCheck((prev) => !prev);
    const newResultTerms = Object.entries(resultTerms).reduce<{
      [key: string]: boolean;
    }>((acc, [key]) => {
      acc[key] = !termAllCheck;
      return acc;
    }, {});
    setResultTerms(newResultTerms);
  };
  return (
    <S.Container>
      <S.AllCheckItem>
        <S.Content>
          <div>
            <Input
              name='allCheck'
              type='checkbox'
              control={control}
              checked={termAllCheck || false}
              onChange={handleAllCheckChange}
            />
            <label htmlFor='allCheck'>
              <IconWrapper>
                {termAllCheck ? (
                  <Icon width={25} height={25} icon='CheckBox_Active' />
                ) : (
                  <Icon width={25} height={25} icon='CheckBox' />
                )}
              </IconWrapper>
            </label>
          </div>
          <S.TermName>서비스 이용약관 전체 동의</S.TermName>
        </S.Content>
      </S.AllCheckItem>
      <Hr />
      {terms.length > 1 &&
        terms.map((term, index) => (
          <S.Item key={index}>
            <S.Content>
              <div>
                <Input
                  name={`${term.termId}`}
                  type='checkbox'
                  control={control}
                  checked={resultTerms[term.termId]}
                  rules={{
                    validate: {
                      isVerified: () =>
                        term.required
                          ? resultTerms[term.termId]
                            ? true
                            : '필수 사항을 체크해주세요'
                          : true,
                    },
                  }}
                  defaultValue=''
                  onChange={handleCheckboxChange}
                />
                <label htmlFor={`${term.termId}`}>
                  <IconWrapper>
                    {resultTerms[term.termId] ? (
                      <Icon width={20} height={20} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={20} height={20} icon='CheckBox' />
                    )}
                  </IconWrapper>
                </label>
              </div>
              <S.TermName>{term.name}</S.TermName>
            </S.Content>
            <S.ViewSpan onClick={() => openNewWindow(term.url)}>
              보기
            </S.ViewSpan>
          </S.Item>
        ))}
      {terms.some((term) => errors[term.termId.toString()]) && (
        <Error role='alert'>
          {errors[
            terms
              .find((term) => errors[term.termId.toString()])!
              .termId.toString()
          ]?.message?.toString()}
        </Error>
      )}
    </S.Container>
  );
};
export default Terms;
