import { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Error } from 'styles/FormStyle';
import {
  Container,
  Background,
  Header,
  Title,
  RequiredButton,
  AddButton,
} from '../style';
import { Flex, Margin, Span } from 'styles/style';
import {
  Input,
  PrepareContainer,
  PrepareImageWrapper,
  PrepareRequiredBox,
  PrepareText,
} from 'styles/FormStyle';
import Icon from 'components/Icon';
import { PreperationType } from 'constants/type';
import { useAccessTokenStore } from 'store/auth';
import { postPreperationList } from 'apis/resource';
type PrepareModalType = {
  setSelectedPreperationList: React.Dispatch<
    React.SetStateAction<PreperationType[]>
  >;
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export const PrepareModal = ({
  setSelectedPreperationList,
  modalShow,
  setModalShow,
}: PrepareModalType) => {
  const [preperationList, setPreperationList] = useState<PreperationType[]>([]);
  const [title, setTitle] = useState<string>('');
  const [activePreperation, setActivePreperation] = useState<{
    iconId: string;
    iconUrl: string;
  }>({
    iconId: '',
    iconUrl: '',
  });
  const [required, setRequired] = useState<boolean>(false);
  const [titleCheck, setTitleCheck] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const { accessToken } = useAccessTokenStore();
  useEffect(() => {
    getPreperationList();
  }, []);
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);

  const getPreperationList = async () => {
    try {
      const response = await postPreperationList();
      const result = response;
      const { preperationList } = result;
      if (preperationList) setPreperationList(preperationList);
      else setPreperationList([]);
    } catch (e) {
      console.error(e);
      setPreperationList([]);
    }
  };
  const handleClose = () => {
    setTitle('');
    setActivePreperation({ iconId: '', iconUrl: '' });
    setRequired(false);
    setModalShow(false);
    setButtonClicked(false);
  };
  const addPrepare = () => {
    setButtonClicked(true);
    if (title === '') {
      setTitleCheck(false);
      return;
    }
    if (activePreperation.iconId === '') {
      return;
    }
    setSelectedPreperationList((prev: Array<PreperationType>) => [
      ...prev,
      {
        iconNo: null,
        title: title,
        isNecessary: required,
        iconId: activePreperation.iconId,
        iconUrl: activePreperation.iconUrl,
      },
    ]);
    handleClose();
  };
  return (
    <>
      {modalShow && (
        <Background padding='100px 0 0 0' onClick={() => handleClose()}>
          <Container onClick={(e) => e.stopPropagation()}>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                addPrepare();
              }}
            >
              <Header>준비물 추가하기</Header>
              <Margin margin='0 0 40px 0' />
              <Title>미리보기</Title>
              <Margin margin='0 0 13px 0' />
              <PrepareContainer width='72px'>
                <PrepareImageWrapper
                  width='72px'
                  height='72px'
                  cursor='default'
                >
                  <img src={activePreperation.iconUrl || '/Prepare1.png'} />
                  {required ? (
                    <PrepareRequiredBox>필수</PrepareRequiredBox>
                  ) : null}
                </PrepareImageWrapper>
                <PrepareText>{title !== '' ? title : '야구배트'}</PrepareText>
              </PrepareContainer>
              <Margin margin='0 0 45px 0' />
              <Flex gap='20px'>
                <Flex gap='10px' flexDirection='column' flex={1}>
                  <Title>준비물 이름</Title>
                  <Input
                    placeholder='준비물 이름을 입력하세요.'
                    onChange={(e) => {
                      title !== '' && setTitleCheck(true);
                      setTitle(e.target.value);
                    }}
                  />
                </Flex>

                <Flex gap='10px' flexDirection='column'>
                  <Title>필수 여부</Title>
                  <RequiredButton
                    active={required}
                    onClick={() => setRequired((prev) => !prev)}
                  >
                    <Flex gap='8px'>
                      {required ? (
                        <>
                          <Icon
                            icon='ToggleCheckButton_Active'
                            width={16}
                            height={16}
                          />
                          <Span fontSize='14px' color='var(--primary)'>
                            필수
                          </Span>
                        </>
                      ) : (
                        <>
                          <Icon
                            icon='ToggleCheckButton'
                            width={16}
                            height={16}
                          />
                          <Span fontSize='14px' color='var(--white)'>
                            필수
                          </Span>
                        </>
                      )}
                    </Flex>
                  </RequiredButton>
                </Flex>
              </Flex>
              <Margin margin='10px 0 0 0' />
              {buttonClicked && title === '' && (
                <Error>준비물 이름을 입력하세요.</Error>
              )}
              <Margin margin='0 0 30px 0' />
              <Title>아이콘 선택</Title>
              <Margin margin='0 0 30px 0' />

              <Flex gap='20px' flexWrap='wrap'>
                {preperationList.map((item, index) => {
                  return (
                    <PrepareImageWrapper
                      width='72px'
                      height='72px'
                      key={index}
                      active={item.iconId === activePreperation.iconId}
                      onClick={() =>
                        item.iconId === activePreperation.iconId
                          ? setActivePreperation({
                              iconId: '',
                              iconUrl: '',
                            })
                          : setActivePreperation({
                              iconId: item.iconId,
                              iconUrl: item.iconUrl,
                            })
                      }
                    >
                      <img src={item.iconUrl} />
                    </PrepareImageWrapper>
                  );
                })}
              </Flex>
              <Margin margin='20px 0 0 0' />
              {buttonClicked && activePreperation.iconId === '' && (
                <Error>준비물 아이콘을 선택하세요.</Error>
              )}
              <Margin margin='0 0 57px 0' />
              <Flex justifyContents='center'>
                <AddButton onClick={() => addPrepare()}>추가하기</AddButton>
              </Flex>
            </form>
          </Container>
        </Background>
      )}
    </>
  );
};
