import styled from 'styled-components';

export const CaptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
export const CaptionItem = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  gap: 15px;
  align-items: center;
`;
export const CaptionTextInput = styled.input`
  height: 38px;
  background-color: var(--background-color);
  border: 1px solid var(--grayscale-c-500, #8a8c99);
  border-radius: 10px;
  flex: 1;
`;
export const CaptionIconWrapper = styled.div`
  width: 52px;
  height: 52px;
  padding: 10px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  background-color: var(--c800);
  cursor: pointer;
  > img {
    object-fit: contain;
  }
`;
