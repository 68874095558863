import { useState, useEffect, useRef } from 'react';
import { Flex, Span } from 'styles/style';
import { Input, VerticalBox, HorizontalBox, TimeInput } from '../Scene/style';
import {
  MetaDataContainer,
  MetaDataColumn,
  MetaDataTitle,
  MetaDataValue,
} from '../MainVideoInput/style';
import SkipToggleButton from 'components/SkipToggleButton';
import VideoSnapshot from 'components/VideoSnapshot';
import { SceneType } from 'constants/type';
import { Container } from './style';
import { DEFAULT_ALERT_TEXT } from 'constants/alertText';
type AddSceneType = {
  ratio: string;
  duration: number;
  sceneList: SceneType[];
  setSceneList: React.Dispatch<React.SetStateAction<SceneType[]>>;
  video: string;
};
export const AddScene = ({
  ratio,
  duration,
  sceneList,
  setSceneList,
  video,
}: AddSceneType) => {
  const [minute, setMinute] = useState<number>(0);
  const [second, setSecond] = useState<number>(0);
  const [minute_e, setMinute_e] = useState<number>(0);
  const [second_e, setSecond_e] = useState<number>(0);
  const [title, setTitle] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<Blob>();
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [canSkipStep, setCanSkipStep] = useState<boolean>(false);
  const add = () => {
    if (!checkTime()) {
      return;
    } else if (title === '') {
      alert('씬의 타이틀을 입력해주세요.');
      return;
    } else if (!thumbnail || !thumbnailUrl) {
      alert(DEFAULT_ALERT_TEXT);
      return;
    }
    const newScene: SceneType = {
      sceneType: 'custom_scene',
      sceneStartAt: (minute * 60 + second) * 1000,
      sceneEndAt: (minute_e * 60 + second_e) * 1000,
      title: title,
      thumbnailUrl: thumbnailUrl,
      thumbnailFile: thumbnail,
      additionalMent: '지금 바로\n연습하고\n피드백을 받아보세요!',
      additionalStreamingDuration:
        (minute_e * 60 + second_e) * 1000 - (minute * 60 + second) * 1000,
      canSkipStep,
      thumbnailUrlChange: true,
      metadata: {}, // 따라하기씬에만 쓰이는 값
    };
    setSceneList((prev) => [...prev, newScene]);
    resetInput();
  };
  const setThumbnailFile = (index: number, blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setThumbnail(blob);
    setThumbnailUrl(url);
  };
  const checkTime = () => {
    const startAt = (minute * 60 + second) * 1000;
    const endAt = (minute_e * 60 + second_e) * 1000;

    if (endAt === 0) {
      alert('종료시간을 입력해주세요.');
      return false;
    }
    if ([minute, minute_e, second, second_e].find((v) => v > 60)) {
      alert('유효한 시간을 입력해주세요. 다시 입력해주세요.');
      return false;
    }
    if (startAt > endAt) {
      alert('시작시간이 종료시간보다 클 수 없습니다. 다시 입력해주세요.');
      return false;
    }
    if (startAt > duration || endAt > duration) {
      alert('메인비디오의 영상 범위를 벗어납니다. 다시 입력해주세요.');
      return false;
    }
    if (
      sceneList.find((scene) => {
        const startAtMS = startAt;
        const endAtMS = endAt;
        if (startAtMS >= scene.sceneStartAt && startAtMS < scene.sceneEndAt) {
          return true;
        }
        if (startAtMS < scene.sceneStartAt && endAtMS > scene.sceneStartAt) {
          return true;
        }
      })
    ) {
      alert('다른 씬과 시간이 겹칩니다. 다시 입력해주세요.');
      return false;
    }

    return true;
  };
  const resetInput = () => {
    setMinute(0);
    setMinute_e(0);
    setSecond(0);
    setSecond_e(0);
    setTitle('');
    setCanSkipStep(false);
  };
  const setCanSkipStep_forFunc = (index: number, value: boolean) => {
    setCanSkipStep(value);
  };
  return (
    <>
      <Container>
        {ratio === 'landscape' ? (
          <HorizontalBox>
            <VideoSnapshot
              videoSrc={video}
              time={(minute * 60 + second) * 1000}
              index={0}
              setThumbnailFile={setThumbnailFile}
            />
          </HorizontalBox>
        ) : (
          <VerticalBox>
            {' '}
            <VideoSnapshot
              videoSrc={video}
              time={(minute * 60 + second) * 1000}
              index={0}
              setThumbnailFile={setThumbnailFile}
            />
          </VerticalBox>
        )}
        <Flex
          flexDirection='column'
          gap='41px'
          justifyContents='center'
          flex={1}
        >
          <Flex
            flexDirection='row'
            justifyContents='space-between'
            alignItems='center'
            gap='15px'
          >
            <MetaDataContainer>
              <MetaDataColumn>
                <MetaDataTitle>시작 시간</MetaDataTitle>
                <MetaDataValue>
                  <TimeInput
                    value={minute.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        // 숫자만 혹은 빈 문자열일 경우
                        setMinute(Number(val));
                      }
                    }}
                    placeholder='00'
                  />
                  분{' '}
                  <TimeInput
                    value={second.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        // 숫자만 혹은 빈 문자열일 경우
                        setSecond(Number(val));
                      }
                    }}
                    placeholder='00'
                  />
                  초
                </MetaDataValue>
              </MetaDataColumn>
              <MetaDataColumn>
                <MetaDataTitle>종료 시간</MetaDataTitle>
                <MetaDataValue>
                  <TimeInput
                    value={minute_e.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        // 숫자만 혹은 빈 문자열일 경우
                        setMinute_e(Number(val));
                      }
                    }}
                    placeholder='00'
                  />
                  분{' '}
                  <TimeInput
                    value={second_e.toString().padStart(2, '0')}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (/^\d+$/.test(val) || val === '') {
                        // 숫자만 혹은 빈 문자열일 경우
                        setSecond_e(Number(val));
                      }
                    }}
                    placeholder='00'
                  />
                  초
                </MetaDataValue>
              </MetaDataColumn>
              <MetaDataColumn>
                <SkipToggleButton
                  id='addScene'
                  index={0}
                  canSkipStep={canSkipStep}
                  setCanSkipStep={setCanSkipStep_forFunc}
                />
              </MetaDataColumn>
            </MetaDataContainer>
            <Flex flexDirection='row' gap='15px' alignItems='center' flex={1}>
              <Input
                placeholder='오프닝'
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Flex>
            <Span
              color='white'
              fontWeight='bold'
              margin='0 5px'
              cursor='pointer'
              onClick={add}
            >
              추가
            </Span>
          </Flex>
        </Flex>
      </Container>
    </>
  );
};
