import { VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';
import { getQuality, getRatio } from 'constants/video';
import React, { useEffect, useState } from 'react';
type useVideoMetadata = {
  videoRef: React.RefObject<HTMLVideoElement>;
  video: any;
  videoRatio?: string;
  setVideoSrc?: React.Dispatch<React.SetStateAction<string>>;
  setIsVideoCanPlay?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setMainVideoSize?: React.Dispatch<React.SetStateAction<any>>;
};
const useVideoMetadata = ({
  videoRef,
  video,
  videoRatio,
  setVideoSrc,
  setIsVideoCanPlay,
  setIsLoading,
  setMainVideoSize,
}: useVideoMetadata) => {
  const [quality, setQuality] = useState<string>('');
  const [ratio, setRatio] = useState<'portrait' | 'landscape'>('landscape');
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [metadata, setMetadata] = useState<any>({});

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    const video = videoRef.current;

    const handleLoadedMetadata = () => {
      const width = video.videoWidth;
      const height = video.videoHeight;
      setMetadata({
        originalWidth: width,
        originalHeight: height,
      });
      setRatio(getRatio(width, height));
      setQuality(getQuality(width, height));
      setDuration(video.duration);
      setMainVideoSize &&
        setMainVideoSize({
          width,
          height,
        });

      //메인 비디오의 ratio를 따라야 할 때
      if (videoRatio !== getRatio(width, height) && setVideoSrc) {
        alert('메인 영상과 같은 비율의 비디오만 업로드 가능합니다.');
        setVideoSrc('');
      }
    };

    const handleCanPlay = () => {
      setIsLoading && setIsLoading(false);
      setIsVideoCanPlay && setIsVideoCanPlay(true);
    };

    const handleTimeUpdate = () => {
      const currentTimeValue = video?.currentTime * 1000;
      setCurrentTime(currentTimeValue);
    };

    video.addEventListener('loadedmetadata', handleLoadedMetadata);
    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      video.removeEventListener('loadedmetadata', handleLoadedMetadata);
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [video]);

  return { duration, ratio, quality, currentTime, setCurrentTime, metadata };
};

export default useVideoMetadata;
