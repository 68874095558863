import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Flex, Margin, Span, Container, Head } from 'styles/style';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import { UserTable } from 'components/UserTable';
import { getUserList } from 'apis/user';
import { ListInfoType, ProfileType } from 'constants/type';
const MemberListPage = () => {
    const { accessToken } = useAccessTokenStore();
    const { center } = useCenterStore();
    const [list, setList] = useState<ProfileType[]>([]);
    const [offset, setOffset] = useState<number>(0);
    const [listInfo, setListInfo] = useState<ListInfoType>({
        count: 0,
        isLastOffset: false,
        limit: 0,
        offset: 0,
        totalCount: 0,
    });

    useEffect(() => {
        setData();
    }, []);
    useEffect(() => {
        setData();
    }, [offset]);

    const setData = async () => {
        const { list, listInfo } = await getUserList({
            centerId: center.centerId,
            role: 'member',
            offset,
        });
        if (list && listInfo) {
            setList(list);
            setListInfo(listInfo);
        } else {
            setList([]);
        }
    };
    return (
        <>
            <Container>
                <Head>센터 회원</Head>
                <Margin margin='30px 0 0' />
                <Flex justifyContents='space-between'>
                    <Span color='var(--c100)'>총 {listInfo.totalCount || 0}명</Span>
                    {/* <Span color='var(--c100)'>1/5페이지</Span> */}
                </Flex>
                <UserTable
                    type='member'
                    centerId={center.centerId}
                    dataList={list}
                    dataInfo={listInfo}
                    setOffset={setOffset}
                    deleteOn={true}
                />
            </Container>
        </>
    );
};
export default MemberListPage;
