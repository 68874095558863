import { Flex, CircleImage, NullDataBox } from 'styles/style';
import * as T from 'styles/TableStyle';
import { getRoleDisplayName, maskEmail } from 'constants/common';
import { ListInfoType, ProfileType } from 'constants/type';
import { useEffect, useState } from 'react';
import Pagination from 'components/Pagination';
import { postDeleteMember } from 'apis/center';
import { useNavigate } from 'react-router-dom';

type Type = {
    type: 'staff' | 'member';
    centerId?: string;
    dataList: ProfileType[];
    dataInfo: ListInfoType;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    deleteOn: boolean;
};
export const UserTable = ({
    centerId,
    dataList,
    dataInfo,
    type,
    setOffset,
    deleteOn,
}: Type) => {
    const [currentPagination, setCurrentPagination] = useState<number>(1);
    const navigate = useNavigate();

    useEffect(() => {
        setOffset((currentPagination - 1) * 10);
    }, [currentPagination]);

    const deleteMember = async (userKey: string) => {
        if (window.confirm(`정말 삭제하시겠습니까?`)) {
            const response = await postDeleteMember({
                params: { accountId: userKey },
                data: { centerId, connRegStatus: 'refusal' },
            });
            navigate(0);
        }
    };
    return (
        <T.Container>
            <T.TableContainer>
                <T.Table>
                    <T.THead>
                        <T.Tr>
                            <T.Th>이름</T.Th>
                            <T.Th>유저타입</T.Th>
                            <T.Th>이메일</T.Th>
                            <T.Th>연락처</T.Th>
                            <T.Th>생성일</T.Th>
                        </T.Tr>
                    </T.THead>
                    <T.TBody>
                        {dataList.length > 0 ? (
                            dataList.map((user, index) => {
                                return (
                                    <T.Tr key={index}>
                                        <T.Td>
                                            <Flex
                                                gap='13px'
                                                alignItems='center'
                                                justifyContents='left'
                                            >
                                                <CircleImage size='31px'>
                                                    <img
                                                        src={
                                                            user.profileImage
                                                                ?.thumbnailImage ||
                                                            '/defaultUserImage.png'
                                                        }
                                                        alt='프로필 이미지'
                                                        onError={(e) => {
                                                            const target =
                                                                e.target as HTMLImageElement;
                                                            target.onerror = null; // 재귀적인 호출을 방지
                                                            target.src =
                                                                '/defaultUserImage.png';
                                                        }}
                                                    />
                                                </CircleImage>
                                                {user.nickname}
                                            </Flex>
                                        </T.Td>
                                        <T.Td>
                                            <T.RoleBox role={user.role}>
                                                {getRoleDisplayName(user.role)}
                                            </T.RoleBox>
                                        </T.Td>
                                        <T.Td>{user.email}</T.Td>
                                        <T.Td>{user.phoneNumber}</T.Td>
                                        <T.Td>{user.createdAt}</T.Td>
                                        {deleteOn && (
                                            <T.Td
                                                onClick={() => deleteMember(user.userKey)}
                                            >
                                                <T.DeleteButton>삭제</T.DeleteButton>
                                            </T.Td>
                                        )}
                                    </T.Tr>
                                );
                            })
                        ) : (
                            <T.Tr>
                                <T.Td colSpan={5}>
                                    <NullDataBox>데이터가 없습니다</NullDataBox>
                                </T.Td>
                            </T.Tr>
                        )}
                    </T.TBody>
                </T.Table>
            </T.TableContainer>
            <Pagination
                listInfo={dataInfo}
                currentPagination={currentPagination}
                setCurrentPagination={setCurrentPagination}
            />
        </T.Container>
    );
};
