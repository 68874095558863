import { useState, useEffect, useRef } from 'react';

type ImgType = {
  src: string;
  alt: string;
};
const Img = ({ src, alt }: ImgType) => {
  const [imageSrc, setImageSrc] = useState(src);
  const retryInterval = 1000; // 0.5초 (500ms)
  const maxRetries = 10; // 최대 재시도 횟수 설정 (옵션)
  const retryCountRef = useRef(0);
  const timeoutIdRef = useRef<number | NodeJS.Timeout | null>(null);
  const timeStamp = new Date().getTime();

  const handleError = () => {
    setImageSrc(transparentGif);
    if (retryCountRef.current >= maxRetries) {
      // 최대 재시도 횟수에 도달하면 더 이상 시도하지 않음.
      return;
    }

    // Clear the previous timeout if there is one
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }

    timeoutIdRef.current = setTimeout(() => {
      // Store the timeout ID
      const timeStamp = new Date().getTime();
      setImageSrc(`${src}?${timeStamp}`);
      retryCountRef.current += 1; // 재시도 횟수 증가
    }, retryInterval);
  };

  // 컴포넌트가 언마운트될 때 setTimeout을 취소합니다.
  useEffect(() => {
    return () => {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    };
  }, []);

  return (
    <img
      src={`${imageSrc}?${timeStamp}`}
      alt={alt}
      onError={handleError}
      style={{ display: imageSrc ? 'block' : 'none' }}
    />
  );
};

const transparentGif = '/default.png';

export default Img;
