import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span, RelativeDiv } from '../../styles/style';
import {
  Form,
  Title,
  Container,
  InputContainer,
  SubmitButton,
  Input,
  InputName,
  Red,
  GrayButton,
  NextButton,
  Error,
} from '../../styles/LoginStyles';
import { TimerSpan } from 'styles/FormStyle';

function PwdResetForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const expireTime = 180;
  const navigate = useNavigate();
  const {
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, isDirty, dirtyFields, isValid, errors },
  } = useForm();
  const [findPwdToken, setFindPwdToken] = useState('');
  const [isAuthNumCorrect, setIsAuthNumCorrect] = useState<boolean>(false);
  const [isReSendActive, setIsResendActive] = useState(false);
  const [isSending, setIsSending] = useState<boolean>(false);
  const [timer, setTimer] = useState<number>(expireTime);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  const email = watch('email');
  const phoneNumber = watch('phoneNumber');
  const authNumber = watch('authNumber');
  const newPassword = watch('newPassword');
  const [step, setStep] = useState(0);
  useEffect(() => {
    dirtyFields['email'] && trigger('email');
    dirtyFields['authNumber'] && trigger('authNumber');
  }, [email, isAuthNumCorrect]);
  const phoneNumberRegex = /^(01([016789]))(\d{4})(\d{4})$/;
  const passwordRegex =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,40}$/;
  const checkEmail = async (data: any) => {
    const response = await fetch(`${API_URL}/api/find-pwd/email-check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });
    if (response.ok) {
      const { findPwdToken } = await response.json();
      setFindPwdToken(() => findPwdToken);
      setStep((prev) => prev + 1);
    } else {
      alert('등록되지 않은 이메일입니다.');
    }
  };
  const isValidPhoneNumber = (phoneNumber: string) => {
    return phoneNumberRegex.test(phoneNumber);
  };
  const sendAuthNumber = async () => {
    trigger('phoneNumber');
    if (!phoneNumber || !isValidPhoneNumber(phoneNumber)) {
      return;
    }
    const response = await fetch(`${API_URL}/api/find-pwd/send-code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ phoneNumber, findPwdToken }),
    });

    if (response.ok) {
      const { findPwdToken } = await response.json();
      setFindPwdToken(findPwdToken);
      setIsResendActive(true);
      resetTimer();
      startTimer();
    } else {
      alert('회원정보와 일치하지 않는 전화번호입니다.');
    }
  };
  const checkAuthNumber = async () => {
    const response = await fetch(`${API_URL}/api/find-pwd/auth-check`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        findPwdToken,
        authNumber,
      }),
    });
    if (response.ok) {
      setIsAuthNumCorrect(true);
      resetTimer();
      trigger('authNumber');
    } else {
      alert('인증번호가 일치하지 않습니다');
      setIsAuthNumCorrect(false);
      trigger('authNumber');
    }
  };
  const resetTimer = () => {
    clearInterval(intervalId!);
    setIntervalId(null);
  };

  const startTimer = () => {
    const id = setInterval(decreaseCounter, 1000);
    setIntervalId(id);
    setTimer(expireTime);
  };
  const decreaseCounter = () => {
    setTimer((prev) => {
      const newTimerValue = prev - 1;

      if (newTimerValue <= 0) {
        alert(
          '입력 시간이 초과되었습니다. 다시 재전송 버튼을 클릭하여 발급된 인증번호를 입력해주세요'
        );
        resetTimer();
        setIsResendActive(true);
      }

      return newTimerValue;
    });
  };
  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [intervalId]);

  const resetPassword = async () => {
    const response = await fetch(`${API_URL}/api/find-pwd/change-pwd`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        findPwdToken,
        newPassword,
      }),
    });
    if (response.ok) {
      const result = await response.json();
      navigate('/login'); // navigate to the new page after password reset
    } else {
      //error
    }
  };
  return (
    <>
      <Container>
        <Title>비밀번호 재설정</Title>
        {step === 0 && (
          <>
            <Form
              onSubmit={handleSubmit((data: any) => {
                checkEmail(data);
              })}
            >
              <InputContainer>
                <InputName>이메일</InputName>
                <Flex flexDirection='row' gap='7px'>
                  <Input
                    id='email'
                    placeholder='이메일을 입력하세요'
                    aria-invalid={
                      isDirty ? (errors.email ? true : false) : undefined
                    }
                    {...register('email', {
                      required: '이메일을 입력하세요.',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: '이메일 형식을 확인하세요.',
                      },
                    })}
                  />
                </Flex>

                {errors.email && (
                  <Error role='alert'>{errors.email.message?.toString()}</Error>
                )}
              </InputContainer>

              <NextButton>다음</NextButton>
            </Form>
          </>
        )}
        {step === 1 && (
          <>
            <Form onSubmit={handleSubmit((data: any) => {})}>
              <InputContainer>
                <InputName>전화번호</InputName>
                <Flex flexDirection='row'>
                  <Span fontSize='13px' color='var(--c400)'>
                    문자를 제외한 숫자만 입력하세요
                  </Span>
                </Flex>
                <Flex flexDirection='row' gap='7px' margin='0 0 3px 0'>
                  <RelativeDiv>
                    <Input
                      id='phoneNumber'
                      placeholder='전화번호를 입력하세요'
                      disabled={isAuthNumCorrect === true}
                      aria-invalid={
                        isDirty
                          ? errors.phoneNumber
                            ? true
                            : false
                          : undefined
                      }
                      {...register('phoneNumber', {
                        required: '전화번호를 입력해주세요',
                        pattern: {
                          value: /^(01[016789])(\d{3,4})(\d{4})$/,
                          message: '유효한 전화번호를 입력해주세요.',
                        },
                      })}
                    />
                    {!isAuthNumCorrect && (
                      <TimerSpan>
                        {Math.floor(timer / 60)}:
                        {(timer % 60).toString().padStart(2, '0')}
                      </TimerSpan>
                    )}
                  </RelativeDiv>
                  <GrayButton
                    type='button'
                    onClick={() => sendAuthNumber()}
                    disabled={isAuthNumCorrect === true}
                  >
                    {isReSendActive ? '재전송' : '인증번호 발송'}
                  </GrayButton>
                </Flex>
                <Flex flexDirection='row' gap='7px' margin='-4px 0 0 0'>
                  <Input
                    id='authNumber'
                    placeholder='인증번호를 입력하세요'
                    disabled={isAuthNumCorrect === true}
                    aria-invalid={
                      isDirty ? (errors.authNumber ? true : false) : undefined
                    }
                    {...register('authNumber', {
                      required: '인증번호를 입력하세요',
                      validate: {
                        isVerified: () =>
                          isAuthNumCorrect !== true
                            ? '인증번호를 정확하게 입력해주세요.'
                            : true,
                      },
                    })}
                  />
                  <GrayButton
                    type='button'
                    onClick={() => checkAuthNumber()}
                    disabled={isAuthNumCorrect === true}
                  >
                    확인
                  </GrayButton>
                </Flex>
                {errors.phoneNumber ? (
                  <Error margin='0 0 10px 0'>
                    {errors.phoneNumber.message?.toString()}
                  </Error>
                ) : (
                  errors.authNumber && (
                    <Error>{errors.authNumber.message?.toString()}</Error>
                  )
                )}
              </InputContainer>
              <NextButton
                onClick={() => {
                  isAuthNumCorrect === true && setStep(() => step + 1);
                }}
              >
                다음
              </NextButton>
            </Form>
          </>
        )}
        {step === 2 && (
          <>
            <Form onSubmit={handleSubmit(() => resetPassword())}>
              <InputContainer>
                <InputName>비밀번호 재설정</InputName>
                <Flex flexDirection='row'>
                  <Span fontSize='13px' color='var(--c400)'>
                    영어, 숫자, 특수문자 포함 10글자 이상 40자 이내
                  </Span>
                </Flex>
                <Input
                  id='newPassword'
                  type='password'
                  placeholder='비밀번호'
                  aria-invalid={
                    isDirty ? (errors.newPassword ? true : false) : undefined
                  }
                  {...register('newPassword', {
                    required: '비밀번호를 입력하세요',
                    pattern: {
                      value: passwordRegex,
                      message:
                        '비밀번호는 영어, 숫자, 특수문자를 포함하여 10글자 이상 40자 이내여야 합니다.',
                    },
                  })}
                />
                {errors.newPassword && (
                  <Error role='alert'>
                    {errors.newPassword.message?.toString()}
                  </Error>
                )}
              </InputContainer>
              <SubmitButton type='submit'>확인</SubmitButton>
            </Form>
          </>
        )}
      </Container>
    </>
  );
}
export default PwdResetForm;
