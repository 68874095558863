import EditSessionForm from 'components/EditSessionForm';
import styled from 'styled-components';

const EditSessionPage = () => {
  return (
    <>
      <Container>
        <EditSessionForm />
      </Container>
    </>
  );
};
export default EditSessionPage;

export const Container = styled.div`
  position: relative;
  display: flex;
  border-radius: 3px;
`;
