import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navigation from '../Navigation';
import { Flex } from '../../styles/style';
import Header from '../../components/Header';
import Footer from 'components/Footer/inex';
import { useCenterStore, useProfileStore } from 'store/auth';
import { useLoadingStore } from 'store/loading';
import { getCenterData } from 'apis/center';
import Loading from 'components/Loading';
interface DefaultLayoutProps {
  children?: React.ReactElement;
}
export default function DefaultLayout({ children }: DefaultLayoutProps) {
  const location = useLocation();
  const { center, setCenter } = useCenterStore();
  const { profile, setProfile } = useProfileStore();
  const { loading } = useLoadingStore();

  useEffect(() => {
    if (center.centerId) {
      getCenterData({ centerId: center.centerId, setCenter, setProfile });
    }
  }, [location]); // location 객체를 의존성 배열에 추가
  return (
    <>
      {loading && <Loading num={2} />}
      <Header />
      <Flex flexDirection='row' margin='64px 0 0 0'>
        <Navigation />
        <Flex
          flexDirection='column'
          position='relative'
          width='100%'
          height='100%'
          margin='0 0 0 245px'
          padding='26px'
          overflow='auto'
        >
          <Outlet />
          <Footer />
        </Flex>
      </Flex>
    </>
  );
}
