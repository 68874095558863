import { useEffect, useRef, useState } from 'react';
import {
  Container,
  Background,
  Header,
  Title,
  AddButton,
  Input,
  InputWrapper,
  DownIconWrapper,
  SearchBox,
  SearchRow,
  SearchItem,
  SearchRow_H,
} from './style';
import { Flex, Margin, Span } from 'styles/style';
import Icon from 'components/Icon';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import { CenterType } from 'constants/type';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { DEFAULT_ALERT_TEXT } from 'constants/alertText';
import { useNavigate } from 'react-router-dom';
import { postCenterView, postSearchCenterList } from 'apis/center';
import { postProposalStaff } from 'apis/user';

type ApplyStaffModalType = {
  modalShow: boolean;
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>;
};
export const ApplyStaffModal = ({
  modalShow,
  setModalShow,
}: ApplyStaffModalType) => {
  //auth
  const { accessToken } = useAccessTokenStore();
  const { setProfile } = useProfileStore();
  const { setCenter } = useCenterStore();

  const navigate = useNavigate();
  const [centerList, setCenterList] = useState<CenterType[]>([]);
  const [nickname, setNickname] = useState<string>('');
  const [isOpenSearchBox, setIsOpenSearchBox] = useState<boolean>(false);
  const [selectedCenter, setSelectedCenter] = useState<CenterType>();
  const [offset, setOffset] = useState(0); // 추가
  //스크롤 처리
  const [totalCount, setTotalCount] = useState(0);
  const lastRef = useRef(null);
  const [observe, unobserve] = useIntersectionObserver(() => {
    setOffset((offset) => offset + 10);
  });
  useEffect(() => {
    isOpenSearchBox && getCenterList();
  }, [isOpenSearchBox]);
  useEffect(() => {
    if (modalShow) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [modalShow]);

  useEffect(() => {
    observe(lastRef.current);
    if (totalCount <= centerList.length || centerList.length === 0) {
      unobserve(lastRef.current);
    }
  }, [centerList]);
  useEffect(() => {
    getCenterList();
  }, [offset]);

  const getCenterList = async () => {
    try {
      const response = await postSearchCenterList({
        isOpenStatus: '-',
        limit: 10,
        offset,
        orderBy: 'desc',
      });
      const result = response;
      const { searchCenter, listInfo } = result;

      if (listInfo) {
        setTotalCount(listInfo.totalCount);
      }
      if (searchCenter?.length > 0 && offset === 0) {
        setCenterList(searchCenter);
      } else if (searchCenter) {
        setCenterList((prev) => [...prev, ...searchCenter]);
      }
    } catch (e) {
      setCenterList([]);
      console.error(e);
    }
  };
  const handleClose = () => {
    setModalShow(false);
  };
  const submit = async () => {
    if (!selectedCenter) {
      alert('선택된 센터가 없습니다.');
      return;
    }
    try {
      const response = await postProposalStaff({
        centerId: selectedCenter.centerId,
        nickname,
      });
      const result = response;
      if (!result) return;

      const { profileImage } = result;
      if (profileImage) {
        setProfile(profileImage);
        setModalShow(false);
        alert('신청이 완료되었습니다.');
        getCenterData(selectedCenter.centerId);
      } else {
        alert(DEFAULT_ALERT_TEXT);
      }
    } catch (e: any) {
      if (e.response && e.response.status == 409) {
        alert('이미 신청 완료한 센터입니다.');
      } else {
        alert('신청 중 오류가 발생했습니다.');
      }
    }
  };
  const getCenterData = async (centerId: string) => {
    try {
      const response = await postCenterView({
        params: {
          centerId,
        },
      });
      const result = response;
      if (!result) return;

      const { center, watchingProfile } = result;
      setCenter(center);
      setProfile(watchingProfile);
      navigate('/pending');
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      {modalShow && (
        <Background onClick={() => handleClose()}>
          <Container onClick={(e) => e.stopPropagation()}>
            <Header>스태프 신청</Header>
            <Margin margin='0 0 40px 0' />
            <Flex gap='10px' flexDirection='column'>
              <Title>센터명</Title>
              <InputWrapper>
                <Input
                  placeholder='신청을 희망하는 센터를 검색하세요.'
                  value={selectedCenter?.displayName}
                  readOnly={true}
                  onChange={(e) => {}}
                  onClick={() => setIsOpenSearchBox((prev) => !prev)}
                  style={{ cursor: 'pointer' }}
                />
                <DownIconWrapper
                  onClick={() => setIsOpenSearchBox((prev) => !prev)}
                >
                  <Icon icon='ArrowDown' width={15} height={15} />
                </DownIconWrapper>
              </InputWrapper>
              {isOpenSearchBox && (
                <SearchBox>
                  <SearchRow_H borderBottom='1px solid rgb(55 55 64)'>
                    <SearchItem>센터명</SearchItem>
                    <SearchItem flex={1}>주소</SearchItem>
                  </SearchRow_H>
                  {centerList.length > 0 ? (
                    centerList.map((center: CenterType, index: number) => {
                      return (
                        <SearchRow
                          key={index}
                          borderBottom='1px solid rgb(55 55 64)'
                          onClick={() => {
                            setSelectedCenter(center);
                            setIsOpenSearchBox(false);
                          }}
                        >
                          <SearchItem>{center.displayName}</SearchItem>
                          <SearchItem flex={1}>
                            {center.location.fullAddress.replace('|', ' ')}
                          </SearchItem>
                        </SearchRow>
                      );
                    })
                  ) : (
                    <SearchRow>
                      <SearchItem flex={1}>센터가 없습니다.</SearchItem>
                    </SearchRow>
                  )}

                  <div ref={lastRef} style={{ height: '1px' }}></div>
                </SearchBox>
              )}
            </Flex>
            <Margin margin='0 0 30px 0' />

            <Flex gap='10px' flexDirection='column'>
              <Title>이름</Title>
              <Input
                placeholder='이름을 입력하세요.'
                value={nickname}
                onChange={(e) => {
                  setNickname(e.target.value);
                }}
              />
            </Flex>
            <Margin margin='0 0 30px 0' />

            <Flex justifyContents='center' width='100%'>
              <AddButton onClick={() => submit()}>신청하기</AddButton>
            </Flex>
          </Container>
        </Background>
      )}
    </>
  );
};
