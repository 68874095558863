import { useForm } from 'react-hook-form';
import { Flex, Margin, Span } from 'styles/style';
import {
  Form,
  InputContainer,
  InputName,
  Input,
  TextArea,
  UploadImageContainer,
  ImageWrapper,
  AddImage,
  PrepareContainer,
  PrepareImageWrapper,
  PrepareText,
  PrepareRequiredBox,
  Error,
} from 'styles/FormStyle';
import {
  Container,
  Title,
  SubmitButton,
  FormWrapper,
  InputContainer_noW100,
  W300,
  CheckBoxContainer,
  DeleteButton,
} from './style';
import { useLocation } from 'react-router-dom';
import Icon from 'components/Icon';
import { useEffect, useState } from 'react';
import PreviewScreen from 'components/PreviewScreen';
import Loading from 'components/Loading';
import { PrepareModal } from 'components/Modals/PrepareModal';
import {
  PreperationType,
  SceneType,
  SessionFormType,
  VideoType,
} from 'constants/type';
import { useNavigate } from 'react-router-dom';
import { CheckBoxInput } from 'styles/LoginStyles';
import { NotificationLine } from 'components/NotificationLine';
import { SessionType } from 'constants/type';
import MainVideoInputDetail from 'components/MainVideoInputDetail';
import { SessionToggleMenu } from 'components/SessionToggleMenu';
import Img from 'components/Img';
type DetailSessionFormType = {
  session: SessionType;
};
function DetailSessionForm({ session }: DetailSessionFormType) {
  const {
    register,
    watch,
    formState: { errors },
  } = useForm<SessionFormType>();
  const watchAllFields = watch();
  const [mainVideo, setMainVideo] = useState<VideoType>();
  const [sceneList, setSceneList] = useState<SceneType[]>([]);
  const [displayImage, setDisplayImage] = useState<string>('');
  const [selectedPreperationList, setSelectedPreperationList] = useState<
    PreperationType[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    setSessionData();
  }, [session]);
  const setSessionData = async () => {
    setSelectedPreperationList(session.preperationList);
    setDisplayImage(session.displayImage.originalImage);
    setMainVideo(session.video);
    setSceneList(session.scenes);
  };
  return (
    <>
      <Container>
        <FormWrapper>
          {isLoading ? <Loading num={1} /> : null}
          <Form>
            <Flex
              flexDirection='row'
              justifyContents='space-between'
              alignItems='center'
              margin='0 0 23px 0'
              width='100%'
            >
              <Title>세션</Title>
              <Flex
                position='relative'
                flex={1}
                justifyContents='right'
                alignItems='flex-start'
              >
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsMenuOpen((prev) => !prev);
                  }}
                  cursor='pointer'
                >
                  <Icon icon='DotMenu' width={20} height={20} />
                </Flex>
                {isMenuOpen && (
                  <SessionToggleMenu
                    sessionId={session.sessionId}
                    isMenuOpen={isMenuOpen}
                    setIsMenuOpen={setIsMenuOpen}
                  />
                )}
              </Flex>
            </Flex>
            <InputContainer>
              <InputName>세션명</InputName>
              <Input
                id='title'
                placeholder='세션 제목을 입력하세요. ex) 케틀벨 스윙으로 부하를 주는 운동법'
                defaultValue={session.title}
                readOnly={true}
                {...register('title', {
                  required: '세션명을 입력하세요.',
                })}
              />
            </InputContainer>

            <InputContainer>
              <InputName>세션 소개</InputName>
              <TextArea
                id='introduceText'
                placeholder='세션 소개를 자유롭게 입력하세요. 최대 1,000자 까지 입력할 수 있습니다.'
                defaultValue={session.introduceText}
                readOnly={true}
                {...register('introduceText', {
                  required: '세션 소개를 입력하세요.',
                })}
              />
            </InputContainer>
            <InputContainer>
              <InputName>공개 범위</InputName>
              <Flex gap='15px'>
                <CheckBoxContainer>
                  <CheckBoxInput id='public' type='checkbox' />
                  <label htmlFor='public'>
                    {session.authority === 'public' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    전체공개
                  </Span>
                </CheckBoxContainer>

                <CheckBoxContainer>
                  <CheckBoxInput id='member_only' type='checkbox' />
                  <label htmlFor='member_only'>
                    {session.authority === 'member_only' ? (
                      <Icon width={22} height={22} icon='CheckBox_Active' />
                    ) : (
                      <Icon width={22} height={22} icon='CheckBox' />
                    )}
                  </label>
                  <Span color='var(--c100)' fontSize='14px'>
                    회원공개
                  </Span>
                </CheckBoxContainer>
              </Flex>
            </InputContainer>
            <Flex flexDirection='row' gap='30px' width='100%'>
              <InputContainer_noW100>
                <InputName>세션 대표 이미지</InputName>
                <Flex flexDirection='row' gap='10px'>
                  <UploadImageContainer>
                    <ImageWrapper>
                      <Img src={displayImage} alt='썸네일' />
                    </ImageWrapper>
                  </UploadImageContainer>
                </Flex>
              </InputContainer_noW100>

              <InputContainer_noW100>
                <InputName>준비물</InputName>
                <Flex flexDirection='row' gap='10px' alignItems='flex-start'>
                  <W300>
                    {selectedPreperationList.length > 0 &&
                      selectedPreperationList.map((item, index) => {
                        return (
                          <PrepareContainer key={index}>
                            <PrepareImageWrapper
                              width='72px'
                              height='72px'
                              backgroundColor='#2c2d34'
                              cursor='default'
                            >
                              <img src={item.iconUrl} alt='iconImage' />
                              {item.isNecessary && (
                                <PrepareRequiredBox>필수</PrepareRequiredBox>
                              )}
                            </PrepareImageWrapper>
                            <PrepareText>{item.title}</PrepareText>
                          </PrepareContainer>
                        );
                      })}
                  </W300>
                </Flex>
              </InputContainer_noW100>
            </Flex>
            <InputContainer>
              <InputName>설명 제목</InputName>
              <Input
                id='introducesTitle'
                placeholder='설명 제목을 입력해 주세요 ex) 시작하기 전 알아야 할 것들'
                defaultValue={
                  session.introduces ? session.introduces[0]?.title : ''
                }
                readOnly={true}
                {...register('introducesTitle', {
                  required: '설명 제목을 입력하세요.',
                })}
              />
            </InputContainer>
            <InputContainer>
              <InputName>설명 내용</InputName>
              <TextArea
                id='introducesContext'
                placeholder='설명을 자유롭게 입력하세요. 최대 1,500자 까지 입력할 수 있습니다.'
                defaultValue={
                  session.introduces ? session.introduces[0]?.context : ''
                }
                readOnly={true}
                {...register('introducesContext', {
                  required: '설명 내용를 입력하세요.',
                })}
              />
            </InputContainer>
            {mainVideo?.convertStatus !== 'completed' && (
              <NotificationLine
                margin='0 0 -50px 0'
                type='alert'
                text='영상을 변환하고 있는 중입니다...'
              />
            )}

            {/* 동영상 추가 */}
            {mainVideo && (
              <MainVideoInputDetail
                setIsLoading={setIsLoading}
                mainVideo={mainVideo}
                sceneList={sceneList}
                setSceneList={setSceneList}
                isEdit={false}
                errors={errors}
                register={register}
              />
            )}
          </Form>
        </FormWrapper>
        <PreviewScreen
          formData={watchAllFields}
          displayImage={displayImage}
          mainVideoDuration={mainVideo?.duration || 0}
          selectedPreperationList={selectedPreperationList}
          copyrightText={session.copyrightText}
        />
        <Margin margin='0 -5px 0 0' />
      </Container>
      <PrepareModal
        setSelectedPreperationList={setSelectedPreperationList}
        modalShow={modalShow}
        setModalShow={setModalShow}
      />
    </>
  );
}
export default DetailSessionForm;
