import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Flex, Span } from '../../styles/style';
import {
    useAccessTokenStore,
    useCenterStore,
    useProfileStore,
    useRefreshTokenStore,
} from 'store/auth';
import DaumPostcodeEmbed from 'react-daum-postcode';
import {
    Form,
    InputName,
    Input,
    AddImage,
    UploadImageContainer,
    InputContainer,
    ImageWrapper,
    DeleteButton,
    SubmitButton,
    TextArea,
    IconWrapper,
    Error,
    ImageContainer,
} from 'styles/FormStyle';
import {
    Container,
    Title,
    AddressFindButton,
    PostcodeWrapper,
    TagImageContainer,
    TagContainer,
    TagName,
    TagImage,
} from './style';
import Header from 'components/Header';
import Icon from '../Icon';
import { allowedImageFormats, uploadS3 } from 'constants/common';
import Loading from 'components/Loading';
import { SportsTagType, TermType } from 'constants/type';
import { TermsWrapper } from 'components/EditCenterFormAfter/style';
import Terms from 'components/Terms';
import { UPLOAD_ALERT_TEXT } from 'constants/alertText';
import { getCenterData, postCreateCenter } from 'apis/center';
import { postSportsTag, postTerm } from 'apis/resource';

function ApplyCenterForm() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        trigger,
        control,
        formState: { isSubmitting, isDirty, errors, dirtyFields },
    } = useForm();
    const [terms, setTerms] = useState<TermType[]>([]);
    const [sportsTags, setSportsTags] = useState<SportsTagType[]>([]);
    const [termPermissions, setTermPermissions] = useState<any>({});
    const [isOpenPost, setIsOpenPost] = useState(false);
    const [address, setAddress] = useState('');
    const [isApiLoading, setIsApiLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [profileFiles, setProfileFiles] = useState<File[]>([]);
    const [businessRegFile, setBusinessRegFile] = useState<File[]>([]);
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [latitude, setLatitude] = useState<string>('');
    const [longitude, setLongitude] = useState<string>('');
    const [profileFilestatus, setProfileFilestatus] = useState<string>('');
    const [tagStatus, setTagStatus] = useState<string>('');
    const [businessImageStatus, setBusinessImageStatus] = useState<string>('');
    const [resultTerms, setResultTerms] = useState<{
        [key: string]: boolean;
    }>({});
    const [validate, setValidate] = useState<boolean>(false);
    const { center, setCenter } = useCenterStore();
    const { setProfile } = useProfileStore();

    const script = document.createElement('script');
    script.src =
        '//dapi.kakao.com/v2/maps/sdk.js?appkey=84e1c459f31b318cab45a0a83e1d85ae&autoload=false&libraries=services';
    script.async = true;
    useEffect(() => {
        getTermsList();
        getSportsTag();
        document.body.appendChild(script);
        script.addEventListener('load', () => {
            window.kakao.maps.load(function () {
                setIsApiLoading(true);
            });
        });
    }, []);
    useEffect(() => {
        getLocation();
    }, [address]);
    const onSubmit = async (data: any) => {
        if (!validate) {
            return;
        }
        setIsLoading(true);
        let bannerImages: { [key: string]: any } = {};
        for (let i = 0; i < profileFiles.length; i++) {
            bannerImages[i + 1] = profileFiles[i].name;
        }
        const response = await postCreateCenter({
            bannerImages,
            businessRegImage: businessRegFile[0].name,
            displayName: data.displayName,
            fullAddress: address + '|' + data.subAddress,
            introduce: data.introduce,
            latitude: latitude.toString() ? latitude.toString() : null,
            longitude: longitude.toString() ? longitude.toString() : null,
            centerPhone: data.centerPhone,
            term_permissions: resultTerms,
            ownerName: data.ownerName,
            ownerPhone: data.ownerPhone,
            regNumber: data.regNumber,
            sportsTags: selectedTags,
        });
        const result = response;
        if (!result) return;
        const { center, bannerImagesPresignedUrl, businessRegImgPresignedUrl } = result;
        getCenterData({ centerId: center.centerId, setCenter, setProfile });

        const uploadTasks: any[] = [];
        profileFiles.forEach((file, index) => {
            uploadTasks.push(uploadS3(bannerImagesPresignedUrl[index], file));
        });
        uploadTasks.push(uploadS3(businessRegImgPresignedUrl, businessRegFile[0]));
        Promise.all(uploadTasks)
            .then((results) => {
                // Ensure state updates and potential re-renders happen before navigating

                setIsLoading(false);
                if (!isLoading) {
                    navigate('/editCenter');
                }
            })
            .catch((error) => {
                console.error('An error occurred during the upload:', error);

                setIsLoading(false);
                alert(UPLOAD_ALERT_TEXT);
            });
        setIsLoading(false);
        return true;
    };
    const getTermsList = async () => {
        try {
            const response = await postTerm();
            const { terms } = response;
            setTerms(terms);
            const initialTermPermissions = terms.reduce((acc: any, term: TermType) => {
                acc[term.termId] = false;
                return acc;
            }, {});
            setTermPermissions(initialTermPermissions);
        } catch (e) {
            console.error(e);
        }
    };
    const getSportsTag = async () => {
        try {
            const response = await postSportsTag();
            const { sportsTag } = response;
            setSportsTags(sportsTag);
        } catch (e) {
            console.error(e);
        }
    };
    const addImage = (file: File, target: string) => {
        if (target === 'profile') {
            setProfileFiles((prev) => [...prev, file]); // `file`을 그대로 추가합니다.
        } else if (target === 'company') {
            setBusinessRegFile((prev) => [...prev, file]); // `file`을 그대로 추가합니다.
        }
    };
    const deleteImage = (file: File | string, target: string) => {
        if (target === 'profile') {
            setProfileFiles((prev) => {
                return profileFiles.filter((v) => v !== file);
            });
        } else if (target === 'company') {
            setBusinessRegFile((prev) => {
                return businessRegFile.filter((v) => v !== file);
            });
        }
    };
    const tagClickHandler = (tagId: string) => {
        if (selectedTags.find((v) => v === tagId)) {
            let arr = selectedTags.filter((v) => v !== tagId);
            setSelectedTags(arr);
        } else {
            setSelectedTags((prev) => [...prev, tagId]);
        }
    };
    // Kakao API
    const handleComplete = (data: any) => {
        setIsOpenPost(false);
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress +=
                    extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }
        setAddress(fullAddress);
        getLocation();
    };

    const getLocation = async () => {
        if (!isApiLoading) {
            return;
        }
        const geocoder = new window.kakao.maps.services.Geocoder();

        var callback = function (result: any, status: any) {
            if (status === window.kakao.maps.services.Status.OK) {
                const { x: long, y: lat } = result[0].road_address;
                setLongitude(long);
                setLatitude(lat);
            }
        };

        trigger('location');
        geocoder.addressSearch(address, callback);
    };
    const checkValidate = () => {
        if (profileFiles.length < 1) {
            setProfileFilestatus('false');
        }
        if (selectedTags.length < 1) {
            setTagStatus('false');
        }
        if (businessRegFile.length < 1) {
            setBusinessImageStatus('false');
        }
        if (
            profileFiles.length > 0 &&
            selectedTags.length > 0 &&
            businessRegFile.length > 0
        ) {
            setValidate(true);
        }
        return;
    };
    return (
        <>
            <Header />
            <Container>
                {isLoading ? <Loading num={0} /> : null}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Title>센터 입점 신청</Title>
                    <InputContainer>
                        <InputName>센터명</InputName>
                        <Input
                            id='displayName'
                            placeholder='센터명을 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.displayName
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('displayName', {
                                required: '센터명을 입력하세요.',
                            })}
                        />
                        {errors.displayName && (
                            <Error role='alert'>
                                {errors.displayName.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>센터주소</InputName>
                        <Flex flexDirection='row' gap='12px'>
                            <Input
                                id='location'
                                type='text'
                                placeholder='주소를 입력하세요'
                                aria-invalid={
                                    isDirty
                                        ? errors.location
                                            ? 'true'
                                            : 'false'
                                        : undefined
                                }
                                {...register('location', {
                                    validate: {
                                        isVerified: () =>
                                            address === '' ? '주소를 입력해주세요' : true,
                                    },
                                })}
                                onClick={() => {
                                    setIsOpenPost(true);
                                }}
                                value={address}
                            />
                            <AddressFindButton
                                type='button'
                                onClick={() => setIsOpenPost(true)}
                            >
                                주소검색
                            </AddressFindButton>
                        </Flex>
                        <Input
                            id='subAddress'
                            placeholder='상세주소'
                            type='text'
                            aria-invalid={
                                isDirty
                                    ? errors.subAddress
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('subAddress')}
                        />
                        {errors.subAddress && (
                            <Error role='alert'>
                                {errors.subAddress.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>센터소개</InputName>
                        <TextArea
                            id='introduce'
                            placeholder='센터소개를 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.introduce
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('introduce', {
                                required: '센터소개를 입력하세요.',
                            })}
                        />
                        {errors.introduce && (
                            <Error role='alert'>
                                {errors.introduce.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>센터 연락처</InputName>
                        <Input
                            id='centerPhone'
                            placeholder='센터 연락처를 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.centerPhone
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('centerPhone', {
                                required: '센터 연락처를 입력하세요.',
                            })}
                        />
                        {errors.centerPhone && (
                            <Error role='alert'>
                                {errors.centerPhone.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>대표이미지 등록</InputName>
                        <ImageContainer>
                            {profileFiles.length > 0 &&
                                profileFiles.map((file, index) => {
                                    let imageUrl = '';
                                    if (file instanceof File) {
                                        imageUrl = URL.createObjectURL(file);
                                    }
                                    return (
                                        <UploadImageContainer key={index}>
                                            <ImageWrapper>
                                                <img src={imageUrl} />
                                            </ImageWrapper>
                                            <DeleteButton
                                                onClick={() =>
                                                    deleteImage(file, 'profile')
                                                }
                                            >
                                                <Icon icon='X' width={9.8} height={9.8} />
                                            </DeleteButton>
                                        </UploadImageContainer>
                                    );
                                })}
                            <input
                                id='profileImage'
                                type='file'
                                accept={allowedImageFormats}
                                style={{ display: 'none' }}
                                onChange={(e) =>
                                    e.target.files &&
                                    addImage(e.target.files[0], 'profile')
                                }
                            />
                            <label htmlFor='profileImage'>
                                <AddImage>
                                    <Span>+</Span>
                                    <Span>이미지 추가</Span>
                                </AddImage>
                            </label>
                        </ImageContainer>
                        {profileFilestatus === 'false' && profileFiles.length < 1 && (
                            <Error role='alert'>대표 이미지를 등록하세요.</Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>스포츠 태그</InputName>
                        <TagContainer>
                            {sportsTags.length > 0 &&
                                sportsTags.map((sportsTag: SportsTagType, index) => {
                                    return (
                                        <TagImageContainer
                                            key={index}
                                            active={selectedTags.includes(
                                                sportsTag.tagId
                                            )}
                                            onClick={() =>
                                                tagClickHandler(sportsTag.tagId)
                                            }
                                        >
                                            <TagImage
                                                src={sportsTag.tagImage.thumbnailImage}
                                            />
                                            <TagName>{sportsTag.tagName}</TagName>
                                        </TagImageContainer>
                                    );
                                })}
                        </TagContainer>

                        {tagStatus === 'false' && selectedTags.length < 1 && (
                            <Error role='alert'>태그를 선택하세요.</Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>대표자 성명</InputName>
                        <Input
                            id='ownerName'
                            placeholder='대표자 성명을 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.ownerName
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('ownerName', {
                                required: '대표자 성명을 입력하세요.',
                            })}
                        />
                        {errors.ownerName && (
                            <Error role='alert'>
                                {errors.ownerName.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>대표자 연락처</InputName>
                        <Input
                            id='ownerPhone'
                            placeholder='대표자 연락처를 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.ownerPhone
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('ownerPhone', {
                                required: '대표자 연락처를 입력하세요.',
                            })}
                        />
                        {errors.ownerPhone && (
                            <Error role='alert'>
                                {errors.ownerPhone.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>사업자등록번호</InputName>
                        <Input
                            id='regNumber'
                            placeholder='사업자등록번호를 입력하세요'
                            aria-invalid={
                                isDirty
                                    ? errors.regNumber
                                        ? 'true'
                                        : 'false'
                                    : undefined
                            }
                            {...register('regNumber', {
                                required: '사업자등록번호를 입력하세요.',
                            })}
                        />
                        {errors.regNumber && (
                            <Error role='alert'>
                                {errors.regNumber.message?.toString()}
                            </Error>
                        )}
                    </InputContainer>
                    <InputContainer>
                        <InputName>사업자등록증</InputName>
                        <Flex flexDirection='row' gap='10px' margin='4px 0 0 0'>
                            {businessRegFile.length > 0 ? (
                                businessRegFile.map((file, index) => {
                                    let imageUrl;
                                    if (file instanceof File) {
                                        imageUrl = URL.createObjectURL(file);
                                    } else {
                                        imageUrl = file;
                                    }
                                    return (
                                        <UploadImageContainer key={index}>
                                            <ImageWrapper>
                                                <img src={imageUrl} />
                                            </ImageWrapper>

                                            <DeleteButton
                                                onClick={() =>
                                                    deleteImage(file, 'company')
                                                }
                                            >
                                                <Icon icon='X' width={9.8} height={9.8} />
                                            </DeleteButton>
                                        </UploadImageContainer>
                                    );
                                })
                            ) : (
                                <>
                                    <input
                                        id='businessRegFile'
                                        type='file'
                                        accept={allowedImageFormats}
                                        style={{ display: 'none' }}
                                        onChange={(e) =>
                                            e.target.files &&
                                            addImage(e.target.files[0], 'company')
                                        }
                                    />
                                    <label htmlFor='businessRegFile'>
                                        <AddImage>
                                            <Span>+</Span>
                                            <Span>이미지 추가</Span>
                                        </AddImage>
                                    </label>
                                </>
                            )}
                        </Flex>
                        {businessImageStatus === 'false' &&
                            businessRegFile.length < 1 && (
                                <Error role='alert'>
                                    사업자등록증 이미지를 등록하세요.
                                </Error>
                            )}
                    </InputContainer>
                    <InputContainer>
                        <>
                            <TermsWrapper>
                                <Terms
                                    control={control}
                                    errors={errors}
                                    resultTerms={resultTerms}
                                    setResultTerms={setResultTerms}
                                />
                            </TermsWrapper>
                        </>
                    </InputContainer>
                    <SubmitButton type='submit' onClick={checkValidate}>
                        입점 신청하기
                    </SubmitButton>
                </Form>
            </Container>
            {isOpenPost && (
                <PostcodeWrapper>
                    <Flex
                        flexDirection='row'
                        height='38px'
                        padding='0 15px'
                        justifyContents='right'
                    >
                        <IconWrapper>
                            <Icon
                                icon='X'
                                width={10}
                                height={10}
                                onClick={() => setIsOpenPost(false)}
                            />
                        </IconWrapper>
                    </Flex>
                    <DaumPostcodeEmbed
                        onComplete={handleComplete}
                        // jsOptions={{ animation: true }}
                        // onError={function (error: unknown): void {
                        //   throw new Error("Function not implemented.");
                        // }}
                    />
                </PostcodeWrapper>
            )}
        </>
    );
}
export default ApplyCenterForm;
