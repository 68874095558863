import { CommentItem } from 'components/CommentItem';
import Icon from 'components/Icon';
import { SubReplyContainer } from 'components/SubReplyContainer';
import { ToggleMenu } from 'components/ToggleMenu';
import { timeAgo } from 'constants/common';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useAccessTokenStore,
  useCenterStore,
  useAccountStore,
} from 'store/auth';
import { Flex, PreLineDiv, Span } from 'styles/style';
import {
  Container,
  ProfileImageWrapper,
  ProfileContainer,
  TopBar,
  TopBarItem,
  CommentBox,
  TextArea,
  AddCommentContainer,
  AddCommentButton,
} from './style';
import { ReplyType } from 'constants/type';
import MyProfileImage from 'components/MyProfileImage';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { postCreateReply, postSubReplyList } from 'apis/community';

type commentContainerType = {
  postId: string;
};
export const CommentContainer = ({ postId }: commentContainerType) => {
  const [replyList, setReplyList] = useState<ReplyType[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { center } = useCenterStore();
  const { accessToken } = useAccessTokenStore();
  const [content, setContent] = useState<string>('');
  const [openIds, setOpenIds] = useState<string[]>([]);
  const [offset, setOffset] = useState(0); // 추가
  const [orderBy, setOrderBy] = useState<'desc' | 'asc'>('desc');
  const [menuOpenId, setMenuOpenId] = useState<string>('');
  //스크롤 처리
  const [totalCount, setTotalCount] = useState(0);
  const lastRef = useRef(null);
  const [observe, unobserve] = useIntersectionObserver(() => {
    setOffset((offset) => offset + 10);
  });

  useEffect(() => {
    getCommentList();
    adjustHeight(false, textAreaRef);
  }, []);
  useEffect(() => {
    getCommentList();
  }, [offset, orderBy]);
  useEffect(() => {
    setOffset(0);
    getCommentList();
  }, [orderBy]);
  useEffect(() => {
    observe(lastRef.current);
    if (totalCount <= replyList.length || replyList.length === 0) {
      unobserve(lastRef.current);
    }
  }, [replyList]);

  const getCommentList = async () => {
    try {
      const response = await postSubReplyList({
        params: { postId },
        data: {
          centerId: center.centerId,
          limit: 10,
          offset,
          orderBy,
        },
      });

      const { listInfo, replylist } = response;
      if (listInfo) {
        setTotalCount(listInfo.totalCount);
      }
      if (Array.isArray(replylist)) {
        if (!replylist || offset === 0) {
          setReplyList(replylist);
        } else {
          setReplyList((prev) => [...prev, ...replylist]);
        }
      } else {
        return;
      }
    } catch (e) {
      console.error('getCommentList error');
    }
  };
  const adjustHeight = (
    isInit: boolean,
    textAreaRef: React.MutableRefObject<HTMLTextAreaElement | null>
  ) => {
    if (textAreaRef.current && isInit) {
      textAreaRef.current.style.height = `20px`;
    } else if (textAreaRef.current) {
      textAreaRef.current.style.height = 'inherit';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  };

  const handleSeeMore = (postId: string) => {
    if (openIds.includes(postId)) {
      const newArray = openIds.filter((id) => id !== postId);
      setOpenIds(newArray);
    } else {
      setOpenIds((prev) => [...prev, postId]);
    }
  };
  const addComment = async () => {
    const response = await postCreateReply({
      params: { postId },
      data: {
        centerId: center.centerId,
        parentReplyId: null,
        content,
        orderBy,
      },
    });
    const result = response;
    if (!result) return;

    setOffset(0);
    getCommentList();
    setContent('');
    adjustHeight(true, textAreaRef);
  };
  return (
    <Container>
      <TopBar>
        <TopBarItem
          style={{ cursor: 'pointer' }}
          onClick={() =>
            orderBy === 'asc' ? setOrderBy('desc') : setOrderBy('asc')
          }
        >
          <Icon icon='FilterVertical' width={12} height={12} />
          시간순
          <Icon icon='ArrowDown' width={6} height={3} />
        </TopBarItem>
      </TopBar>
      <AddCommentContainer>
        <ProfileContainer>
          <ProfileImageWrapper>
            <MyProfileImage />
          </ProfileImageWrapper>
        </ProfileContainer>
        <TextArea
          rows={1}
          ref={textAreaRef}
          onInput={() => adjustHeight(false, textAreaRef)}
          onChange={(e) => setContent(e.target.value)}
          value={content}
          placeholder='댓글을 남겨주세요'
        />
        {content !== '' ? (
          <AddCommentButton isActive={true} onClick={() => addComment()}>
            등록
          </AddCommentButton>
        ) : (
          <AddCommentButton isActive={false}>등록</AddCommentButton>
        )}
      </AddCommentContainer>

      {replyList.length > 0 ? (
        <>
          {replyList.map((reply: ReplyType, index: number) => {
            return (
              <CommentBox key={reply.replyId + index}>
                <CommentItem
                  reply={reply}
                  orderBy={orderBy}
                  handleSeeMore={handleSeeMore}
                  menuOpenId={menuOpenId}
                  setMenuOpenId={setMenuOpenId}
                />
                <SubReplyContainer
                  postId={postId}
                  parentReplyId={reply.replyId}
                  isOpen={openIds.includes(reply.replyId)}
                  openIds={openIds}
                  setOpenIds={setOpenIds}
                  orderBy={orderBy}
                  menuOpenId={menuOpenId}
                  setMenuOpenId={setMenuOpenId}
                />
              </CommentBox>
            );
          })}
          <div ref={lastRef} style={{ height: '10px' }}></div>
        </>
      ) : (
        <CommentBox>
          <Span fontSize='13px' color='var(--c500)' margin='15px 0'>
            등록된 댓글이 없습니다.
          </Span>
        </CommentBox>
      )}
    </Container>
  );
};
