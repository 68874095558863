import { timeAgo } from 'constants/common';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CircleImage, Flex, PreLineDiv, Span } from 'styles/style';
import { useAccessTokenStore, useCenterStore } from 'store/auth';
import VideoPlayer from 'components/VideoPlayer';
import { CommentContainer } from 'components/CommentContainer';
import Icon from 'components/Icon';
import { ToggleMenu } from 'components/ToggleMenu';
import { DeleteButton } from 'styles/FormStyle';
import { NotificationLine } from 'components/NotificationLine';
import { PostType } from 'constants/type';
import MyProfileImage from 'components/MyProfileImage';
import { VIDEOUPLOAD_ALERT_TEXT } from 'constants/alertText';
import Img from 'components/Img';
import { postAddLike, postCommunity } from 'apis/community';
export const DetailCommunityPage = () => {
  const [post, setPost] = useState<PostType>();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const postId = searchParams.get('postId');
  const { accessToken } = useAccessTokenStore();
  const { center } = useCenterStore();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);

  //form data 이미 들어있는 데이터
  const [content, setContent] = useState<string>('');
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [mediaRatio, setMediaRatio] = useState<'landscape' | 'portrait'>();
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const [isNoti, setIsNoti] = useState<boolean>(false);
  const [isConvertEnd, setIsConvertEnd] = useState<boolean>(false);

  //새롭게 입력받을 데이터
  const [uploadImage, setuploadImage] = useState<File[]>();
  const [uploadVideo, setUploadVideo] = useState<File>();
  const [mediaFormat, setMediaFormat] = useState<string>('');
  const [isLike, setIsLike] = useState<boolean>(false);
  const timeStamp = new Date().getTime();
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (post) {
      setContent(post.content);
      post.video && setMediaDuration(post.video.duration);
      post.video && setMediaUrl(post.video.streamingUrl);
      post.image && setMediaUrl(post.image.thumbnailImage);
      setIsNoti(post.isNoti);
      setIsLike(post.isLikeExist);
    }
  }, [post]);
  useEffect(() => {
    if (uploadImage && uploadImage[0]) {
      const img = new Image();
      setMediaUrl(URL.createObjectURL(uploadImage[0]));
      const fileExtension = uploadImage[0].name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      img.onload = function () {
        const that = this as HTMLImageElement;
        if (that.width > that.height) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      };
    }
    if (uploadVideo) {
      const video = document.createElement('video');
      setMediaUrl(URL.createObjectURL(uploadVideo));
      const fileExtension = uploadVideo.name.split('.').pop()?.toLowerCase();

      if (fileExtension) {
        setMediaFormat(fileExtension);
      }
      video.addEventListener('loadedmetadata', function () {
        const duration = this.duration;
        setMediaDuration(duration * 1000);
        if (this.videoWidth > this.videoHeight) {
          setMediaRatio('landscape');
        } else {
          setMediaRatio('portrait');
        }
      });
    }
  }, [uploadImage, uploadVideo]);

  const handleThumbsUp = async (postId: string, isLike: boolean) => {
    const response = await postAddLike({
      centerId: center.centerId,
      postId,
      postType: '0',
      isLikeData: !isLike,
    });
    const result = response;
    if (!result) return;

    setIsLike(!isLike);
  };
  const getData = async () => {
    if (!postId) return;
    const response = await postCommunity({
      params: {
        postId,
      },
      data: {
        centerId: center.centerId,
      },
    });
    const result = await response;
    const { view } = result;
    setPost(view);
  };
  const addMedia = (file: File) => {
    if (file) {
      const fileType = file.type;

      if (fileType.startsWith('image/')) {
        setuploadImage((prev) => (prev ? [...prev, file] : [file]));
      } else if (fileType.startsWith('video/')) {
        const videoElement = document.createElement('video');
        const objectUrl = URL.createObjectURL(file);
        videoElement.src = objectUrl;
        videoElement.addEventListener('loadedmetadata', () => {
          const videoDuration = videoElement.duration;
          if (videoDuration > 30 * 60) {
            alert(VIDEOUPLOAD_ALERT_TEXT);
            URL.revokeObjectURL(objectUrl);
            return;
          } else {
            setUploadVideo(file);
          }
        });
      } else {
        // 기타 파일 처리 로직
      }
    }
  };
  const deleteMedia = () => {
    setuploadImage([]);
    setUploadVideo(undefined);
  };
  return (
    <>
      {post && (
        <Container>
          <MainContainer>
            <UserInfoContainer>
              <CircleImage>
                <img
                  src={
                    post.authorProfile?.profileImage?.thumbnailImage ||
                    '/defaultUserImage.png'
                  }
                  alt='profileImage'
                />
              </CircleImage>
              <Flex flexDirection='column' gap='3px'>
                <Span>{post.authorProfile?.nickname}</Span>
                <Span fontSize='14px' color='var(--c400)'>
                  {timeAgo(post.createdAt)}
                </Span>
              </Flex>
              <Flex
                position='relative'
                flex={1}
                justifyContents='right'
                alignItems='flex-start'
                gap='15px'
              >
                <ButtonWrapper
                  onClick={() => handleThumbsUp(post.postId, isLike)}
                >
                  {isLike ? (
                    <Icon icon='ThumbsUp_Active' width={20} height={20} />
                  ) : (
                    <Icon icon='ThumbsUp' width={20} height={20} />
                  )}
                </ButtonWrapper>
                <ButtonWrapper onClick={() => setIsMenuOpen((prev) => !prev)}>
                  <Icon icon='DotMenu' width={20} height={20} />
                </ButtonWrapper>
                {isMenuOpen && (
                  <ToggleMenu
                    postId={post.postId}
                    isMyPost={post.isMine}
                    top='26px'
                    setIsEdit={setIsEdit}
                    editUrl={`/center/community/edit?postId=${post.postId}`}
                  />
                )}
              </Flex>
            </UserInfoContainer>
            <PreLineDiv>{content}</PreLineDiv>
            <input
              id='feedbackMedia'
              accept='image/*, video/*'
              type='file'
              style={{ display: 'none' }}
              onChange={(e) => e.target.files && addMedia(e.target.files[0])}
            />
            {post?.video && !isConvertEnd && (
              <NotificationLine
                margin='30px 0 0px 0'
                type='alert'
                text='영상을 변환하고 있는 중입니다...'
              />
            )}
            {isEdit &&
              !post.image?.originalImage &&
              !post.video?.streamingUrl && (
                <label htmlFor='feedbackMedia'>
                  <AddMediaButton>
                    <Icon icon='Plus' width={10} height={10} />
                    <Span>사진이나 동영상 추가하기</Span>
                  </AddMediaButton>
                </label>
              )}
            <Flex position='relative'>
              {post?.image?.originalImage && (
                <MediaWrapper>
                  <Img src={post?.image.originalImage} alt='게시글 이미지' />
                </MediaWrapper>
              )}
              {post?.video?.streamingUrl && (
                <>
                  <MediaWrapper>
                    <VideoPlayer
                      controls={true}
                      src={`${post?.video.streamingUrl}?${timeStamp}`}
                      setIsConvertEnd={setIsConvertEnd}
                    />
                  </MediaWrapper>
                </>
              )}
              {isEdit && (
                <DeleteButton onClick={() => deleteMedia()}>
                  <Icon icon='X' width={9.8} height={9.8} />
                </DeleteButton>
              )}
            </Flex>
          </MainContainer>
          {<CommentContainer postId={post?.postId} />}
        </Container>
      )}
    </>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 800px;
  min-width: 800px;
  height: calc(100vh - 115px);
  display: flex;
  background-color: var(--background-color);
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  cursor: default;
`;
const MainContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 32px;
  flex: 1;
  border-right: 1px solid rgb(55, 57, 66);
`;
const UserInfoContainer = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;
const MediaWrapper = styled.div`
  width: 100%;
  border-radius: 8px;
  margin-top: 20px;
  overflow: hidden;
`;
const ButtonWrapper = styled.div`
  cursor: pointer;
`;

const AddMediaButton = styled.div`
  width: 100%;
  padding: 15px;
  border-radius: 12px;
  background-color: var(--c700);
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  color: var(--c300);
  font-size: 1.6rem;
  cursor: pointer;
`;
