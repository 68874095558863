import { ApplyStaffModal } from 'components/ApplyStaffModal';
import Header from 'components/Header';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Span } from 'styles/style';
import {
  Container,
  ApplyButton,
  GrayText,
  ApplyStaffText,
  ImageWrapper,
} from './style';
import {
  useAccessTokenStore,
  useCenterStore,
  useProfileStore,
} from 'store/auth';
import { getCenterData } from 'apis/center';
function NoCenter() {
  const navigate = useNavigate();
  const { center, setCenter } = useCenterStore();
  const { setProfile } = useProfileStore();
  useEffect(() => {
    if (center?.centerId) {
      getCenterData({ centerId: center.centerId, setCenter, setProfile });
    }
  }, []);
  const [modalShow, setModalShow] = useState(false);
  return (
    <>
      <Header />
      <Flex
        flexDirection='row'
        height='100vh'
        justifyContents='center'
        alignItems='center'
        padding='64px 0 0 0'
      >
        <Container>
          <ImageWrapper>
            <img src='/noCenter.png' />
          </ImageWrapper>
          <Span color='white' fontWeight='700' fontSize='24px'>
            센터를 찾을 수 없습니다
          </Span>
          <Span
            color='#8A8C99'
            fontWeight='400'
            lineHeight='23px'
            fontSize='16px'
          >
            아카데미 비즈니스를 운영하고 계신가요? <br />
            지금 입점 신청하고 <br />
            휘슬 Pro의 혜택을 누리세요
          </Span>
          <ApplyButton onClick={() => navigate('/applyCenter')}>
            + 입점신청
          </ApplyButton>
          <Flex flexDirection='column'>
            <GrayText>리얼 피드에 등록된 아카데미의 스태프 이신가요?</GrayText>
            <ApplyStaffText onClick={() => setModalShow(true)}>
              스태프 신청하기
            </ApplyStaffText>
          </Flex>
        </Container>
        <ApplyStaffModal modalShow={modalShow} setModalShow={setModalShow} />
      </Flex>
    </>
  );
}
export default NoCenter;
