import { postProfile } from 'apis/profile';
import Icon from 'components/Icon';
import MyProfileImage from 'components/MyProfileImage';
import { SAVE_ALERT_TEXT } from 'constants/alertText';
import {
  allowedImageFormats,
  getRoleDisplayName,
  uploadS3,
} from 'constants/common';
import { useState } from 'react';
import { useCenterStore, useProfileStore } from 'store/auth';
import styled from 'styled-components';
import { Input, InputContainer, InputName } from 'styles/FormStyle';
import { Flex, Span, Container } from 'styles/style';

const ProfilePage = () => {
  const { center } = useCenterStore();
  const { profile, setProfile } = useProfileStore();
  const [nickname, setNickname] = useState<string>(profile.nickname);
  const [userProfileImage, setUserProfileImage] = useState<File>();
  const addProfileImage = (file: File) => {
    setUserProfileImage(file);
  };
  const submit = async () => {
    const response = await postProfile({
      centerId: center.centerId,
      profileImages: userProfileImage?.name || null,
      nickname,
    });

    const { imagePresignedUrl, myProfile } = response;
    const { nickname: nickname_new } = myProfile;
    if (!myProfile) {
      return;
    }
    userProfileImage &&
      uploadS3(imagePresignedUrl, userProfileImage)
        .then(() => {})
        .catch((error) => {
          alert(SAVE_ALERT_TEXT);
        });

    const updatedProfile = {
      ...profile,
      nickname: nickname_new,
      ...(userProfileImage
        ? {
            profileImage: {
              thumbnailImage: URL.createObjectURL(userProfileImage),
              largeImage: URL.createObjectURL(userProfileImage),
              mediumImage: URL.createObjectURL(userProfileImage),
              originalImage: URL.createObjectURL(userProfileImage),
            },
          }
        : {}),
    };
    setProfile(updatedProfile);
  };
  return (
    <>
      <ProfileContainer>
        <Span
          fontSize='18px'
          fontWeight='700'
          whiteSpace='nowrap'
          margin='0 0 35px 0'
        >
          기본정보
        </Span>
        <Flex>
          <UserImageContainer>
            <input
              id='addProfileImage'
              type='file'
              accept={allowedImageFormats}
              style={{ display: 'none' }}
              onChange={(e) =>
                e.target.files && addProfileImage(e.target.files[0])
              }
            />
            <UserImageWrapper>
              {userProfileImage ? (
                <img src={URL.createObjectURL(userProfileImage)} />
              ) : (
                <MyProfileImage />
              )}
            </UserImageWrapper>
            <label htmlFor='addProfileImage'>
              <AddImageButton>
                <Icon icon='Plus' width={13} height={13} color='black' />
              </AddImageButton>
            </label>
          </UserImageContainer>
          <Form>
            <InputContainer>
              <InputName>이름</InputName>
              <Input
                placeholder='이름을 입력해주세요.'
                defaultValue={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
            </InputContainer>
            <InputContainer>
              <InputName>직급</InputName>
              <Span fontSize='14px' color='var(--c500)'>
                {getRoleDisplayName(profile.role)}
              </Span>
            </InputContainer>
          </Form>
        </Flex>
        <Flex justifyContents='right' margin='15px 0 0 0'>
          <SaveButton onClick={() => submit()}>저장하기</SaveButton>
        </Flex>
      </ProfileContainer>
    </>
  );
};
export default ProfilePage;

const ProfileContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 400px;
  max-width: 700px;
`;
const UserImageContainer = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  flex-shrink: 0;
`;
const UserImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  overflow: hidden;
`;
const AddImageButton = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 100%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;
const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 30px;
`;
const SaveButton = styled.div`
  width: 80px;
  height: 40px;
  background-color: var(--primary);
  color: black;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
`;
