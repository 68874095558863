import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useCenterStore } from 'store/auth';
import Analytics from 'components/Analytics';
import { Container, Margin, Head } from 'styles/style';
import { UserTable } from 'components/UserTable';
import { ListInfoType } from 'constants/type';
import { getUserList } from 'apis/user';

const DashBoardPage = () => {
    const { center } = useCenterStore();
    const [memberList, setMemberList] = useState([]);
    const [offset, setOffset] = useState<number>(0);
    const [memberListInfo, setMemberListInfo] = useState<ListInfoType>({
        count: 0,
        isLastOffset: false,
        limit: 0,
        offset: 0,
        totalCount: 0,
    });
    useEffect(() => {
        setMemberData();
    }, [offset]);
    const setMemberData = async () => {
        try {
            const { list, listInfo } = await getUserList({
                centerId: center.centerId,
                role: 'all',
                offset,
            });
            console.log('list : ', list);
            if (list) {
                setMemberList(list);
            } else {
                setMemberList([]);
            }
            if (listInfo) {
                setMemberListInfo(listInfo);
            }
        } catch (e) {
            console.error('setMemberData error');
        }
    };
    return (
        <>
            {/* <Analytics /> */}
            {/* <Margin margin='30px 0 0' /> */}
            <ItemContainer>
                <Head>센터 회원</Head>
                <UserTable
                    dataList={memberList}
                    dataInfo={memberListInfo}
                    type='member'
                    setOffset={setOffset}
                    deleteOn={false}
                />
            </ItemContainer>
        </>
    );
};
export default DashBoardPage;
export const ItemContainer = styled(Container)`
    margin: 0;
`;
