import { PreperationType } from 'constants/type';
import { getMMSSFormat } from 'constants/video';
import { FieldValues } from 'react-hook-form';
import { useCenterStore } from 'store/auth';
import {
  PrepareContainer,
  PrepareImageWrapper,
  PrepareRequiredBox,
  PrepareText,
} from 'styles/FormStyle';
import { Flex, Margin, PremiumBadge, Span } from 'styles/style';
import {
  Container,
  BackgroundImage,
  MainInfoContainer,
  PhoneStatusImageWrapper,
  TopBar,
  DisplayName,
  CompletionCountContainer,
  CircleContainer,
  CircleImage,
  SubInfoContainer,
  CompletionCountText,
  SubInfoTitle,
  SubItemContainer,
  IntroduceText,
  IntroducesTitle,
  IntroducesContextWrapper,
  StartButton,
  HomeIndicator,
  HomeIndicatorWrapper,
  CenterInfoCircle,
  IntroducesContext,
} from './style';
import { useEffect, useRef } from 'react';
import { PrepareCircle } from 'components/AddSessionForm/style';
import { getKoreanDate } from 'constants/common';
type PreviewScreenType = {
  formData: FieldValues;
  displayImage: string;
  mainVideoDuration: number;
  selectedPreperationList: Array<PreperationType>;
  copyrightText?: string;
};
function PreviewScreen({
  formData,
  displayImage,
  mainVideoDuration: duration,
  selectedPreperationList,
  copyrightText,
}: PreviewScreenType) {
  const screenRef = useRef<HTMLDivElement>(null);
  const { center } = useCenterStore();
  const { title, introduceText, introducesTitle, introducesContext } = formData;
  let targetTop = 0;
  const animate = () => {
    if (screenRef.current && screenRef.current.parentElement) {
      const currentTop = parseFloat(screenRef.current.style.top || '0');
      const newTop = currentTop + (targetTop - currentTop) * 0.1;
      const parentBottom =
        screenRef.current.parentElement.getBoundingClientRect().bottom;
      const currentBottom = screenRef.current.getBoundingClientRect().bottom;
      if (currentBottom + (newTop - currentTop) > parentBottom) {
        requestAnimationFrame(animate);
      } else {
        screenRef.current.style.top = `${newTop}px`;
        requestAnimationFrame(animate);
      }
    }
  };
  useEffect(() => {
    requestAnimationFrame(animate);
    const handleScroll = () => {
      targetTop = window.scrollY;
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <Container ref={screenRef}>
        <BackgroundImage backgroundImage={displayImage} />
        <TopBar>
          <div>9:41</div>
          <PhoneStatusImageWrapper>
            <img src='/phoneStatus.png' />
          </PhoneStatusImageWrapper>
        </TopBar>
        <MainInfoContainer>
          <Span fontSize='1.4rem' fontWeight='700'>
            {center.displayName}
          </Span>
          {title ? (
            <Span fontSize='2.4rem' fontWeight='700'>
              {title}
            </Span>
          ) : (
            <>
              <DisplayName>
                <Span fontSize='2.4rem' fontWeight='700'>
                  제목을
                </Span>
                <Span fontSize='2.4rem' fontWeight='700'>
                  입력하세요
                </Span>
              </DisplayName>
            </>
          )}
          <Span>ㅡ</Span>
          <Span fontSize='1.6rem' fontWeight='700'>
            {getMMSSFormat(duration)}
          </Span>
        </MainInfoContainer>
        <SubInfoContainer>
          <CompletionCountContainer>
            <CircleContainer>
              {[1, 2, 3, 4].map((v, i) => {
                return <CircleImage key={i} src={`/Ellipse ${v}.png`} />;
              })}
            </CircleContainer>
            <CompletionCountText>89명의 멤버가 완료함</CompletionCountText>
          </CompletionCountContainer>
          <SubItemContainer>
            <SubInfoTitle>세션 소개</SubInfoTitle>
            {introduceText ? (
              <IntroduceText value={introduceText} />
            ) : (
              <Span>
                소개를 입력하세요.
                <br />
                최대 1,000자 까지 입력할 수 있습니다.
              </Span>
            )}
          </SubItemContainer>
          <SubItemContainer>
            <SubInfoTitle>준비물</SubInfoTitle>
            <Flex gap='10px' width='343px' overflow='auto'>
              {selectedPreperationList.length > 0 ? (
                selectedPreperationList.map((item, index) => {
                  return (
                    <PrepareContainer key={index}>
                      <PrepareImageWrapper
                        width='72px'
                        height='72px'
                        backgroundColor='#2c2d34'
                        cursor='default'
                      >
                        <img src={item.iconUrl} />
                        {item.isNecessary && (
                          <PrepareRequiredBox>필수</PrepareRequiredBox>
                        )}
                      </PrepareImageWrapper>
                      <PrepareText>{item.title}</PrepareText>
                    </PrepareContainer>
                  );
                })
              ) : (
                <PrepareCircle>
                  <Span>+ 준비물</Span>
                  <Span>추가</Span>
                </PrepareCircle>
              )}
            </Flex>
          </SubItemContainer>
          <SubItemContainer>
            <IntroducesTitle>
              {introducesTitle ? introducesTitle : '설명 제목을 입력하세요'}
            </IntroducesTitle>
            <IntroducesContextWrapper>
              {introducesContext ? (
                <IntroducesContext value={introducesContext} />
              ) : (
                <>
                  <Span>등록하려는 세션의 설명을 입력하세요.</Span>
                  <br />
                  <Span>최대 1,500자 까지 입력할 수 있습니다.</Span>
                </>
              )}
            </IntroducesContextWrapper>
          </SubItemContainer>
          <SubItemContainer>
            <Flex flexDirection='row' gap='13px' margin='15px 0 0 0'>
              <CenterInfoCircle>
                <img
                  src={
                    center.bannerImages[0].largeImage || '/defaultUserImage.png'
                  }
                  alt='프로필 이미지'
                  onError={(e) => {
                    const target = e.target as HTMLImageElement;
                    target.onerror = null; // 재귀적인 호출을 방지
                    target.src = '/defaultUserImage.png';
                  }}
                />
              </CenterInfoCircle>
              <Flex flexDirection='column'>
                {center.centerGrade === 'premium' && (
                  <PremiumBadge>Premium</PremiumBadge>
                )}
                <Margin margin='7px 0 0 0' />
                <Span color='white' fontSize='14px'>
                  {center.displayName}
                </Span>
                <Span color='var(--c500)' fontSize='10px' margin='4px 0 0 0'>
                  {getKoreanDate(center.createdAt).replace(/-/g, '.')}
                </Span>
              </Flex>
            </Flex>
          </SubItemContainer>
          {copyrightText ? (
            <Span
              color='var(--c500)'
              fontSize='10px'
              lineHeight='140%'
              margin='-10px 0 0 0'
            >
              {copyrightText}
            </Span>
          ) : (
            <Span
              color='var(--c500)'
              fontSize='10px'
              lineHeight='140%'
              margin='-10px 0 0 0'
            >
              {'<'}Copyright 2023. 스포애니강남역1호점. All right reserved{'>'}
              <br />본 영상의 저작권은 스포애니 강남1호점에 있습니다.
              <br />
              저작권자의 허가 없이 무단으로 배포, 변경 하는 행위에는 법적 조치가
              이루어질 수 있습니다.
            </Span>
          )}
        </SubInfoContainer>
        <StartButton>세션 시작하기</StartButton>
        <HomeIndicatorWrapper>
          <HomeIndicator />
        </HomeIndicatorWrapper>
      </Container>
    </>
  );
}
export default PreviewScreen;
