import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAccessTokenStore } from '../../store/auth';
interface LoginLayoutProps {
  children?: React.ReactElement;
}
export default function LoginLayout({ children }: LoginLayoutProps) {
  const navigate = useNavigate();
  const { accessToken } = useAccessTokenStore();

  useEffect(() => {
    if (accessToken !== '') {
      navigate('/center/dashboard');
    }
  }, [accessToken]);

  return (
    <>
      <Outlet />
    </>
  );
}
